import { EslManagerPrivateRoute, HttpMethod, SystemConfig, SystemConfigSavePayload } from '@ekkogmbh/apisdk';
import { RestartAlt, Save, Stop } from '@mui/icons-material';
import SaveAlt from '@mui/icons-material/SaveAlt';
import { Box, Button, Checkbox, Grid, IconButton, Paper, Popover, Stack, Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CSSProperties, WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import Dropzone from 'react-dropzone';
import { RouteComponentProps, withRouter } from 'react-router';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { request } from '../../Common/Helper/FetchHandler';
import {
  CancelableFetchPromises,
  CancelablePromise,
  cancelFetchPromises,
  makePromiseCancelable,
} from '../../Common/Helper/PromiseHelper';
import { spacing, withHint } from '../../Common/Helper/ThemeHelper';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { SystemConfigRootStore } from '../Stores/SystemConfigRootStore';
import { ContentControl } from 'src/Common/Components/ContentControl';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    boxShadow: theme.shadows[5],
    padding: spacing(theme) * 2,
    marginBottom: spacing(theme) * 4,
    position: 'relative',
    minHeight: 290,
  } as CSSProperties,
  title: {
    fontSize: 24,
    fontWeight: 700,
  },
  button: {
    flexGrow: 1,
    margin: spacing(theme),
  },
  dataTablePaper: {
    paddingTop: 15,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expansion: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  expansionExpanded: {
    minHeight: spacing(theme) * 7,
  },
  chip: {
    margin: spacing(theme),
    padding: spacing(theme),
  },
  exportIndicator: {
    display: 'none',
  },
  exportFlexContainer: {
    display: 'block',
  },
  dropZone: {
    position: 'relative',
    width: '100%',
    minHeight: 200,
    backgroundColor: theme.palette.background.default,
    border: 'dashed 0.3rem',
    borderColor: withHint(theme.palette.text).hint,
    cursor: 'pointer',
    boxSizing: 'border-box',
    outline: 'none',
    overflow: 'hidden',
    transition: theme.transitions.create(['border', 'background-color', 'padding'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
  } as CSSProperties,
  uploadIcon: {
    fontSize: '8em',
    color: withHint(theme.palette.text).hint,
    display: 'block',
    margin: '0 auto',
    padding: spacing(theme) * 2,
  } as CSSProperties,
});

interface SystemConfigContentStores {
  api: ApiStore;
  systemConfigRootStore: SystemConfigRootStore;
}

const stores = ['api', 'systemConfigRootStore'];

interface SystemConfigContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

@inject(...stores)
@observer
class SystemConfigContentComponent extends React.Component<SystemConfigContentProps> {
  public state: {
    loading: boolean;
    colorPicker?: {
      anchor: HTMLButtonElement;
      color?: string;
    };
  } = {
    loading: false,
  };
  private fetchPromise?: CancelablePromise;
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): SystemConfigContentStores {
    return this.props as SystemConfigContentProps & SystemConfigContentStores;
  }

  public componentDidMount(): void {
    this.setState({ loading: true }, this.fetchConfigRoot);
  }

  public componentWillUnmount(): void {
    if (this.fetchPromise && !this.fetchPromise.isResolved()) {
      this.fetchPromise.cancel();
    }
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchConfigRoot = async (): Promise<void> => {
    const { api, systemConfigRootStore } = this.stores;

    if (this.fetchPromise && !this.fetchPromise.isResolved()) {
      this.fetchPromise.cancel();
    }

    this.fetchPromise = makePromiseCancelable(api.getSystemConfigRoot());

    try {
      const systemConfig = await this.fetchPromise.promise;

      this.setState(
        {
          loading: systemConfig.imageType !== null,
        },
        () => {
          systemConfigRootStore.setState({
            enabled: systemConfig.enabled,
            workerUiPrimaryColor: systemConfig.workerUiPrimaryColor,
          });

          systemConfigRootStore.editableSystemConfigRoot = systemConfig;

          if (systemConfig.imageType !== null) {
            this.fetchConfigRootImage();
          }
        },
      );
    } catch (error) {
      if ((error as { isCanceled?: boolean }).isCanceled) {
        return;
      }

      this.setState({ loading: false });
    }
  };

  public fetchConfigRootImage = async (): Promise<void> => {
    const { api, systemConfigRootStore } = this.stores;

    if (this.fetchPromise && !this.fetchPromise.isResolved()) {
      this.fetchPromise.cancel();
    }

    this.fetchPromise = makePromiseCancelable(api.getSystemConfigRootImage());

    try {
      const image = await this.fetchPromise.promise;

      this.setState(
        {
          loading: false,
        },
        () => {
          systemConfigRootStore.editableSystemConfigRootImage = image;
        },
      );
    } catch (error) {
      if ((error as { isCanceled?: boolean }).isCanceled) {
        return;
      }

      this.setState({ loading: false });
    }
  };

  public saveSystemConfigRoot = async (): Promise<void> => {
    const { api, systemConfigRootStore } = this.stores;
    const { state, editableSystemConfigRoot, editableSystemConfigRootImage } = systemConfigRootStore;

    this.setState({ loading: true });

    const savableSystemConfigRoot: SystemConfigSavePayload = {
      enabled: state.enabled,
      workerUiPrimaryColor: state.workerUiPrimaryColor,
    };

    const savableImage =
      state.image !== null
        ? state.image
        : editableSystemConfigRootImage !== undefined
        ? new File(
            [editableSystemConfigRootImage],
            'image.' + (editableSystemConfigRoot?.imageType === 'image/png' ? 'png' : 'svg'),
          )
        : null;

    await request<SystemConfig>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveSystemConfigRoot(savableSystemConfigRoot, savableImage),
      EslManagerPrivateRoute.SAVE_SYSTEM_CONFIG_ROOT,
      HttpMethod.POST,
      { 200: 'System Config saved.' },
      () => {
        systemConfigRootStore.editableSystemConfigRoot = undefined;
        systemConfigRootStore.editableSystemConfigRootImage = undefined;
        this.fetchConfigRoot();
      },
    );
  };

  public toggleColorPickerPopover = (mappingStateIndex?: {
    event: React.MouseEvent<HTMLButtonElement>;
    color?: string;
  }) => {
    if (mappingStateIndex === undefined) {
      this.setState({ colorPicker: undefined });
    } else {
      const { event, color } = mappingStateIndex;
      this.setState({ colorPicker: { anchor: event.currentTarget, color } });
    }
  };

  public confirmColorPick = () => {
    const { systemConfigRootStore } = this.stores;
    const { colorPicker } = this.state;

    systemConfigRootStore.setState({
      workerUiPrimaryColor: colorPicker?.color?.replace('#', ''),
    });
  };

  public onDrop = (files: File[]) => {
    const { systemConfigRootStore } = this.stores;
    systemConfigRootStore.setFile(files.shift());
  };

  public onDropCancel = () => {
    const { systemConfigRootStore } = this.stores;
    systemConfigRootStore.removeFile();
  };

  public render() {
    const { classes } = this.props;
    const { systemConfigRootStore } = this.stores;
    const { state, editableSystemConfigRootImage } = systemConfigRootStore;
    const { loading, colorPicker } = this.state;
    const { color } = colorPicker || { color: state.workerUiPrimaryColor || '5fd362' };

    const imageUrl =
      state.image !== null
        ? URL.createObjectURL(state.image)
        : editableSystemConfigRootImage !== undefined
        ? URL.createObjectURL(editableSystemConfigRootImage)
        : null;

    return (
      <>
        <ContentControl />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper className={classNames(classes.root)}>
              {loading && <LoadingMask width={75} height={75} />}
              <Grid container spacing={2} alignContent={'space-between'} alignItems={'stretch'}>
                <Grid item xs={12}>
                  <Typography className={classes.title} color="textPrimary">
                    Root System Configuration
                  </Typography>
                </Grid>

                <Grid item sm={2} xs={4}>
                  <Stack spacing={2} sx={{ pt: 4 }}>
                    <Stack spacing={2} justifyContent={'space-between'} direction={'row'}>
                      <Box sx={{ alignContent: 'center' }}>Worker-UI Color</Box>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ fontWeight: 'bolder', marginRight: 2 }}>#{color}</Typography>
                        <IconButton
                          sx={{ padding: 0.5 }}
                          onClick={(event) =>
                            this.toggleColorPickerPopover({
                              event,
                              color,
                            })
                          }
                        >
                          <Stop style={{ color, fontSize: 34 }} />
                        </IconButton>
                      </Box>
                      <Popover
                        open={colorPicker !== undefined}
                        anchorEl={colorPicker?.anchor}
                        anchorOrigin={{ horizontal: 'left', vertical: 'center' }}
                        transformOrigin={{ horizontal: 'center', vertical: 'center' }}
                        onClose={() => this.toggleColorPickerPopover()}
                      >
                        <Stack>
                          <HexColorPicker
                            color={colorPicker?.color}
                            onChange={(color) => {
                              if (colorPicker !== undefined) {
                                this.setState({ colorPicker: { ...colorPicker, color } });
                              }
                            }}
                          />
                          <HexColorInput
                            color={colorPicker?.color}
                            onChange={(color) => {
                              if (colorPicker !== undefined) {
                                this.setState({ colorPicker: { ...colorPicker, color } });
                              }
                            }}
                          />
                          <Button
                            onClick={() => {
                              this.confirmColorPick();
                              this.toggleColorPickerPopover();
                            }}
                          >
                            {'confirm'}
                          </Button>
                        </Stack>
                      </Popover>
                    </Stack>
                    <Stack spacing={2} justifyContent={'space-between'} direction={'row'}>
                      <Box sx={{ alignContent: 'center' }}>Enabled</Box>
                      <Box>
                        <Checkbox
                          checked={state.enabled}
                          onChange={(event) => {
                            systemConfigRootStore.setState({ enabled: event.target.checked });
                          }}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </Grid>

                <Grid item sm={10} xs={8}>
                  <Typography variant="h5" gutterBottom sx={{ textAlign: 'center' }}>
                    Worker-UI Logo
                  </Typography>
                  <Dropzone
                    accept="image/svg+xml, .svg, image/png, .png"
                    onDrop={this.onDrop}
                    onFileDialogCancel={this.onDropCancel}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps()} className={classNames(classes.dropZone)}>
                        <input {...getInputProps()} />
                        <Box sx={{ display: 'flex', justifyContent: 'center', minHeight: 200 }}>
                          {imageUrl === null && <SaveAlt className={classNames(classes.uploadIcon)} />}
                          {imageUrl !== null && <img src={imageUrl} alt="Logo" style={{ maxWidth: 350, margin: 4 }} />}
                        </Box>
                      </div>
                    )}
                  </Dropzone>
                </Grid>

                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
                    <div>
                      <Button
                        variant="outlined"
                        color="secondary"
                        className={classes.button}
                        onClick={systemConfigRootStore.resetStoreEdit}
                        startIcon={<RestartAlt />}
                      >
                        Reset
                      </Button>
                    </div>

                    <div></div>

                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={false}
                        onClick={this.saveSystemConfigRoot}
                        endIcon={<Save />}
                      >
                        Save
                      </Button>
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </>
    );
  }
}

const RouterWrapped = withRouter<SystemConfigContentProps, typeof SystemConfigContentComponent>(
  SystemConfigContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const SystemConfigContent = StyleWrapped;
