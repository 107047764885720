import { TaskCollectionView, TaskState } from '@ekkogmbh/apisdk';
import { Box, LinearProgress, LinearProgressProps, Stack, Tooltip, Typography } from '@mui/material';
import React, { CSSProperties } from 'react';

interface TaskCollectionProgressBarProps {
  taskCollection?: Pick<TaskCollectionView, 'id' | 'progress' | 'state'>;
}
interface TaskCollectionProgressBarState {}

export class TaskCollectionProgressBar extends React.Component<
  TaskCollectionProgressBarProps,
  TaskCollectionProgressBarState
> {
  private inferColor = (): LinearProgressProps['color'] => {
    const { taskCollection } = this.props;

    switch (taskCollection?.state) {
      case TaskState.CREATED:
        return 'warning';
      case TaskState.FAILED:
        return 'error';
      case TaskState.FINISHED:
        return 'success';
      case TaskState.RUNNING:
      default:
        return 'primary';
    }
  };

  public render(): React.ReactNode {
    const { taskCollection } = this.props;

    const styleProps: CSSProperties = {
      height: 10,
      width: '100%',
    };

    if (taskCollection !== undefined) {
      const progress = taskCollection.progress ?? 0;
      return (
        <Tooltip arrow title={taskCollection.state} placement={'top'}>
          <Stack direction={'row'} spacing={1} justifyContent={'center'} alignItems={'baseline'}>
            <Typography>{taskCollection.id}</Typography>
            <LinearProgress
              color={this.inferColor()}
              style={styleProps}
              variant={progress > 0 ? 'determinate' : 'indeterminate'}
              value={progress}
            />
          </Stack>
        </Tooltip>
      );
    }

    return <Box style={styleProps} />;
  }
}
