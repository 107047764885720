import {
  EslManagerPrivateRoute,
  HttpMethod,
  Pagination,
  PaginationResponse,
  SortedSide,
  SortedSideCreatePayload,
  SortedSideUpdatePayload,
  SpatialEntity,
  Template,
} from '@ekkogmbh/apisdk';
import { MenuItem, Paper, SelectChangeEvent } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { request } from '../../Common/Helper/FetchHandler';
import { NodeSeparator } from '../../Common/Helper/Nodes';
import { CancelableFetchPromises, cancelFetchPromises } from '../../Common/Helper/PromiseHelper';
import { SuccessHandlerStatusMessages } from '../../Common/Helper/ResponseHandler';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { ConfigStore } from '../../Common/Stores/ConfigStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { PickingSideStore } from '../Stores/PickingSideStore';
import { PickingStyles } from '../Styles/PickingStyles';
import { materialDatatableColumnDefinitions } from './PickingSideDatatableColumnDefinitions';
import { SortedSidePanel } from './SortedSidePanel';
import React from 'react';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

const styles = PickingStyles;

const stores = ['api', 'configStore', 'paginationStore', 'searchContentStore', 'navigationStore', 'pickingSideStore'];

export interface PickingSidesContentActionHandlers {
  edit: (sortedSide: SortedSide) => void;
  delete: (sortedSide: SortedSide) => void;
  blink: (sortedSide: SortedSide) => void;
}

export type PickingSidesContentPropsWithStores = PickingSidesContentProps & PickingSidesContentStores;

export interface PickingSidesContentStores {
  api: ApiStore;
  configStore: ConfigStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  pickingSideStore: PickingSideStore;
}

export interface PickingSidesContentState {
  isPanelOpen: boolean;
  openDialog?: 'delete' | 'blink';
  selectedSide?: SortedSide;
  selectedDuration: number;
}

export interface PickingSidesContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

@inject(...stores)
class PickingSidesContentComponent extends Component<PickingSidesContentProps, PickingSidesContentState> {
  public state: PickingSidesContentState = {
    isPanelOpen: false,
    selectedDuration: 60,
  };
  private readonly filterFields: DataTableFilterFields<SortedSide> = ['name', 'key', 'templates', 'sortingStrategy'];
  private readonly sortFieldMap: DataTableSortFieldMap<SortedSide> = {
    name: 'S.name',
    key: 'S.key',
    templates: 'S.templates',
    sortingStrategy: 'S.sortingStrategy',
  };
  private fetchPromises: CancelableFetchPromises = {};
  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    200: 'Picking-Side updated.',
    201: 'Picking-Side created.',
    204: 'Picking-Side deleted.',
  };

  get stores(): PickingSidesContentStores {
    return this.props as PickingSidesContentPropsWithStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public blinkSortedSide = async (sortedSide: SortedSide, duration: number): Promise<void> => {
    const { api } = this.stores;

    return request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.blinkSortedSide(sortedSide, duration),
      EslManagerPrivateRoute.BLINK_SORTED_SIDE,
      HttpMethod.POST,
      { 204: 'Blink request successful.' },
    );
  };

  public addSortedSide = async (sortedSide: SortedSideCreatePayload): Promise<SortedSide> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<SortedSide>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.addSortedSide(sortedSide),
      EslManagerPrivateRoute.SORTED_SIDES,
      HttpMethod.POST,
      this.successStatusCodes,
      successCallback,
    );
  };

  public updateSortedSide = async (sortedSide: SortedSideUpdatePayload): Promise<SortedSide> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<SortedSide>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.updateSortedSide(sortedSide),
      EslManagerPrivateRoute.SORTED_SIDE,
      HttpMethod.PUT,
      this.successStatusCodes,
      successCallback,
    );
  };

  public deleteSortedSide = async (sortedSide: SortedSide): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteSortedSide(sortedSide),
      EslManagerPrivateRoute.SORTED_SIDE,
      HttpMethod.DELETE,
      this.successStatusCodes,
      successCallback,
    );
  };

  public fetchSortedSides = async (pagination: Pagination): Promise<PaginationResponse<SortedSide>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<SortedSide>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getSortedSides(pagination),
      EslManagerPrivateRoute.SORTED_SIDES,
      HttpMethod.GET,
    );
  };

  public fetchSortableItems = async (
    nodeValue: string,
    key: string,
    templates: Array<Pick<Template, 'id'>>,
    sortingStrategy?: string,
  ): Promise<SpatialEntity[]> => {
    const { api } = this.stores;
    const templateIds = templates.map((t) => t.id);

    return await request<SpatialEntity[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getSpatialEntitiesByNode(nodeValue, key, templateIds, sortingStrategy),
      EslManagerPrivateRoute.SPATIAL_ENTITIES,
      HttpMethod.GET,
    );
  };

  public fetchSortableItemsBySortedSide = async (
    sortedSide: SortedSide | Pick<SortedSide, 'id'>,
  ): Promise<SpatialEntity[]> => {
    const { api } = this.stores;

    return await request<SpatialEntity[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getSpatialEntitiesBySortedSide(sortedSide),
      EslManagerPrivateRoute.SORTED_SIDE_SPATIAL_ENTITIES,
      HttpMethod.GET,
    );
  };

  public onDismiss = () => {
    this.setState({
      selectedSide: undefined,
      isPanelOpen: false,
      openDialog: undefined,
    });
  };

  public getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen, selectedSide } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return (
      <SortedSidePanel
        saveHandler={this.addSortedSide}
        updateHandler={this.updateSortedSide}
        closeHandler={this.onDismiss}
        fetchSortableItems={this.fetchSortableItems}
        fetchSortableItemsBySortedSide={this.fetchSortableItemsBySortedSide}
        sortedSide={selectedSide}
      />
    );
  };

  public openEditPanel = async (sortedSide: SortedSide): Promise<void> => {
    const { navigationStore } = this.stores;

    this.setState(
      {
        isPanelOpen: true,
        openDialog: undefined,
        selectedSide: sortedSide,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public openDeleteDialog = async (sortedSide: SortedSide): Promise<void> => {
    this.setState({
      selectedSide: sortedSide,
      openDialog: 'delete',
    });
  };

  public onDeleteOk = async () => {
    const { selectedSide } = this.state;

    if (selectedSide?.id === undefined) {
      return;
    }

    await this.deleteSortedSide(selectedSide);

    this.onDismiss();
  };

  public openBlinkDialog = async (sortedSide: SortedSide): Promise<void> => {
    this.setState({
      isPanelOpen: false,
      openDialog: 'blink',
      selectedSide: sortedSide,
    });
  };

  public onBlinkConfirm = async () => {
    const { selectedSide, selectedDuration } = this.state;

    if (selectedSide?.id === undefined) {
      return;
    }

    // Do not wait for the request to finish before closing the dialog
    this.blinkSortedSide(selectedSide, Number(selectedDuration));
    this.onDismiss();
  };

  public handleChange = (name: string) => ({ target: { value } }: SelectChangeEvent<unknown>) => {
    if (name === 'blink') {
      this.setState({
        selectedDuration: Number(value),
      });
    }
  };

  public getOpenDialog = (): JSX.Element | undefined => {
    const { classes } = this.props;
    const { openDialog, selectedSide, selectedDuration } = this.state;

    if (selectedSide?.id === undefined) {
      return;
    }

    switch (openDialog) {
      case 'delete':
        return (
          <GenericDialog
            open
            centered
            fullWidth
            type="confirmation"
            maxWidth={'sm'}
            title={'Delete Picking-Side'}
            text={
              <React.Fragment>
                <div>
                  Delete Picking-Side: <span className={classes.boldFont}>{selectedSide.name}</span>
                </div>
                <div>
                  on Node: <span className={classes.boldFont}>{selectedSide.node.parts.join(NodeSeparator)}</span>?
                </div>
              </React.Fragment>
            }
            onClose={this.onDismiss}
            onConfirm={this.onDeleteOk}
          />
        );
      case 'blink':
        return (
          <GenericDialog
            open
            fullWidth
            centered
            type="confirmation"
            maxWidth={'sm'}
            minHeight={170}
            title={'Blink ' + selectedSide.name}
            text={
              <StyledSelectField value={selectedDuration} onChange={this.handleChange('blink')} label={'Duration'}>
                <MenuItem value={0}>stop</MenuItem>
                <MenuItem value={60}>1 minute</MenuItem>
                <MenuItem value={300}>5 minutes</MenuItem>
                <MenuItem value={600}>10 minutes</MenuItem>
              </StyledSelectField>
            }
            onClose={this.onDismiss}
            onConfirm={this.onBlinkConfirm}
          />
        );
      default:
        return;
    }
  };

  public render() {
    const { isPanelOpen } = this.state;
    const { classes } = this.props;
    const { fetchSortedSides } = this;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as PickingSidesContentPropsWithStores, {
        edit: this.openEditPanel,
        delete: this.openDeleteDialog,
        blink: this.openBlinkDialog,
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={this.getOpenDialog()}
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedSide: undefined })}
              tooltip={'Add Sorted-Side'}
              requiredPermission={Permissions.PICKING_AREAS_WRITE}
            />,
          ]}
        />
        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={fetchSortedSides}
            columns={columnDefinition}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<PickingSidesContentProps, typeof PickingSidesContentComponent>(
  PickingSidesContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const PickingSidesContent = StyleWrapped;
