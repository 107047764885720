import {
  ApiSdkEvents,
  Endpoint,
  EslManagerPrivateRoute,
  Pagination,
  PaginationResponse,
  User,
  UserCreatePayload,
  UserRoleNodeMapping,
  UserUpdatePayload,
} from '@ekkogmbh/apisdk';
import { Grid, Paper, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DatePicker } from '@mui/x-date-pickers';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import moment, { Moment } from 'moment';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { DataTable, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { AuthMechanisms } from '../../Common/Helper/AuthMechanisms';
import {
  CancelableFetchPromises,
  cancelFetchPromises,
  makePromiseCancelable,
  noop,
  promiseKeys,
} from '../../Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { ConfigStore } from '../../Common/Stores/ConfigStore';
import { ContentTitleStore } from '../../Common/Stores/ContentTitleStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { UserManagementStyles } from '../Styles/UserManagementStyles';
import { materialDatatableColumnDefinitions } from './UserDatatableColumnDefinitions';
import { UserPanel } from './UserPanel';
import React from 'react';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { Add } from '@mui/icons-material';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

export interface UserManagementContentState {
  editableUser?: User;
  editableMappings?: UserRoleNodeMapping[];
  openDialog?: 'delete' | 'valid';
  isPanelOpen: boolean;
}

export interface UserManagementContentStores {
  api: ApiStore;
  contentTitleStore: ContentTitleStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  configStore: ConfigStore;
}

export interface UserManagementContentActionHandlers {
  roles: (user: User) => void;
  edit: (user: User) => void;
  delete: (user: User) => void;
}

export interface UserManagementContentHelpers {
  onClickValidUntil: (user: User) => void;
}

const stores = ['api', 'contentTitleStore', 'paginationStore', 'searchContentStore', 'navigationStore', 'configStore'];

export interface UserManagementContentProps extends WithStyles<typeof UserManagementStyles>, RouteComponentProps {}

export type UserManagementContentPropsWithStores = UserManagementContentProps & UserManagementContentStores;

@inject(...stores)
class UserManagementContentComponent extends Component<UserManagementContentProps, UserManagementContentState> {
  public state: UserManagementContentState = {
    isPanelOpen: false,
  };
  private fetchPromises: CancelableFetchPromises = {};
  private readonly filterFields: string[];
  private readonly sortFieldMap: DataTableSortFieldMap<User>;

  constructor(props: UserManagementContentProps) {
    super(props);

    this.filterFields = ['username', 'email'];
    this.sortFieldMap = {
      username: 'U.username',
      email: 'U.email',
      validUntil: 'U.validUntil',
    };
  }

  get stores(): UserManagementContentStores {
    return this.props as UserManagementContentProps & UserManagementContentStores;
  }

  public componentWillUnmount(): void {
    const { contentTitleStore } = this.stores;

    contentTitleStore.setContentTitle(undefined);
    cancelFetchPromises(this.fetchPromises);
  }

  public renderContentTitle = (text: string) => (
    <span>
      :&nbsp;
      <span key={'title'} style={{ fontWeight: 'bold' }}>
        {text}
      </span>
    </span>
  );

  public handleError = (status: number, response: Response, json: Record<string, unknown>): void => {
    if (status >= 400 && status < 500) {
      enqueueSnackbar(response.statusText + ': ' + json.message, { variant: 'error' });
    }
  };

  public handleRedirect = (_status: number, _response: Response, _location: string): void => {
    enqueueSnackbar('User already exists.', { variant: 'warning' });
  };

  public handleSuccess = ({ status }: Response): void => {
    if (status === 201) {
      enqueueSnackbar('User created.');
    }

    if (status === 200 || status === 303) {
      enqueueSnackbar('User already exists.');
    }
  };

  public handleSuccessUpdate = ({ status }: Response): void => {
    if (status === 200) {
      enqueueSnackbar('User updated.');
    }
  };

  public fetchUsers = async (pagination: Pagination): Promise<PaginationResponse<User>> => {
    const { api } = this.stores;

    const endpoint: Endpoint = { path: EslManagerPrivateRoute.USERS };
    const endpointEvent = api.endpointEventType('request:error' as ApiSdkEvents, endpoint);

    api.once(endpointEvent, this.handleError);

    const promise = api.getUsers(pagination, true, true);

    promise
      .then(() => {
        api.off(endpointEvent, this.handleError);
      })
      .catch(noop);

    return await promise;
  };

  public fetchItems = async (pagination: Pagination): Promise<PaginationResponse<User>> => {
    if (this.fetchPromises[promiseKeys.USERS] && !this.fetchPromises[promiseKeys.USERS].isResolved()) {
      this.fetchPromises[promiseKeys.USERS].cancel();
    }

    this.fetchPromises[promiseKeys.USERS] = makePromiseCancelable(this.fetchUsers(pagination));

    this.fetchPromises[promiseKeys.USERS].promise.catch((reason) => {
      if (reason.isCanceled) {
        return;
      }

      throw reason;
    });

    return await this.fetchPromises[promiseKeys.USERS].promise;
  };

  public onClickValidUntil = (user: User) => {
    this.setState({
      editableUser: {
        ...user,
      },
      openDialog: 'valid',
    });
  };

  public actionHandlerRoles = (user: User) => {
    const { navigationStore } = this.stores;
    navigationStore.scrollTop();

    this.props.history.push('/mappings/user/' + user.id);
  };

  public actionHandlerEdit = (user: User) => {
    const { navigationStore } = this.stores;
    this.setState(
      {
        isPanelOpen: true,
        editableUser: { ...user },
      },
      () => navigationStore.scrollTop(),
    );
  };

  public actionHandlerDeleteDialog = (user: User) => {
    this.setState({
      isPanelOpen: false,
      openDialog: 'delete',
      editableUser: { ...user },
    });
  };

  public onDeleteOk = async () => {
    const { editableUser } = this.state;

    if (editableUser !== undefined && editableUser.id !== undefined) {
      await this.handleDeleteUser(editableUser);
    }

    this.onDismiss();
  };

  public handleSaveUser = async (user: UserUpdatePayload | UserCreatePayload, password?: string): Promise<void> => {
    if ((user as UserUpdatePayload).id !== undefined) {
      await this.handleUpdateUser(user as UserUpdatePayload, password);
    } else {
      await this.handleAddUser(user as UserCreatePayload, password);
    }

    this.onDismiss();
  };

  public handleAddUser = async (user: UserCreatePayload, password?: string): Promise<User> => {
    const { api, searchContentStore } = this.stores;

    const endpoint: Endpoint = { path: EslManagerPrivateRoute.USERS };
    const endpointEventError = api.endpointEventType('request:error' as ApiSdkEvents, endpoint);
    const endpointEventRedirect = api.endpointEventType(ApiSdkEvents.REQUEST_REDIRECT, endpoint);
    const endpointEventSuccess = api.endpointEventType('request:finished' as ApiSdkEvents, endpoint);

    api.once(endpointEventError, this.handleError);
    api.once(endpointEventRedirect, this.handleRedirect);
    api.once(endpointEventSuccess, this.handleSuccess);

    const promise = api.addUser(user, password);

    promise
      .then(() => {
        api.off(endpointEventError, this.handleError);
        api.off(endpointEventRedirect, this.handleRedirect);
        api.off(endpointEventSuccess, this.handleSuccess);
        searchContentStore.emitRefresh();
      })
      .catch(noop);

    return await promise;
  };

  public handleUpdateUser = async (user: UserUpdatePayload, password?: string): Promise<boolean> => {
    const { api, searchContentStore } = this.stores;

    const endpoint: Endpoint = {
      path: EslManagerPrivateRoute.USER,
      params: { id: user.id },
    };
    const endpointEventError = api.endpointEventType('request:error' as ApiSdkEvents, endpoint);
    const endpointEventSuccess = api.endpointEventType('request:finished' as ApiSdkEvents, endpoint);

    api.once(endpointEventError, this.handleError);
    api.once(endpointEventSuccess, this.handleSuccessUpdate);

    try {
      await api.updateUser(user, password);
      api.off(endpointEventError, this.handleError);
      api.off(endpointEventSuccess, this.handleSuccessUpdate);
      searchContentStore.emitRefresh();
    } catch (e) {
      api.off(endpointEventError, this.handleError);
      api.off(endpointEventSuccess, this.handleSuccessUpdate);
      return false;
    }

    return true;
  };

  public handleDeleteUser = async (user: User): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const endpoint: Endpoint = {
      path: EslManagerPrivateRoute.USER,
      params: { id: user.id },
    };
    const endpointEventError = api.endpointEventType('request:error' as ApiSdkEvents, endpoint);

    api.once(endpointEventError, this.handleError);

    const promise = api.deleteUser(user);

    promise
      .then(async () => {
        api.off(endpointEventError, this.handleError);
        searchContentStore.emitRefresh();

        enqueueSnackbar('User deleted.');
      })
      .catch(noop);

    await promise;
  };

  public handleChangeValidUntil = (date: Moment | null) => {
    const { editableUser } = this.state;

    if (!editableUser || date == null) {
      return;
    }

    editableUser.validUntil = date.format('YYYY-MM-DD');

    this.setState({
      editableUser,
    });
  };

  public updateValidUntilDialog = (): React.JSX.Element => {
    const { classes } = this.props;
    const { editableUser } = this.state;

    if (!editableUser) {
      return <div />;
    }

    const validUntil = moment(editableUser.validUntil);

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minWidth: 300 }}
      >
        <Grid item xs={12}>
          <div>
            <div>
              User: <span className={classes.boldFont}>{editableUser.username}</span>
            </div>
            <div>
              Role: <span className={classes.boldFont}>{editableUser.email}</span>
            </div>
            <br />
            <DatePicker
              className={classes.margin}
              label="Valid-Until"
              disablePast
              value={validUntil}
              onChange={this.handleChangeValidUntil}
            />
          </div>
        </Grid>
      </Grid>
    );
  };

  public onUpdateOk = async () => {
    const { editableUser } = this.state;

    if (
      !editableUser ||
      !editableUser.id ||
      !editableUser.username ||
      !editableUser.coordinate ||
      !editableUser.email ||
      !editableUser.validUntil ||
      editableUser.validUntil === ''
    ) {
      return;
    }

    const user = {
      id: editableUser.id,
      username: editableUser.username,
      email: editableUser.email,
      coordinate: editableUser.coordinate,
      validUntil: editableUser.validUntil,
    };

    if (await this.handleUpdateUser(user)) {
      this.onDismiss();
    }
  };

  public onDismiss = () => {
    this.setState({
      openDialog: undefined,
      isPanelOpen: false,
      editableUser: undefined,
      editableMappings: undefined,
    });
  };

  public getOpenDialog = (): JSX.Element | undefined => {
    const { openDialog, editableUser } = this.state;

    if (editableUser?.id === undefined) {
      return;
    }

    switch (openDialog) {
      case 'delete': {
        return (
          <GenericDialog
            open
            fullWidth
            centered
            timedOkButton
            type="confirmation"
            maxWidth={'sm'}
            title={'Delete User'}
            text={
              <>
                <div>Delete User &quot;{editableUser.username}&quot;?</div>
                <Typography style={{ margin: 16 }} variant={'caption'}>
                  This action can not be undone.
                </Typography>
              </>
            }
            onClose={this.onDismiss}
            onConfirm={this.onDeleteOk}
          />
        );
      }
      case 'valid': {
        return (
          <GenericDialog
            open
            type="confirmation"
            title={'Update Valid-Until'}
            text={this.updateValidUntilDialog()}
            onClose={this.onDismiss}
            onConfirm={this.onUpdateOk}
          />
        );
      }
      default:
        return;
    }
  };

  public render() {
    const { configStore } = this.stores;
    const { classes } = this.props;
    const { isPanelOpen, editableUser } = this.state;

    const oauthEnabled = configStore.config.api.authMechanism === AuthMechanisms.OAUTH2;

    const columnDefinitions: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(
        this.state,
        this.props as UserManagementContentPropsWithStores,
        {
          roles: this.actionHandlerRoles,
          edit: this.actionHandlerEdit,
          delete: this.actionHandlerDeleteDialog,
        },
        {
          onClickValidUntil: this.onClickValidUntil,
        },
      ),
    );

    const panel = isPanelOpen ? (
      <UserPanel
        user={editableUser}
        closeHandler={this.onDismiss}
        saveHandler={this.handleSaveUser}
        deleteHandler={this.onDeleteOk}
        passwordEditable={!oauthEnabled}
      />
    ) : (
      undefined
    );

    return (
      <>
        <ContentControl
          panel={panel}
          dialog={this.getOpenDialog()}
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen })}
              tooltip={'Add User'}
              requiredPermission={Permissions.USERS_WRITE}
            />,
          ]}
        />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={this.fetchItems}
            columns={columnDefinitions}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<UserManagementContentProps, typeof UserManagementContentComponent>(
  UserManagementContentComponent,
);
const StyleWrapped = withStyles(UserManagementStyles)(RouterWrapped);

export const UserManagementContent = StyleWrapped;
