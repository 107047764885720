import {
  EslManagerPrivateRoute,
  HttpMethod,
  Pagination,
  PaginationResponse,
  Preset,
  PresetSavePayload,
} from '@ekkogmbh/apisdk';
import { Grid, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { SuccessHandlerStatusMessages } from 'src/Common/Helper/ResponseHandler';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from '../../Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { PaginationStore } from '../../Common/Stores/PaginationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { PresetStore } from '../Stores/PresetStore';
import { PresetManagementStyles } from '../Styles/PresetManagementStyles';
import { materialDatatableColumnDefinitions } from './PresetManagementDatatableColumnDefinitions';
import { PresetPanel } from './PresetPanel';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';
import classNames from 'classnames';

const styles = PresetManagementStyles;

const stores = ['api', 'presetStore', 'paginationStore', 'searchContentStore', 'navigationStore'];

export interface PresetManagementContentActionHandlers {
  edit: (preset: Preset) => void;
  delete: (preset: Preset) => void;
}

export interface PresetManagementContentStores {
  api: ApiStore;
  presetStore: PresetStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

export interface PresetManagementContentState {
  selectedPreset?: Preset;
  isDialogOpen: boolean;
  isPanelOpen: boolean;
}

export interface PresetManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type PresetManagementContentPropsWithStores = PresetManagementContentProps & PresetManagementContentStores;

@inject(...stores)
class PresetManagementContentComponent extends Component<PresetManagementContentProps, PresetManagementContentState> {
  private readonly filterFields: DataTableFilterFields<Preset> = ['name', 'coordinatePrefix'];

  private readonly sortFieldMap: DataTableSortFieldMap<Preset> = {
    name: 'P.name',
  };

  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    201: 'Preset created.',
    204: 'Preset deleted.',
  };

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): PresetManagementContentStores {
    return this.props as PresetManagementContentPropsWithStores;
  }

  public state: PresetManagementContentState = {
    isPanelOpen: false,
    isDialogOpen: false,
  };

  public componentWillUnmount() {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchPresets = async (pagination: Pagination): Promise<PaginationResponse<Preset>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<Preset>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getPresets(pagination),
      EslManagerPrivateRoute.PRESETS,
      HttpMethod.GET,
    );
  };

  public onEdit = async (preset: Preset): Promise<void> => {
    const { navigationStore, presetStore } = this.stores;
    presetStore.setEditablePreset(preset);

    this.setState(
      {
        selectedPreset: preset,
        isPanelOpen: true,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public openDeleteDialog = async (preset: Preset): Promise<void> => {
    this.setState({
      selectedPreset: preset,
      isDialogOpen: true,
      isPanelOpen: false,
    });
  };

  public onDismiss = () => {
    this.setState({
      isPanelOpen: false,
      isDialogOpen: false,
      selectedPreset: undefined,
    });
  };

  public onDeleteOk = async () => {
    const { selectedPreset } = this.state;

    if (selectedPreset === undefined) {
      return;
    }

    this.deletePreset(selectedPreset);
    this.onDismiss();
  };

  public deletePreset = async (preset: Preset): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deletePreset(preset),
      EslManagerPrivateRoute.PRESET,
      HttpMethod.DELETE,
      this.successStatusCodes,
      successCallback,
    );
  };

  public savePreset = async (preset: PresetSavePayload): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    await request<Preset>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.savePreset(preset),
      EslManagerPrivateRoute.PRESET,
      HttpMethod.PUT,
      this.successStatusCodes,
    );

    searchContentStore!.emitRefresh();
  };

  public createPanel = (): JSX.Element | undefined => {
    const { isPanelOpen } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return <PresetPanel saveHandler={this.savePreset} closeHandler={this.onDismiss} />;
  };

  public render() {
    const { fetchPresets } = this;
    const { classes } = this.props;
    const { isPanelOpen, isDialogOpen, selectedPreset } = this.state;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as PresetManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.openDeleteDialog,
      }),
    );

    const deleteDialogText =
      isDialogOpen && selectedPreset ? (
        <React.Fragment>
          <div>
            <span className={classes.boldFont}>{selectedPreset.name}</span>
          </div>
        </React.Fragment>
      ) : (
        ''
      );

    return (
      <Grid item xs={12}>
        <ContentControl
          panel={this.createPanel()}
          dialog={
            <GenericDialog
              type="confirmation"
              maxWidth={'sm'}
              fullWidth={true}
              centered={true}
              open={isDialogOpen}
              title={'Delete Preset'}
              text={deleteDialogText}
              onClose={this.onDismiss}
              onConfirm={this.onDeleteOk}
            />
          }
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen })}
              tooltip={'Add Compartment'}
              requiredPermission={Permissions.SHELFLABELING_WRITE}
            />,
          ]}
        />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={fetchPresets}
            columns={columnDefinition}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<PresetManagementContentProps, typeof PresetManagementContentComponent>(
  PresetManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const PresetManagementContent = StyleWrapped;
