import { EslManagerPrivateRoute, HttpMethod, Label, Pagination, PaginationResponse } from '@ekkogmbh/apisdk';
import { Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject, observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { CsvDropzoneStore } from 'src/Common/Stores/CsvDropzoneStore';
import { DataTable } from '../../Common/Components/DataTable';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises } from '../../Common/Helper/PromiseHelper';
import { SuccessHandlerStatusMessages } from '../../Common/Helper/ResponseHandler';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { LabelManagementStyles } from '../Styles/LabelManagementStyles';
import { materialDatatableColumnDefinitions } from './LabelDatatableColumnDefinitions';
import { ContentControl } from 'src/Common/Components/ContentControl';

const styles = LabelManagementStyles;

const stores = ['api', 'csvDropzoneStore', 'searchContentStore'];

export interface LabelManagementContentActionHandlers {
  blink: (label: Label) => void;
}

interface LabelManagementContentStores {
  api: ApiStore;
  csvDropzoneStore: CsvDropzoneStore;
  searchContentStore: SearchContentStore;
}

export interface LabelManagementContentState {}

export interface LabelManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type LabelManagementContentPropsWithStores = LabelManagementContentProps & LabelManagementContentStores;

@inject(...stores)
@observer
class LabelManagementContentComponent extends Component<LabelManagementContentProps> {
  private fetchPromises: CancelableFetchPromises = {};
  private readonly successTaskCollectionStatusCodes: SuccessHandlerStatusMessages = {
    200: 'Blink request queued.',
  };

  get stores(): LabelManagementContentStores {
    return this.props as LabelManagementContentPropsWithStores;
  }

  public componentWillUnmount(): void {
    this.stores.csvDropzoneStore.reset();
  }

  public fetchItems = (pagination: Pagination): Promise<PaginationResponse<Label>> => {
    const { api } = this.stores;

    return api.getLabels(pagination);
  };

  public actionHandlerBlink = async (label: Label) => {
    const { api } = this.stores;

    try {
      await request<void>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.blinkLabel(label),
        EslManagerPrivateRoute.BLINK,
        HttpMethod.POST,
        this.successTaskCollectionStatusCodes,
      );
    } catch (e) {
      console.error(e);
    }
  };

  public render() {
    const { classes } = this.props;

    const columnDefinitions: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as LabelManagementContentPropsWithStores, {
        blink: this.actionHandlerBlink,
      }),
    );

    return (
      <>
        <ContentControl />
        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={this.fetchItems}
            columns={columnDefinitions}
            filterFields={['id', 'identifier', 'technology', 'status', 'batteryStatus']}
            sortFieldMap={{
              id: 'L.id',
              identifierValue: 'Ident.value',
              technology: 'L.technology',
              status: 'L.status',
              batteryStatus: 'L.batteryStatus',
            }}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[3].name, direction: 'asc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<LabelManagementContentProps, typeof LabelManagementContentComponent>(
  LabelManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const LabelManagementContent = StyleWrapped;
