import { Endpoint, EslManagerPrivateRoute, HttpMethod, PaginationResponse, Permission, Role } from '@ekkogmbh/apisdk';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { DataTable, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { createRequestWrapper, request } from 'src/Common/Helper/FetchHandler';
import { SuccessHandlerStatusMessages } from 'src/Common/Helper/ResponseHandler';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { CancelableFetchPromises, cancelFetchPromises, makePromiseCancelable } from '../../Common/Helper/PromiseHelper';
import { TaskCollectionProgressCallback } from '../../Common/Stores/TaskCollectionStore';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { Routes, RouteShortDefinition } from '../../Routes';
import { RoleManagementStyles } from '../Styles/RoleManagementStyles';
import { materialDatatableColumnDefinitions } from './RoleDatatableColumnDefinitions';
import { RolePanel } from './RolePanel';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';
import { Add } from '@mui/icons-material';
import { Paper } from '@mui/material';

interface RoleManagementContentStores {
  api: ApiStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

const stores = ['api', 'paginationStore', 'searchContentStore', 'navigationStore'];

export interface RoleManagementContentActionHandlers {
  users: (role: Role) => void;
  edit: (role: Role) => void;
  delete: (role: Role) => void;
}

export interface RoleManagementContentHelpers {
  updateRolePermissions: (role: Role, permissions: Permission[]) => Promise<void>;
}

export interface RoleManagementContentState {
  selectedRole?: Role;
  isDialogOpen: boolean;
  isPanelOpen: boolean;
  allPermissions: Permission[];
}

export interface RoleManagementContentProps extends WithStyles<typeof RoleManagementStyles>, RouteComponentProps {}

export type RoleManagementContentPropsWithStores = RoleManagementContentProps & RoleManagementContentStores;

@inject(...stores)
class RoleManagementContentComponent extends Component<RoleManagementContentProps, RoleManagementContentState> {
  public state: RoleManagementContentState;
  private fetchPromises: CancelableFetchPromises = {};
  private readonly filterFields: string[];
  private readonly sortFieldMap: DataTableSortFieldMap<Role>;
  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    200: 'Role updated.',
    201: 'Role created.',
    204: 'Role deleted.',
  };

  constructor(props: RoleManagementContentProps) {
    super(props);

    this.state = {
      allPermissions: [],
      isPanelOpen: false,
      isDialogOpen: false,
    };

    this.filterFields = ['name'];
    this.sortFieldMap = { name: 'name' };
  }

  get stores(): RoleManagementContentStores {
    return this.props as RoleManagementContentProps & RoleManagementContentStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchPermissions = async (): Promise<Permission[]> => {
    if (
      this.fetchPromises[EslManagerPrivateRoute.PERMISSIONS] &&
      !this.fetchPromises[EslManagerPrivateRoute.PERMISSIONS].isResolved()
    ) {
      this.fetchPromises[EslManagerPrivateRoute.PERMISSIONS].cancel();
    }

    const { api } = this.stores;

    const endpoint: Endpoint = { path: EslManagerPrivateRoute.PERMISSIONS };

    const requestWrapper = createRequestWrapper<Permission[]>(api, api.getPermissions(), enqueueSnackbar);

    return await requestWrapper(endpoint, HttpMethod.GET);
  };

  public fetchRoles = async (): Promise<PaginationResponse<Role>> => {
    const { api } = this.stores;

    const paginatedRoles = await request<PaginationResponse<Role>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getRoles({ page: 1, limit: 99 }),
      EslManagerPrivateRoute.ROLES,
      HttpMethod.GET,
    );

    return paginatedRoles;
  };

  public fetchItems = async (): Promise<PaginationResponse<Role>> => {
    cancelFetchPromises(this.fetchPromises);

    if (this.state.allPermissions.length === 0) {
      this.fetchPromises[EslManagerPrivateRoute.PERMISSIONS] = makePromiseCancelable(this.fetchPermissions());

      this.fetchPromises[EslManagerPrivateRoute.PERMISSIONS].promise.catch((reason) => {
        if (reason.isCanceled) {
          return;
        }

        throw reason;
      });

      const allPermissions = await this.fetchPromises[EslManagerPrivateRoute.PERMISSIONS].promise;

      this.setState({ allPermissions });
    }

    this.fetchPromises[EslManagerPrivateRoute.ROLES] = makePromiseCancelable(this.fetchRoles());

    this.fetchPromises[EslManagerPrivateRoute.ROLES].promise.catch((reason) => {
      if (reason.isCanceled) {
        return;
      }

      throw reason;
    });

    return await this.fetchPromises[EslManagerPrivateRoute.ROLES].promise;
  };

  public updateRolePermissions = async (role: Role, permissions: Permission[]): Promise<void> => {
    role.permissions = permissions;
    await this.handleSaveRole(role);
  };

  public handleSaveRole = async (
    role: Partial<Role>,
    progressCallback?: TaskCollectionProgressCallback,
  ): Promise<void> => {
    if (role.id === undefined) {
      await this.handleAddRole(role);
    } else {
      await this.handleUpdateRole(role);
    }

    if (progressCallback) {
      // @TODO workaround
      progressCallback('', '');
    }
  };

  public handleAddRole = async (role: Partial<Role>): Promise<Role> => {
    const { api, searchContentStore } = this.stores;

    const endpoint: Endpoint = { path: EslManagerPrivateRoute.ROLES };

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    const requestWrapper = createRequestWrapper<Role>(api, api.addRole(role as Role), enqueueSnackbar, successCallback);

    return await requestWrapper(endpoint, HttpMethod.POST, this.successStatusCodes);
  };

  public handleUpdateRole = async (role: Partial<Role>): Promise<Role> => {
    const { api, searchContentStore, navigationStore } = this.stores;

    const endpoint: Endpoint = {
      path: EslManagerPrivateRoute.ROLE,
      params: { id: role.id },
    };

    const successCallback = async () => {
      searchContentStore.emitRefresh();
      await api.checkAuthentication();

      const routeFilter = (route: RouteShortDefinition) =>
        route.menu !== undefined &&
        (route.permission === undefined || api.anyNodePermissions.indexOf(route.permission) !== -1);

      const menuEntries = Routes.filter(routeFilter);

      navigationStore.initMenuSorting();
      navigationStore.applyPermissionsToMenuSorting(menuEntries.map((entry) => entry.menu?.link as string));
    };

    const requestWrapper = createRequestWrapper<Role>(
      api,
      api.updateRole(role as Role),
      enqueueSnackbar,
      successCallback,
    );

    return await requestWrapper(endpoint, HttpMethod.PUT, this.successStatusCodes);
  };

  public handleDeleteRole = async (role: Role): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const endpoint: Endpoint = {
      path: EslManagerPrivateRoute.ROLE,
      params: { id: role.id },
    };

    const successCallback = () => {
      searchContentStore.emitRefresh();
      api.checkAuthentication();
    };

    const requestWrapper = createRequestWrapper<void>(api, api.deleteRole(role), enqueueSnackbar, successCallback);

    return await requestWrapper(endpoint, HttpMethod.DELETE, this.successStatusCodes);
  };

  public actionHandlerUsers = async (role: Role) => {
    const { history } = this.props;

    history.push('/mappings/role/' + role.id);
  };

  public actionHandlerEdit = async (role: Role) => {
    const { navigationStore } = this.stores;

    this.setState(
      {
        isPanelOpen: true,
        selectedRole: role,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public actionHandlerDeleteDialog = async (role: Role) => {
    this.setState({
      isDialogOpen: true,
      selectedRole: role,
    });
  };

  public onDeleteOk = async () => {
    const { selectedRole } = this.state;

    if (selectedRole?.id !== undefined) {
      await this.handleDeleteRole(selectedRole);
    }

    this.onDismiss();
  };

  public onDismiss = () => {
    this.setState({
      isDialogOpen: false,
      isPanelOpen: false,
      selectedRole: undefined,
    });
  };

  public render() {
    const { classes } = this.props;
    const { allPermissions, isPanelOpen, isDialogOpen, selectedRole } = this.state;

    const deleteDialogText = selectedRole ? `Delete Role ${selectedRole.name}?` : '';

    const columnDefinitions: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(
        this.state,
        this.props as RoleManagementContentPropsWithStores,
        {
          users: this.actionHandlerUsers,
          edit: this.actionHandlerEdit,
          delete: this.actionHandlerDeleteDialog,
        },
        {
          updateRolePermissions: this.updateRolePermissions,
        },
      ),
    );

    const panel = isPanelOpen ? (
      <RolePanel
        role={selectedRole}
        allPermissions={allPermissions}
        closeHandler={this.onDismiss}
        saveHandler={this.handleSaveRole}
        deleteHandler={this.onDeleteOk}
      />
    ) : (
      undefined
    );

    return (
      <>
        <ContentControl
          dialog={
            <GenericDialog
              type="confirmation"
              maxWidth={'sm'}
              fullWidth={true}
              centered={true}
              open={isDialogOpen}
              title={'Delete Role'}
              text={deleteDialogText}
              onClose={this.onDismiss}
              onConfirm={this.onDeleteOk}
            />
          }
          panel={panel}
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedRole: undefined })}
              tooltip={'Add Role'}
              requiredPermission={Permissions.ROLES_WRITE}
            />,
          ]}
        />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={this.fetchItems}
            columns={columnDefinitions}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            disableFooter={true}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<RoleManagementContentProps, typeof RoleManagementContentComponent>(
  RoleManagementContentComponent,
);
const StyleWrapped = withStyles(RoleManagementStyles)(RouterWrapped);

export const RoleManagementContent = StyleWrapped;
