import { EslManagerPublicRouteV1, HttpMethod, Pagination, PaginationResponse, UpdaterProfile } from '@ekkogmbh/apisdk';
import { Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { CompartmentGeneratorStore } from '../../CompartmentManagement/Stores/CompartmentGeneratorStore';
import { UpdaterProfileStore } from '../Stores/UpdaterProfileStore';
import { UpdaterProfileManagementStyles } from '../Styles/UpdaterProfileManagementStyles';
import { materialDatatableColumnDefinitions } from './UpdaterProfileDatatableColumnDefinitions';
import { UpdaterProfilePanel } from './UpdaterProfilePanel';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';
import classNames from 'classnames';

const styles = UpdaterProfileManagementStyles;

const stores = [
  'api',
  'updaterProfileStore',
  'paginationStore',
  'searchContentStore',
  'navigationStore',
  'compartmentGeneratorStore',
];

export interface UpdaterProfileManagementContentActionHandlers {
  edit: (updaterProfile: UpdaterProfile) => void;
  delete: (updaterProfile: UpdaterProfile) => void;
}

export interface UpdaterProfileManagementContentStores {
  api: ApiStore;
  updaterProfileStore: UpdaterProfileStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  compartmentGeneratorStore: CompartmentGeneratorStore;
}

export interface UpdaterProfileManagementContentState {
  selectedUpdaterProfile?: UpdaterProfile;
  isPanelOpen: boolean;
  isDialogOpen: boolean;
}

export interface UpdaterProfileManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type UpdaterProfileManagementContentPropsWithStores = UpdaterProfileManagementContentProps &
  UpdaterProfileManagementContentStores;

@inject(...stores)
class UpdaterProfileManagementContentComponent extends Component<
  UpdaterProfileManagementContentProps,
  UpdaterProfileManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<UpdaterProfile> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<UpdaterProfile> = {
    name: 'UP.name',
  };

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): UpdaterProfileManagementContentStores {
    return this.props as UpdaterProfileManagementContentPropsWithStores;
  }

  public state: UpdaterProfileManagementContentState = {
    isDialogOpen: false,
    isPanelOpen: false,
  };

  public componentWillUnmount() {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchUpdaterProfiles = async (pagination: Pagination): Promise<PaginationResponse<UpdaterProfile>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<UpdaterProfile>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getUpdaterProfiles(pagination),
      EslManagerPublicRouteV1.UPDATER_PROFILES,
      HttpMethod.GET,
    );
  };

  public onDelete = async (updaterProfile: UpdaterProfile): Promise<void> => {
    this.setState({
      selectedUpdaterProfile: updaterProfile,
      isDialogOpen: true,
    });
  };

  public onDismiss = () => {
    this.setState({
      selectedUpdaterProfile: undefined,
      isPanelOpen: false,
      isDialogOpen: false,
    });
  };

  public onDeleteOk = async () => {
    const { selectedUpdaterProfile } = this.state;
    const { api, searchContentStore } = this.stores;

    if (selectedUpdaterProfile === undefined) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteUpdaterProfile(selectedUpdaterProfile.coordinate, selectedUpdaterProfile.name),
      EslManagerPublicRouteV1.UPDATER_PROFILE,
      HttpMethod.DELETE,
      { 200: 'UpdaterProfile deleted.' },
    );

    this.onDismiss();
    searchContentStore.emitRefresh();
  };

  public onEdit = async (updaterProfile: UpdaterProfile): Promise<void> => {
    const { navigationStore, updaterProfileStore } = this.stores;

    updaterProfileStore.setEditableUpdaterProfile(updaterProfile);

    this.setState(
      {
        selectedUpdaterProfile: updaterProfile,
        isPanelOpen: true,
        isDialogOpen: false,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public onSave = async (updaterProfilePayload: UpdaterProfile): Promise<UpdaterProfile> => {
    const { api, navigationStore, searchContentStore, updaterProfileStore } = this.stores;
    const { editableUpdaterProfile } = updaterProfileStore;

    const doOverwrite = editableUpdaterProfile !== undefined;

    const updaterProfile = await request<UpdaterProfile>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveUpdaterProfile(updaterProfilePayload, doOverwrite),
      EslManagerPublicRouteV1.UPDATER_PROFILE,
      HttpMethod.PUT,
      { 200: 'UpdaterProfile saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return updaterProfile;
  };

  public getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return <UpdaterProfilePanel saveHandler={this.onSave} closeHandler={this.onDismiss} />;
  };

  public render() {
    const { classes } = this.props;
    const { isPanelOpen, isDialogOpen, selectedUpdaterProfile } = this.state;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as UpdaterProfileManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={
            <GenericDialog
              type="confirmation"
              maxWidth={'sm'}
              fullWidth={true}
              centered={true}
              open={isDialogOpen}
              title={'Delete Updater Profile'}
              text={selectedUpdaterProfile?.name ?? ''}
              onClose={this.onDismiss}
              onConfirm={this.onDeleteOk}
            />
          }
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              tooltip={'Add Updater Profile'}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedUpdaterProfile: undefined })}
              requiredPermission={Permissions.UPDATER_PROFILE_WRITE}
            />,
          ]}
        />

        <Paper className={classNames(classes.root)}>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchUpdaterProfiles}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<UpdaterProfileManagementContentProps, typeof UpdaterProfileManagementContentComponent>(
  UpdaterProfileManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const UpdaterProfileManagementContent = StyleWrapped;
