import {
  ApiSdkEvents,
  Endpoint,
  EslManagerPrivateRoute,
  EslManagerPublicRouteV1,
  HttpMethod,
  Node,
  PaginationResponse,
  TaskCollectionHandler,
  TaskCollectionView,
} from '@ekkogmbh/apisdk';
import { Paper, Stack, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { DataTable, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { request } from '../../Common/Helper/FetchHandler';
import { NodeSeparator } from '../../Common/Helper/Nodes';
import { injectFakePagination } from '../../Common/Helper/Pagination';
import { CancelableFetchPromises, makePromiseCancelable, noop, noopAsync } from '../../Common/Helper/PromiseHelper';
import { SuccessHandlerStatusMessages } from '../../Common/Helper/ResponseHandler';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { PaginationStore } from '../../Common/Stores/PaginationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { AreaManagementStyles } from '../Styles/AreaManagementStyles';
import { materialDatatableColumnDefinitions } from './AreaDatatableColumnDefinitions';
import { AreaPanel } from './AreaPanel';
import { AnnotationDialog } from 'src/Common/Components/AnnotationDialog';
import { TaskCollectionStore } from 'src/Common/Stores/TaskCollectionStore';
import { Add, Warning } from '@mui/icons-material';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

export const nodeSeparator = ' ' + NodeSeparator + ' ';

enum NodeEndpoint {
  NODE = 'node',
  NODES = 'nodes',
  NODECHILDREN = 'nodechildren',
}

const promiseKeys = {
  ...NodeEndpoint,
  general: 'general',
  rootNodes: 'rootNodes',
};

export interface AreaManagementContentActionHandlers {
  users: (area: Node) => void;
  delete: (area: Node) => void;
  annotations: (area: Node) => void;
  sync: (area: Node) => void;
}

export interface AreaManagementContentState {
  loading: boolean;
  rootNodes: Node[];
  id?: string;
  editableNode?: Node | Partial<Node> | null;
  currentChildnodes: Node[];
  selectedNode?: Node;
  openDialog?: 'annotation' | 'delete' | 'sync';
  openPanel: boolean;
}

interface AreaManagementContentStores {
  api: ApiStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  taskCollectionStore: TaskCollectionStore;
}

const stores = ['api', 'paginationStore', 'searchContentStore', 'navigationStore', 'taskCollectionStore'];

interface AreaManagementContentParams {
  id?: string;
}

interface AreaManagementContentProps
  extends WithStyles<typeof AreaManagementStyles>,
    RouteComponentProps<AreaManagementContentParams> {}

export type AreaManagementContentPropsWithStores = AreaManagementContentProps & AreaManagementContentStores;

@inject(...stores)
class AreaManagementContentComponent extends Component<AreaManagementContentProps, AreaManagementContentState> {
  private currentNode: Node | null = null;
  private readonly filterFields: string[];
  private readonly sortFieldMap: DataTableSortFieldMap<Node>;

  private fetchPromises: CancelableFetchPromises = {};
  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    200: 'Area exists.',
    201: 'Area created.',
    204: 'Area deleted.',
  };

  constructor(props: AreaManagementContentProps) {
    super(props);

    const { id } = props.match.params;

    this.state = {
      loading: false,
      openPanel: false,
      rootNodes: [],
      id,
      editableNode: undefined,
      currentChildnodes: [],
    };

    this.filterFields = ['value'];
    this.sortFieldMap = { value: 'value' };
  }

  get stores(): AreaManagementContentStores {
    return this.props as AreaManagementContentProps & AreaManagementContentStores;
  }

  public componentDidMount(): void {
    const id = this.props.match.params.id;

    this.setCurrentNodeId(id);
  }

  // @TODO deprecated, maybe refactor to getDerivedStateFromProps
  // eslint-disable-next-line react/no-deprecated,@typescript-eslint/no-explicit-any
  public componentWillReceiveProps(nextProps: Readonly<AreaManagementContentProps>, _: any): void {
    const { match } = this.props;

    if (match.params.id !== nextProps.match.params.id) {
      const id = nextProps.match.params.id;

      this.setCurrentNodeId(id);
    }
  }

  public setCurrentNodeId(nodeId: string | undefined): void {
    const { searchContentStore } = this.stores;
    const { id } = this.state;

    const stateCallback = () => {
      searchContentStore.emitRefresh();
    };

    if (nodeId !== id) {
      this.setState({ id: nodeId }, stateCallback);
    }
  }

  public componentWillUnmount(): void {
    this.cancelFetchPromises();
  }

  public handleError = (status: number, response: Response, json: { message: string }): void => {
    if (status !== 409 && status > 400 && status <= 500) {
      enqueueSnackbar(response.statusText + ': ' + json.message, { variant: 'error' });
    }

    if (status === 409) {
      enqueueSnackbar('Conflict: Can not delete area with linked objects.', { variant: 'error' });
    }
  };

  public handleSuccess = ({ status }: Response): void => {
    if (status === 201) {
      enqueueSnackbar('Area created.');
    }

    if (status === 200) {
      enqueueSnackbar('Area already exists.');
    }

    if (status === 204) {
      enqueueSnackbar('Area deleted.');
    }
  };

  public fetch = async (endpoint: NodeEndpoint, id?: number): Promise<Node | PaginationResponse<Node>> => {
    switch (endpoint) {
      case NodeEndpoint.NODE:
        if (id === undefined) {
          throw new Error('no id given.');
        }

        return await this.fetchNode(id);

      case NodeEndpoint.NODECHILDREN:
        if (id === undefined) {
          throw new Error('no id given.');
        }

        return await this.fetchNodeChildren(id);

      case NodeEndpoint.NODES:
      default:
        return await this.fetchNodes();
    }
  };

  public fetchNode = async (id: number): Promise<Node> => {
    const { api } = this.stores;

    const endpoint: Endpoint = {
      path: EslManagerPrivateRoute.NODE,
      params: { id },
    };
    const endpointEvent = api.endpointEventType('request:error' as ApiSdkEvents, endpoint);

    api.once(endpointEvent, this.handleError);

    const promise = api.getNode(id);

    promise
      .then(() => {
        api.off(endpointEvent, this.handleError);
      })
      .catch(noop);

    return await promise;
  };

  public fetchNodes = async (): Promise<PaginationResponse<Node>> => {
    const { api } = this.stores;

    const endpoint: Endpoint = { path: EslManagerPrivateRoute.NODES };
    const endpointEvent = api.endpointEventType('request:error' as ApiSdkEvents, endpoint);

    api.once(endpointEvent, this.handleError);

    this.fetchPromises[promiseKeys.rootNodes] = makePromiseCancelable(api.getNodes());

    this.fetchPromises[promiseKeys.rootNodes].promise
      .then(() => {
        api.off(endpointEvent, this.handleError);
      })
      .catch(noop);

    const data = await this.fetchPromises[promiseKeys.rootNodes].promise;

    const rootNodeIds = data.map((node: Node) => node.id);
    const stateRootNodeIds = this.state.rootNodes.map((node: Node) => node.id);

    if (stateRootNodeIds !== rootNodeIds) {
      this.setState({ rootNodes: data });
    }

    return injectFakePagination<Node>(data);
  };

  public fetchNodeChildren = async (id: number): Promise<PaginationResponse<Node>> => {
    const { api } = this.stores;

    const promise = api.getNodeChildren(id);
    const data = await promise;

    this.setState({ currentChildnodes: data });

    return injectFakePagination<Node>(data);
  };

  public cancelFetchPromises = (): void => {
    Object.keys(this.fetchPromises).forEach((key: string) => {
      if (this.fetchPromises[key] && !this.fetchPromises[key].isResolved()) {
        this.fetchPromises[key].cancel();
      }
    });
  };

  public fetchItems = async (): Promise<Node | PaginationResponse<Node>> => {
    this.cancelFetchPromises();

    this.currentNode = null;

    const { id } = this.state;

    if (id !== undefined && id !== null) {
      const idInt = parseInt(id, 10);
      this.fetchPromises[promiseKeys.NODE] = makePromiseCancelable(this.fetch(NodeEndpoint.NODE, idInt));
      this.currentNode = await this.fetchPromises[promiseKeys.NODE].promise;

      const editableNode = this.currentNode;

      if (editableNode !== this.state.editableNode) {
        this.setState({ editableNode });
      }

      if (this.state.rootNodes.length === 0) {
        this.fetchPromises[promiseKeys.NODES] = makePromiseCancelable(this.fetch(NodeEndpoint.NODES));
        await this.fetchPromises[promiseKeys.NODES].promise;
      }

      this.fetchPromises[promiseKeys.general] = makePromiseCancelable(this.fetch(NodeEndpoint.NODECHILDREN, idInt));
    } else {
      this.fetchPromises[promiseKeys.general] = makePromiseCancelable(this.fetch(NodeEndpoint.NODES));
      this.setState({ editableNode: undefined });
    }

    this.fetchPromises[promiseKeys.general].promise.catch((reason) => {
      if (reason.isCanceled) {
        return;
      }

      throw reason;
    });

    const data = await this.fetchPromises[promiseKeys.general].promise;

    if (this.currentNode) {
      data.items.unshift(this.currentNode);
    }

    return data;
  };

  public actionHandlerSync = async (node: Node) => {
    this.setState({
      selectedNode: node,
      openDialog: 'sync',
    });
  };

  public actionHandlerAnnotations = async (node: Node) => {
    this.setState({
      selectedNode: node,
      openDialog: 'annotation',
    });
  };

  public actionHandlerUsers = async (node: Node) => {
    const { history } = this.props;

    history.push('/areas/' + node.id + '/users/');
  };

  public actionHandlerDeleteDialog = async (node: Node) => {
    this.setState({
      openDialog: 'delete',
      selectedNode: node,
    });
  };

  public closePanel = () => {
    this.setState({
      editableNode: null,
      openPanel: false,
    });
  };

  public togglePanel = () => {
    const { openPanel } = this.state;
    this.setState({ openPanel: !openPanel });
  };

  public annotateNodeHandler = async (annotations: {
    system?: Record<string, string>;
    custom?: Record<string, string>;
  }): Promise<Node> => {
    const { api, searchContentStore } = this.stores;
    const { selectedNode } = this.state;

    const node = await request<Node>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.annotateNode(selectedNode!, annotations),
      EslManagerPublicRouteV1.NODE_ANNOTATE,
      HttpMethod.PUT,
    );

    if (node) {
      searchContentStore.emitRefresh();
    }

    return node;
  };

  public saveNodeHandler = async (value: string, parentNode: Node): Promise<Node> => {
    const { api, searchContentStore } = this.stores;

    const node = await request<Node>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.addNodeChild(value, parentNode),
      EslManagerPrivateRoute.NODECHILDREN,
      HttpMethod.POST,
      this.successStatusCodes,
    );

    if (node) {
      searchContentStore.emitRefresh();
    }

    return node;
  };

  public deleteNodeHandler = async (node: Node): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteNode(node),
      EslManagerPrivateRoute.NODE,
      HttpMethod.DELETE,
      this.successStatusCodes,
    );

    searchContentStore.emitRefresh();
  };

  public onDeleteOk = async () => {
    const { selectedNode } = this.state;

    this.setState({
      selectedNode: undefined,
      openDialog: undefined,
    });

    if (selectedNode && selectedNode.id !== undefined) {
      await this.deleteNodeHandler(selectedNode as Node);
    }
  };

  public onSyncOk = async () => {
    const { api, taskCollectionStore } = this.stores;
    const { selectedNode } = this.state;
    if (selectedNode === undefined) {
      return;
    }

    const taskCollectionHandlerFn: TaskCollectionHandler = (taskCollectionUri) =>
      taskCollectionStore.processTaskCollection(taskCollectionUri);

    await request<TaskCollectionView>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.syncCompartmentLinks([], [selectedNode.coordinate], taskCollectionHandlerFn),
      EslManagerPrivateRoute.SYNC_COMPARTMENT_LINKS,
      HttpMethod.POST,
    );

    this.setState({ openDialog: undefined, selectedNode: undefined });
  };

  public getOpenDialog(): JSX.Element {
    const { openDialog, selectedNode } = this.state;

    const isExistingNode = selectedNode !== undefined && selectedNode.id !== undefined;

    if (!isExistingNode) {
      return <></>;
    }

    const dismissDialog = () => {
      this.setState({
        openDialog: undefined,
        selectedNode: undefined,
      });
    };

    switch (openDialog) {
      case 'delete':
        return (
          <GenericDialog
            open
            centered
            fullWidth
            type="confirmation"
            maxWidth={'sm'}
            title={'Delete Area'}
            text={`Delete Area ${selectedNode.value}?`}
            onClose={dismissDialog}
            onConfirm={this.onDeleteOk}
          />
        );
      case 'annotation':
        return (
          <AnnotationDialog
            open
            editable
            title={'Node Annotations'}
            annotations={selectedNode!.annotations}
            isRemovableFilter={(_key, entry) => {
              if (entry.path === undefined) {
                // system annotation
                return false;
              } else if (entry.path !== selectedNode.path) {
                // inherited annotation
                return `Inherited from Area: "${entry.path}"`;
              } else {
                // own annotation
                return true;
              }
            }}
            systemKeys={['inventoryField', 'ekanbanState', 'fenceName', 'fenceId']}
            onSaveCallback={async (annotations: Record<string, string>) => {
              const system: Record<string, string> = {};
              const custom: Record<string, string> = {};

              Object.keys(annotations).forEach((key: string) => {
                if (key.startsWith('_')) {
                  system[key.substring(1)] = annotations[key];
                } else {
                  custom[key] = annotations[key];
                }
              });

              this.annotateNodeHandler({ custom, system });
            }}
            onClose={dismissDialog}
          />
        );
      case 'sync':
        return (
          <GenericDialog
            open
            centered
            fullWidth
            type={'confirmation'}
            maxWidth={'sm'}
            title={'Synchronize linked devices in Area'}
            text={
              <Stack direction={'column'} spacing={2} alignItems={'center'}>
                <Typography variant={'body1'} fontWeight={'bold'}>{`${selectedNode.path}`}</Typography>
                <div>
                  <Warning />
                  <Typography variant={'subtitle1'}>
                    {'Synchronizing areas with many linked devices may take some time'}
                  </Typography>
                </div>
              </Stack>
            }
            onClose={dismissDialog}
            onConfirm={this.onSyncOk}
            okButtonDisabled={false}
          />
        );
      default:
        return <></>;
    }
  }

  public render() {
    const { currentChildnodes, editableNode, openPanel, rootNodes } = this.state;
    const { classes } = this.props;

    const columnDefinitions: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as AreaManagementContentPropsWithStores, {
        users: this.actionHandlerUsers,
        delete: this.actionHandlerDeleteDialog,
        annotations: this.actionHandlerAnnotations,
        sync: this.actionHandlerSync,
      }),
    );

    const panel = openPanel ? (
      <AreaPanel
        node={editableNode as Node}
        userRootNodes={rootNodes}
        childnodes={currentChildnodes}
        closeHandler={this.closePanel}
        saveHandler={this.saveNodeHandler}
        deleteHandler={noopAsync}
      />
    ) : (
      undefined
    );

    return (
      <>
        <ContentControl
          panel={panel}
          dialog={this.getOpenDialog()}
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              onClick={this.togglePanel}
              tooltip={'Add Node'}
              requiredPermission={Permissions.AREAS_WRITE}
            />,
          ]}
        />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={this.fetchItems as () => Promise<PaginationResponse<Node>>}
            columns={columnDefinitions}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            disableFooter={true}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<AreaManagementContentProps, typeof AreaManagementContentComponent>(
  AreaManagementContentComponent,
);
const StyleWrapped = withStyles(AreaManagementStyles)(RouterWrapped);

export const AreaManagementContent = StyleWrapped;
