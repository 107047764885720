import { Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { DevicePoolManagementStyles } from '../Styles/DevicePoolManagementStyles';
import { RouteComponentProps, withRouter } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { inject } from 'mobx-react';
import { Add } from '@mui/icons-material';
import { DevicePoolPanel } from './DevicePoolPanel';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { DevicePool, EslManagerPublicRouteV2, HttpMethod, Pagination, PaginationResponse } from '@ekkogmbh/apisdk';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { materialDatatableColumnDefinitions } from './DevicePoolColumnDefinitions';
import { request } from 'src/Common/Helper/FetchHandler';
import { DevicePoolStore } from '../Stores/DevicePoolStore';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import classNames from 'classnames';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

const styles = DevicePoolManagementStyles;

const stores = ['api', 'searchContentStore', 'navigationStore', 'devicePoolStore'];

export interface DevicePoolManagementContentActionHandlers {
  edit: (devicePool: DevicePool) => void;
  delete: (devicePool: DevicePool) => void;
}

export interface DevicePoolManagementContentStores {
  api: ApiStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  devicePoolStore: DevicePoolStore;
}

export interface DevicePoolManagementContentState {
  selectedDevicePool?: DevicePool;
  isPanelOpen: boolean;
  isDialogOpen: boolean;
}

export interface DevicePoolManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type DevicePoolManagementContentPropsWithStores = DevicePoolManagementContentProps &
  DevicePoolManagementContentStores;

@inject(...stores)
class DevicePoolManagementContentComponent extends Component<
  DevicePoolManagementContentProps,
  DevicePoolManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<DevicePool> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<DevicePool> = { name: 'DP.name' };

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): DevicePoolManagementContentStores {
    return this.props as DevicePoolManagementContentPropsWithStores;
  }

  public state: DevicePoolManagementContentState = {
    isPanelOpen: false,
    isDialogOpen: false,
  };

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchDevicePools = async (pagination: Pagination): Promise<PaginationResponse<DevicePool>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<DevicePool>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getDevicePools(pagination),
      EslManagerPublicRouteV2.DEVICE_POOLS,
      HttpMethod.GET,
    );
  };

  private onSave = async (): Promise<void> => {
    const { api, navigationStore, searchContentStore, devicePoolStore } = this.stores;

    const allowOverwrite = devicePoolStore.editableDevicePool !== undefined;

    await request<DevicePool>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveDevicePool(devicePoolStore.getSavableDevicePool(), allowOverwrite),
      EslManagerPublicRouteV2.DEVICE_POOL,
      HttpMethod.PUT,
      { 200: 'Device Pool saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();
  };

  private onDismiss = () => {
    this.setState({
      selectedDevicePool: undefined,
      isDialogOpen: false,
      isPanelOpen: false,
    });
  };

  private onEdit = async (devicePool: DevicePool): Promise<void> => {
    const { navigationStore, devicePoolStore } = this.stores;

    devicePoolStore.setEditableDevicePool(devicePool);

    this.setState(
      {
        selectedDevicePool: devicePool,
        isPanelOpen: true,
        isDialogOpen: false,
      },
      () => navigationStore.scrollTop(),
    );
  };

  private onDeleteConfirm = async () => {
    const { selectedDevicePool } = this.state;
    const { api, searchContentStore } = this.stores;

    if (selectedDevicePool === undefined) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteDevicePool(selectedDevicePool),
      EslManagerPublicRouteV2.DEVICE_POOL,
      HttpMethod.DELETE,
      { 200: 'Device Pool deleted.' },
    );

    this.onDismiss();
    searchContentStore.emitRefresh();
  };

  private getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return <DevicePoolPanel saveHandler={this.onSave} closeHandler={this.onDismiss} />;
  };

  public render() {
    const { classes } = this.props;
    const { selectedDevicePool, isPanelOpen, isDialogOpen } = this.state;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as DevicePoolManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: (devicePool) => this.setState({ selectedDevicePool: devicePool }),
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={
            <GenericDialog
              type="confirmation"
              maxWidth={'sm'}
              fullWidth={true}
              centered={true}
              open={isDialogOpen}
              title={'Delete Device Pool'}
              text={selectedDevicePool?.name ?? ''}
              onClose={this.onDismiss}
              onConfirm={this.onDeleteConfirm}
            />
          }
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              tooltip={'Add Device Pool'}
              requiredPermission={Permissions.DEVICE_POOLS_WRITE}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedDevicePool: undefined })}
            />,
          ]}
        />

        <Paper className={classNames(classes.root)}>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchDevicePools}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<DevicePoolManagementContentProps, typeof DevicePoolManagementContentComponent>(
  DevicePoolManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const DevicePoolManagementContent = StyleWrapped;
