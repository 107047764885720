import {
  EslManagerPublicRouteV2,
  HttpMethod,
  Pagination,
  PaginationResponse,
  Zone,
  ZonePayload,
} from '@ekkogmbh/apisdk';
import { Add } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject } from 'mobx-react';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { NotificationStrategyStore } from '../Stores/NotificationStrategyStore';
import { ZoneStore } from '../Stores/ZoneStore';
import { ZoneManagementStyles } from '../Styles/ZoneManagementStyles';
import { materialDatatableColumnDefinitions } from './ZoneColumnDefinitions';
import { ZonePanel } from './ZonePanel';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

const styles = ZoneManagementStyles;

const stores = ['api', 'searchContentStore', 'navigationStore', 'zoneStore', 'notificationStrategyStore'];

export interface ZoneManagementContentActionHandlers {
  blueprints: (zone: Zone) => void;
  edit: (zone: Zone) => void;
  delete: (zone: Zone) => void;
}

export interface ZoneManagementContentStores {
  api: ApiStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  zoneStore: ZoneStore;
  notificationStrategyStore: NotificationStrategyStore;
}

export interface ZoneManagementContentState {
  selectedZone?: Zone;
  isPanelOpen: boolean;
  isDialogOpen: boolean;
  forceDelete: boolean;
}

export interface ZoneManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type ZoneManagementContentPropsWithStores = ZoneManagementContentProps & ZoneManagementContentStores;

@inject(...stores)
class ZoneManagementContentComponent extends Component<ZoneManagementContentProps, ZoneManagementContentState> {
  private readonly filterFields: DataTableFilterFields<Zone> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<Zone> = { name: 'Z.name' };

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): ZoneManagementContentStores {
    return this.props as ZoneManagementContentPropsWithStores;
  }

  public state: ZoneManagementContentState = {
    forceDelete: false,
    isDialogOpen: false,
    isPanelOpen: false,
  };

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchZones = async (pagination: Pagination): Promise<PaginationResponse<Zone>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<Zone>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getZones(pagination),
      EslManagerPublicRouteV2.ZONES,
      HttpMethod.GET,
    );
  };

  private onSave = async (savableZone: ZonePayload, allowOverwrite: boolean): Promise<void> => {
    const { api, navigationStore, searchContentStore } = this.stores;

    await request<Zone>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveZone(savableZone, allowOverwrite),
      EslManagerPublicRouteV2.ZONE,
      HttpMethod.PUT,
      { 200: 'Zone saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();
  };

  private onEdit = async (zone: Zone): Promise<void> => {
    const { navigationStore, zoneStore, notificationStrategyStore } = this.stores;

    zoneStore.setEditableZone(zone);
    notificationStrategyStore.resetStore(zone.notificationStrategy);

    this.setState(
      {
        isPanelOpen: true,
        selectedZone: zone,
      },
      () => navigationStore.scrollTop(),
    );
  };

  private onDismiss = () => {
    this.setState({
      selectedZone: undefined,
      isPanelOpen: false,
      isDialogOpen: false,
      forceDelete: false,
    });
  };

  private onDeleteConfirm = async () => {
    const { selectedZone, forceDelete } = this.state;
    const { api, searchContentStore } = this.stores;

    if (selectedZone === undefined) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteZone(selectedZone, forceDelete),
      EslManagerPublicRouteV2.ZONE,
      HttpMethod.DELETE,
      { 204: 'Zone deleted.' },
    );

    this.onDismiss();
    searchContentStore.emitRefresh();
  };

  private getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return <ZonePanel saveHandler={this.onSave} closeHandler={this.onDismiss} />;
  };

  public actionHandlerBlueprints = (zone: Zone) => {
    const { navigationStore } = this.stores;
    navigationStore.scrollTop();

    this.props.history.push('/picking/zones/' + zone.name + '/' + zone.coordinate);
  };

  public render() {
    const { classes } = this.props;
    const { selectedZone, isPanelOpen, isDialogOpen, forceDelete } = this.state;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as ZoneManagementContentPropsWithStores, {
        blueprints: this.actionHandlerBlueprints,
        edit: this.onEdit,
        delete: (zone) => this.setState({ selectedZone: zone, isDialogOpen: true }),
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={
            <GenericDialog
              type="confirmation"
              maxWidth={'sm'}
              fullWidth={true}
              centered={true}
              open={isDialogOpen}
              text={
                <>
                  {forceDelete && (
                    <>
                      <Typography color={'error'}>{'open orders found'}</Typography>
                      <Typography color={'primary'}>{'delete anyway?'}</Typography>
                    </>
                  )}
                  {!forceDelete && <Typography color={'primary'}>{'confirm deletion'}</Typography>}
                </>
              }
              title={`Delete "${selectedZone?.name ?? ''}" ?`}
              onClose={this.onDismiss}
              onConfirm={this.onDeleteConfirm}
              timedOkButton={forceDelete}
            />
          }
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              tooltip={'Add Picking Zone'}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedZone: undefined })}
              requiredPermission={Permissions.ZONES_WRITE}
            />,
          ]}
        />

        <Paper className={classes.root}>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchZones}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<ZoneManagementContentProps, typeof ZoneManagementContentComponent>(
  ZoneManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const ZoneManagementContent = StyleWrapped;
