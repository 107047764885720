import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { spacing } from '../Helper/ThemeHelper';

const fabWidth = 56;

export const ContentControlStyles = (theme: Theme): Record<string, CSSProperties> => ({
  root: {
    width: '100%',
    overflow: 'hidden' as const,
    boxShadow: theme.shadows[5],
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen * 4,
    }),
  },
  rootWhenNotExpanded: {
    margin: 0,
    minHeight: 0,
    height: 0,
  },
  rootWhenExpanded: {
    marginBottom: 48,
  },
  expansion: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  expansionExpanded: {
    minHeight: spacing(theme) * 7,
    marginTop: '16px !important',
    margin: '16px !important',
  },
  buttonRow: {
    zIndex: 999,
    position: 'absolute',
    top: spacing(theme) * -5,
    right: spacing(theme) * 3,
  },
  button: {
    flexGrow: 1,
    width: '100%',
  },
  fab: {
    width: fabWidth,
    margin: spacing(theme),
  },
  fabConditional: {
    backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&:hover $flipBack': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  // flip effect
  flip: {
    width: 48,
    height: 24,
    perspective: 1000,
    '&:hover $flipInner': {
      transform: 'rotateY(180deg)',
    },
  },
  flipInner: {
    position: 'relative',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d',
  },
  flipMain: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
  },
  flipBack: {
    fontSize: 14,
    fontWeight: 700,
    transform: 'rotateY(180deg)',
    backgroundColor: theme.palette.secondary.light,
  },
});
