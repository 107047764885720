import {
  CompartmentSavePayload,
  EslManagerPrivateRoute,
  HttpMethod,
  PaginationResponse,
  Preset,
  RendererPayload,
  RendererResult,
  Template,
} from '@ekkogmbh/apisdk';
import { Fade, Grid, SelectChangeEvent, Stack } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, Component } from 'react';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { TemplatePreviewList } from 'src/TemplateManagement/Components/TemplatePreviewList';
import { CoordinateInput } from '../../Common/Components/CoordinateInput';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { request } from '../../Common/Helper/FetchHandler';
import { onKeyPressCallback } from '../../Common/Helper/FormHelper';
import { CancelableFetchPromises, cancelFetchPromises, noop } from '../../Common/Helper/PromiseHelper';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { ConfigStore } from '../../Common/Stores/ConfigStore';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { ContentPanel } from 'src/Common/Components/ContentControl/ContentPanel';
import { CompartmentStore } from '../Stores/CompartmentStore';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api', 'configStore', 'compartmentStore'];
interface CompartmentPanelStores {
  api: ApiStore;
  configStore: ConfigStore;
  compartmentStore: CompartmentStore;
}

interface CompartmentPanelState {
  fieldsPreset: string;
  presetsById: Record<number, Preset>;
  changedPreset: boolean;
  loading: boolean;
  selectedPresetId: number;
}

export interface CompartmentPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (compartment: CompartmentSavePayload) => Promise<void>;
}

@inject(...stores)
@observer
class CompartmentPanelComponent extends Component<CompartmentPanelProps, CompartmentPanelState> {
  public state: CompartmentPanelState = {
    fieldsPreset: '',
    presetsById: {},
    changedPreset: false,
    loading: false,
    selectedPresetId: -1,
  };
  private fetchPromises: CancelableFetchPromises = {};
  private progressCompleteCallback: () => void = noop;

  get stores(): CompartmentPanelStores {
    return this.props as CompartmentPanelProps & CompartmentPanelStores;
  }

  public async componentDidMount(): Promise<void> {
    const { compartmentStore } = this.stores;
    const { editableCompartment } = compartmentStore;

    this.setState(
      {
        loading: true,
      },
      async () => {
        const userPresets = (await this.fetchPresets()).items ?? [];
        const presetsById = userPresets.reduce((carry: Record<number, Preset>, p: Preset) => {
          carry[p.id] = p;
          return carry;
        }, {});

        if (editableCompartment !== undefined) {
          compartmentStore.resetWithCompartment(editableCompartment);
        }

        this.setState({
          loading: false,
          presetsById,
        });
      },
    );
  }

  public componentWillUnmount(): void {
    const { compartmentStore } = this.stores;
    compartmentStore.resetWithCompartment();
    cancelFetchPromises(this.fetchPromises);
    this.progressCompleteCallback = noop;
  }

  public fetchPresets = async (): Promise<PaginationResponse<Preset>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<Preset>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getPresets({ page: 1, limit: 1000 }),
      EslManagerPrivateRoute.PRESETS,
      HttpMethod.GET,
    );
  };

  public fetchRendererResult = async (payload: RendererPayload): Promise<RendererResult> => {
    const { api } = this.stores;

    return await request<RendererResult>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.renderTemplate(payload),
      EslManagerPrivateRoute.RENDERER,
      HttpMethod.POST,
    );
  };

  public fetchTemplateWithData = async (template: Template): Promise<Template> => {
    const { api } = this.stores;

    return await request<Template>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getTemplate(template),
      EslManagerPrivateRoute.TEMPLATE,
      HttpMethod.GET,
    );
  };

  public resetState = () => {
    const { compartmentStore } = this.stores;

    compartmentStore.resetWithCompartment(compartmentStore.editableCompartment);
  };

  public onChangeField = (event: ChangeEvent<HTMLInputElement>) => {
    const { compartmentStore } = this.stores;
    const { name, value } = event.target;

    compartmentStore.setField(name, value);
  };

  public onChangeFieldsPreset = (event: SelectChangeEvent<unknown>) => {
    const { compartmentStore } = this.stores;
    const { presetsById } = this.state;

    const { value } = event.target;
    const selectedId = value as number;

    /**
     * @TODO maybe show a warning when changing preset in edit mode
     */
    this.setState({ selectedPresetId: selectedId }, () => {
      if (selectedId >= 0) {
        compartmentStore.resetWithPreset(presetsById[selectedId], true);
      } else {
        compartmentStore.resetWithCompartment(compartmentStore.editableCompartment);
      }
    });
  };

  public onSave = async () => {
    const { compartmentStore } = this.stores;
    const { closeHandler, saveHandler } = this.props;

    this.progressCompleteCallback = () => {
      this.setState({ loading: false });
      closeHandler();
    };

    this.setState({ loading: true }, async () => {
      try {
        await saveHandler(compartmentStore.getPayload());
        this.progressCompleteCallback();
      } catch (e) {
        enqueueSnackbar((e as Error).message, { variant: 'error' });
        this.setState({ loading: false });
      }
    });
  };

  public onCancel = () => {
    const { closeHandler } = this.props;
    this.resetState();
    closeHandler();
  };

  private presetSelect = (): JSX.Element => {
    const { presetsById, selectedPresetId } = this.state;

    const options = Object.values(presetsById).map((preset: Preset) => (
      <option key={preset.id} value={preset.id}>
        {preset.name}
      </option>
    ));
    options.unshift(
      <option key={-1} value={-1}>
        {'-'}
      </option>,
    );

    return (
      <Fade in={true} timeout={fadeTimeout}>
        <StyledSelectField native value={selectedPresetId} onChange={this.onChangeFieldsPreset} label="Preset">
          {options}
        </StyledSelectField>
      </Fade>
    );
  };

  private coordinateInput = (editMode: boolean): JSX.Element => {
    const { compartmentStore } = this.stores;
    const { presetsById, selectedPresetId } = this.state;
    const { coordinate } = compartmentStore.state;

    const isPresetSelected = selectedPresetId >= 0;
    const coordinatePrefix = isPresetSelected ? presetsById[selectedPresetId].coordinatePrefix : '';

    const trimmedCoordinate =
      isPresetSelected && coordinate.startsWith(coordinatePrefix)
        ? coordinate.replace(coordinatePrefix, '')
        : coordinate;

    return (
      <Fade in timeout={fadeTimeout}>
        <Grid container spacing={1}>
          {!editMode && isPresetSelected && (
            <Grid item xs={6}>
              <StyledTextField
                onChange={() => noop}
                label={'Coordinate Prefix'}
                disabled={true}
                value={coordinatePrefix}
              />
            </Grid>
          )}
          <Grid item xs={editMode ? 12 : 6}>
            {(isPresetSelected || editMode) && (
              <CoordinateInput
                disabled={editMode}
                onChange={(coordinateInput) => compartmentStore.setCoordinate(coordinatePrefix + coordinateInput)}
                value={trimmedCoordinate}
                trimPrefix={coordinatePrefix}
                trailingDelimiter="both"
              />
            )}
          </Grid>
        </Grid>
      </Fade>
    );
  };

  private fieldsInput = (): JSX.Element => {
    const { compartmentStore } = this.stores;
    const { fields } = compartmentStore.state;

    const keys = Object.keys(fields);

    if (keys.length === 0) {
      return <></>;
    }

    const fieldElements = keys.map((key: string, i: number) => (
      <Fade in timeout={fadeTimeout} key={i}>
        <Grid item lg={6} xs={12}>
          <StyledTextField
            label={key}
            value={fields[key]}
            onChange={(e) => compartmentStore.setField(key, e.target.value as string)}
          />
        </Grid>
      </Fade>
    ));

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledFormHeader label={'Fields'} />
        </Grid>
        <Grid container item xs={12} spacing={1}>
          {fieldElements}
        </Grid>
      </Grid>
    );
  };

  public templatePreview(): JSX.Element {
    const { compartmentStore, configStore } = this.stores;
    const uniqueTemplates = compartmentStore
      .getLinkedTemplates()
      .filter(
        (value, index, self) =>
          index === self.findIndex((template) => template.id === value.id && template.name === value.name),
      );

    if (uniqueTemplates.length === 0) {
      return <></>;
    }

    const { coordinate, fields } = compartmentStore.state;
    const { coordinateFieldName, labelIdFieldName } = configStore.config;

    return (
      <Fade in timeout={fadeTimeout}>
        <Stack direction={'column'} spacing={1}>
          <StyledFormHeader label={'Linked Templates'} />
          <TemplatePreviewList
            templates={uniqueTemplates}
            fields={fields}
            coordinate={coordinate}
            coordinateFieldName={coordinateFieldName}
            labelIdFieldName={labelIdFieldName}
            fetchRendererResult={this.fetchRendererResult}
            fetchTemplateWithData={this.fetchTemplateWithData}
          />
        </Stack>
      </Fade>
    );
  }

  public makeForm(editMode: boolean): JSX.Element {
    const { compartmentStore } = this.stores;
    const handleKeyPress = onKeyPressCallback(this.onSave, compartmentStore.isSavable, 'Enter');

    return (
      <Grid
        container
        item
        xs={11}
        spacing={1}
        justifyContent={'flex-start'}
        onKeyDown={handleKeyPress}
        style={{ minWidth: 0 }}
      >
        <Grid item xs={10}>
          {this.presetSelect()}
        </Grid>
        <Grid item xs={10}>
          {this.coordinateInput(editMode)}
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={editMode ? 9 : 12} sx={{ padding: 0 }}>
            {this.fieldsInput()}
          </Grid>
          {editMode && (
            <Grid item xs={3}>
              {this.templatePreview()}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  public makePanel(editMode: boolean): JSX.Element {
    const { compartmentStore } = this.stores;

    return (
      <ContentPanel
        content={this.makeForm(editMode)}
        panelButtonProps={{
          cancelHandler: this.onCancel,
          resetHandler: this.resetState,
          saveHandler: this.onSave,
          isResetDisabled: false,
          isSaveDisabled: !compartmentStore.isSavable,
          isDeleteHidden: true,
        }}
      />
    );
  }

  public render() {
    const { compartmentStore } = this.stores;
    const { loading } = this.state;

    const isEditMode = compartmentStore.editableCompartment !== undefined;

    return (
      <>
        {loading && <LoadingMask />}
        {this.makePanel(isEditMode)}
      </>
    );
  }
}

export const CompartmentPanel = withStyles(styles)(CompartmentPanelComponent);
