import {
  EslManagerPublicRouteV2,
  HttpMethod,
  Pagination,
  PaginationResponse,
  Zone,
  ZoneIdentifier,
} from '@ekkogmbh/apisdk';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import {
  Fade,
  IconButton,
  ListItemText,
  ListSubheader,
  MenuItem,
  SelectChangeEvent,
  SelectProps,
  Stack,
  Typography,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { Component } from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;

interface ZonePickerStores {
  api: ApiStore;
}

interface ZonePickerProps extends WithStyles<typeof styles> {
  selected?: ZoneIdentifier;
  optional?: boolean;
  onChange: (zone: ZoneIdentifier | undefined) => void;
  onError?: () => void;
  disabled?: boolean;
  renderValue?: SelectProps['renderValue'];
  label?: string;
}

interface ZonePickerState {
  loading: boolean;
  failure: boolean;
  zones: (ZoneIdentifier | undefined)[];
  page: number;
  pageCount: number;
}

@inject('api')
class ZonePickerComponent extends Component<ZonePickerProps, ZonePickerState> {
  public state: ZonePickerState = {
    loading: true,
    failure: false,
    zones: [],
    page: 1,
    pageCount: 1,
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): ZonePickerStores {
    return this.props as ZonePickerProps & ZonePickerStores;
  }

  public componentDidMount(): void {
    this.setState({ loading: true }, this.fetchZones);
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchZones = async (): Promise<void> => {
    const { api } = this.stores;
    const { page } = this.state;
    const { onError, onChange, optional, selected } = this.props;

    const onErrorCallback = () => {
      this.setState({ failure: true, zones: [] }, onError);
    };

    let zones: (ZoneIdentifier | undefined)[] = [];
    let pageCount: number = 1;
    try {
      const pagedZones = await request<PaginationResponse<Zone>>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getZones({ page, limit: 9 } as Pagination),
        EslManagerPublicRouteV2.ZONES,
        HttpMethod.GET,
        undefined,
        undefined,
        onErrorCallback,
      );

      zones = pagedZones.items ?? [];
      pageCount = pagedZones.pageCount ?? 1;
    } catch (e) {
      cancelFetchPromises(this.fetchPromises);
    }

    if (optional) {
      zones.unshift(undefined);
    } else {
      if (zones.length === 0) {
        enqueueSnackbar('No Zones Found.');
        onErrorCallback();
        return;
      }
    }

    let selectedIndex = this.getListIndex(selected, zones);
    if (selectedIndex === -1) {
      if (optional) {
        selectedIndex = 0;
      } else {
        // the selected zone may just be on another page
        // adding to the end to enable re-selection
        zones.push(selected);
        selectedIndex = zones.length - 1;
      }

      onChange(zones[selectedIndex]);
    }

    this.setState({ loading: false, zones, pageCount });
  };

  private getListIndex = (zone: ZonePickerProps['selected'], list: ZonePickerState['zones']): number => {
    if (zone === undefined) {
      return 0;
    }

    return list.findIndex(
      (z: ZoneIdentifier | undefined) => z && z.name === zone.name && z.coordinate === zone.coordinate,
    );
  };

  private onChangeZone = (event: SelectChangeEvent<unknown>): void => {
    const { onChange } = this.props;
    const { zones } = this.state;

    const value = event.target.value as number;

    onChange(zones[value]);
  };

  private onChangePage = (page: number) => {
    this.setState({ page }, this.fetchZones);
  };

  private createMenuItem = (index: number, zone?: ZoneIdentifier): React.JSX.Element => {
    const name = zone ? zone.name : '-';
    const coordinate = zone ? zone.coordinate : '';

    return (
      <MenuItem key={`zone-picker-item-${name}-${coordinate}`} value={index}>
        <ListItemText primary={name} secondary={coordinate} />
      </MenuItem>
    );
  };

  public render() {
    const { label, disabled, selected } = this.props;
    const { zones, loading, failure, page, pageCount } = this.state;

    const entries = zones.map((zone: ZoneIdentifier | undefined, index: number) => this.createMenuItem(index, zone));

    return (
      <>
        {loading && (
          <div
            style={{
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: 1,
              borderRadius: 4,
              margin: 8,
              padding: 0,
              width: '100%',
            }}
          >
            <Fade in={true} timeout={2000}>
              <CheckmarkSpinner complete={false} failure={failure} />
            </Fade>
          </div>
        )}
        {!loading && (
          <StyledSelectField
            value={selected ? this.getListIndex(selected, zones) : 0}
            label={label ?? 'Zone'}
            onChange={this.onChangeZone}
            disabled={disabled}
          >
            {entries}
            {pageCount > 1 && (
              <ListSubheader>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <IconButton disabled={page === 1} title={'prev'} onClick={() => this.onChangePage(page - 1)}>
                    <ArrowBackIos />
                  </IconButton>
                  <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
                    <Typography>{'page'}</Typography>
                    <Typography>{`${page} / ${pageCount}`}</Typography>
                  </Stack>
                  <IconButton disabled={page === pageCount} title={'next'} onClick={() => this.onChangePage(page + 1)}>
                    <ArrowForwardIos />
                  </IconButton>
                </Stack>
              </ListSubheader>
            )}
          </StyledSelectField>
        )}
      </>
    );
  }
}

const StyleWrapped = withStyles(styles)(ZonePickerComponent);

export const ZonePicker = StyleWrapped;
