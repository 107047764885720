import { Compartment, CompartmentSavePayload, CompartmentView, Preset, Template } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';

type CompartmentState = Pick<Compartment, 'coordinate' | 'fields'>;

export class CompartmentStore {
  @observable
  public editableCompartment?: CompartmentView;

  @observable
  public state: CompartmentState = {
    coordinate: '',
    fields: {},
  };

  @observable
  public isSavable: boolean = false;

  @action
  public setState = (newState: Partial<CompartmentState>): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    if (this.editableCompartment !== undefined) {
      this.isSavable = Object.values(this.editableCompartment.fields) !== Object.values(this.state.fields);
    } else {
      this.isSavable = this.state.coordinate !== '';
    }
  };

  public setField = (key: string, value: string): void => {
    this.setState({
      fields: {
        ...this.state.fields,
        [key]: value,
      },
    });
  };

  public setCoordinate = (coordinate: string): void => {
    this.setState({ coordinate });
  };

  public resetWithPreset = (preset: Pick<Preset, 'coordinatePrefix' | 'keys'>, doMerge: boolean = false): void => {
    const { coordinate, fields } = this.state;
    const { coordinatePrefix, keys } = preset;

    const newFields: Record<string, string> = {};
    if (keys !== null) {
      keys.forEach((key: string) => {
        if (doMerge && fields[key] !== undefined) {
          newFields[key] = fields[key];
        } else {
          newFields[key] = '';
        }
      });
    }

    this.setState({
      coordinate: doMerge && coordinate.startsWith(coordinatePrefix) ? coordinate : '',
      fields: newFields,
    });
  };

  public resetWithCompartment = (compartment?: CompartmentView): void => {
    this.editableCompartment = compartment;

    if (compartment !== undefined) {
      const { coordinate, fields } = compartment;
      this.setState({
        fields,
        coordinate,
      });
    } else {
      this.setState({
        coordinate: '',
        fields: {},
      });
    }
  };

  public getLinkedTemplates = (): Template[] =>
    this.editableCompartment !== undefined ? this.editableCompartment.links.map((link) => link.template) : [];

  public getPayload = (): CompartmentSavePayload => this.state as CompartmentSavePayload;
}
