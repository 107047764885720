import { Box, Collapse, Slide, Stack, StepConnector, Stepper, Theme, TypographyProps } from '@mui/material';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

const styles = (_: Theme) => ({
  root: {},
  labelText: {
    fill: 'white',
  },
});

const Connector = styled(StepConnector)(() => ({
  top: 15,
  left: 'calc(-50% + 32px)',
  right: 'calc(50% + 32px)',
}));

export interface DynamicStepperStep {
  title: string;
  titleProps?: TypographyProps;
  description?: string | React.JSX.Element;
  elementCallback: () => React.JSX.Element;
}

interface DynamicStepperState {
  activeStep: number;
  prevStep: number;
}

interface DynamicStepperProps extends WithStyles<typeof styles>, RouteComponentProps {
  activeStep: number;
  steps: DynamicStepperStep[];
  minHeight?: number;
  alternativeLabel?: boolean;
}

@observer
class DynamicStepperComponent extends Component<DynamicStepperProps, DynamicStepperState> {
  public state: DynamicStepperState = {
    activeStep: 0,
    prevStep: 0,
  };

  public static getDerivedStateFromProps(
    props: Readonly<DynamicStepperProps>,
    state: DynamicStepperState,
  ): Partial<DynamicStepperState> | null {
    return { activeStep: props.activeStep, prevStep: state.activeStep };
  }

  public render() {
    const { activeStep, prevStep } = this.state;
    const { alternativeLabel, steps, minHeight } = this.props;

    return (
      <Stack direction={'column'} spacing={2} alignItems={'stretch'}>
        <div style={{ minHeight: activeStep >= steps.length ? 0 : minHeight ?? 200 }}>
          {steps.map(({ elementCallback }, index) => (
            <Slide key={index} direction={prevStep > activeStep ? 'right' : 'left'} in={activeStep === index}>
              <Box>
                <Collapse in={activeStep === index}>
                  <Box>{activeStep === index && elementCallback()}</Box>
                </Collapse>
              </Box>
            </Slide>
          ))}
        </div>

        <Stepper activeStep={activeStep} alternativeLabel={alternativeLabel} connector={<Connector />} sx={{ mt: 4 }}>
          {steps.map(({ title, titleProps, description }) => {
            const isActiveStep = activeStep === steps.findIndex((step) => step.title === title);

            return (
              <Step key={title}>
                <StepLabel
                  StepIconProps={{
                    classes: isActiveStep ? { text: this.props.classes.labelText } : {},
                    sx: (theme) => ({
                      fontSize: 32,
                      color: (isActiveStep ? theme.palette.secondary.main : theme.palette.primary.main) + '!important',
                    }),
                  }}
                >
                  <Typography {...titleProps}>{title}</Typography>
                  {description}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Stack>
    );
  }
}

const RouterWrapped = withRouter<DynamicStepperProps, typeof DynamicStepperComponent>(DynamicStepperComponent);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const DynamicStepper = StyleWrapped;
