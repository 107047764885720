import { EslManagerPublicRouteV1, HttpMethod, LinkProfile, Pagination, PaginationResponse } from '@ekkogmbh/apisdk';
import { Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { LinkProfileStore } from '../Stores/LinkProfileStore';
import { LinkProfileManagementStyles } from '../Styles/LinkProfileManagementStyles';
import { materialDatatableColumnDefinitions } from './LinkProfileDatatableColumnDefinitions';
import { LinkProfilePanel } from './LinkProfilePanel';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

const styles = LinkProfileManagementStyles;

const stores = ['api', 'linkProfileStore', 'paginationStore', 'searchContentStore', 'navigationStore'];

export interface LinkProfileManagementContentActionHandlers {
  edit: (linkProfile: LinkProfile) => void;
  delete: (linkProfile: LinkProfile) => void;
}

export interface LinkProfileManagementContentStores {
  api: ApiStore;
  linkProfileStore: LinkProfileStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

export interface LinkProfileManagementContentState {
  selectedLinkProfile?: LinkProfile;
  isPanelOpen: boolean;
  isDialogOpen: boolean;
}

export interface LinkProfileManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type LinkProfileManagementContentPropsWithStores = LinkProfileManagementContentProps &
  LinkProfileManagementContentStores;

@inject(...stores)
class LinkProfileManagementContentComponent extends Component<
  LinkProfileManagementContentProps,
  LinkProfileManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<LinkProfile> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<LinkProfile> = {
    name: 'LP.name',
  };

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): LinkProfileManagementContentStores {
    return this.props as LinkProfileManagementContentPropsWithStores;
  }

  public state: LinkProfileManagementContentState = {
    isDialogOpen: false,
    isPanelOpen: false,
  };

  public componentWillUnmount() {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchLinkProfiles = async (pagination: Pagination): Promise<PaginationResponse<LinkProfile>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<LinkProfile>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getLinkProfiles(pagination),
      EslManagerPublicRouteV1.LINK_PROFILES,
      HttpMethod.GET,
    );
  };

  public onDelete = async (linkProfile: LinkProfile): Promise<void> => {
    this.setState({
      isDialogOpen: true,
      selectedLinkProfile: linkProfile,
    });
  };

  public onDismiss = () => {
    this.setState({
      selectedLinkProfile: undefined,
      isPanelOpen: false,
      isDialogOpen: false,
    });
  };

  public onDeleteOk = async () => {
    const { selectedLinkProfile } = this.state;
    const { api, searchContentStore } = this.stores;

    if (selectedLinkProfile === undefined) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteLinkProfile(selectedLinkProfile.coordinate, selectedLinkProfile.name),
      EslManagerPublicRouteV1.LINK_PROFILE,
      HttpMethod.DELETE,
      { 200: 'LinkProfile deleted.' },
    );

    this.onDismiss();
    searchContentStore.emitRefresh();
  };

  public onEdit = async (linkProfile: LinkProfile): Promise<void> => {
    const { navigationStore, linkProfileStore } = this.stores;

    linkProfileStore.setEditableLinkProfile(linkProfile);

    this.setState(
      {
        selectedLinkProfile: linkProfile,
        isPanelOpen: true,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public onSave = async (linkProfilePayload: LinkProfile): Promise<LinkProfile> => {
    const { api, navigationStore, searchContentStore, linkProfileStore } = this.stores;
    const { editableLinkProfile } = linkProfileStore;

    const doOverwrite = editableLinkProfile !== undefined;

    const linkProfile = await request<LinkProfile>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveLinkProfile(linkProfilePayload, doOverwrite),
      EslManagerPublicRouteV1.LINK_PROFILE,
      HttpMethod.PUT,
      { 200: 'Link Profile saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return linkProfile;
  };

  public getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen } = this.state;
    if (!isPanelOpen) {
      return;
    }

    return <LinkProfilePanel closeHandler={this.onDismiss} saveHandler={this.onSave} />;
  };

  public render() {
    const { isPanelOpen, isDialogOpen, selectedLinkProfile } = this.state;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as LinkProfileManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={
            <GenericDialog
              type="confirmation"
              maxWidth={'sm'}
              fullWidth={true}
              centered={true}
              open={isDialogOpen}
              title={'Delete Link Profile'}
              text={selectedLinkProfile?.name ?? ''}
              onClose={this.onDismiss}
              onConfirm={this.onDeleteOk}
            />
          }
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              tooltip={'Add Link Profile'}
              requiredPermission={Permissions.LINK_PROFILES_WRITE}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedLinkProfile: undefined })}
            />,
          ]}
        />
        <Paper>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchLinkProfiles}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<LinkProfileManagementContentProps, typeof LinkProfileManagementContentComponent>(
  LinkProfileManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const LinkProfileManagementContent = StyleWrapped;
