import {
  CompartmentSelector,
  CompartmentView,
  EslManagerPublicRouteV1,
  HttpMethod,
  Pagination,
  PaginationResponse,
} from '@ekkogmbh/apisdk';
import { Fade, Paper, Stack, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import MUIDataTable, { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { CompartmentSelectorStore } from '../Stores/CompartmentSelectorStore';
import { CompartmentSelectorManagementStyles } from '../Styles/CompartmentSelectorManagementStyles';
import { materialDatatableColumnDefinitions } from './CompartmentSelectorDatatableColumnDefinitions';
import { CompartmentSelectorPanel } from './CompartmentSelectorPanel';
import React from 'react';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';
import { RunCompartmentSelectorResult } from './RunComartmentSelectorResult';

const styles = CompartmentSelectorManagementStyles;

const stores = ['api', 'compartmentSelectorStore', 'paginationStore', 'searchContentStore', 'navigationStore'];

export interface CompartmentSelectorManagementContentActionHandlers {
  edit: (compartmentSelector: CompartmentSelector) => void;
  delete: (compartmentSelector: CompartmentSelector) => void;
  run: (compartmentSelector: CompartmentSelector) => void;
}

export interface CompartmentSelectorManagementContentStores {
  api: ApiStore;
  compartmentSelectorStore: CompartmentSelectorStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

export interface CompartmentSelectorManagementContentState {
  selectedCompartmentSelector?: CompartmentSelector;
  openDialog?: 'test' | 'delete';
  isPanelOpen: boolean;
  test?: {
    isLoading: boolean;
    isResultShown: boolean;
    compartments?: CompartmentView[];
  };
}

export interface CompartmentSelectorManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type CompartmentSelectorManagementContentPropsWithStores = CompartmentSelectorManagementContentProps &
  CompartmentSelectorManagementContentStores;

@inject(...stores)
class CompartmentSelectorManagementContentComponent extends Component<
  CompartmentSelectorManagementContentProps,
  CompartmentSelectorManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<CompartmentSelector> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<CompartmentSelector> = { name: 'CS.name' };

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): CompartmentSelectorManagementContentStores {
    return this.props as CompartmentSelectorManagementContentPropsWithStores;
  }

  public state: CompartmentSelectorManagementContentState = {
    isPanelOpen: false,
  };

  public componentWillUnmount() {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchCompartmentSelectors = async (
    pagination: Pagination,
  ): Promise<PaginationResponse<CompartmentSelector>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<CompartmentSelector>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getCompartmentSelectors(pagination),
      EslManagerPublicRouteV1.COMPARTMENT_SELECTORS,
      HttpMethod.GET,
    );
  };

  public onDelete = async (compartmentSelector: CompartmentSelector): Promise<void> => {
    this.setState({
      isPanelOpen: false,
      selectedCompartmentSelector: compartmentSelector,
      openDialog: 'delete',
    });
  };

  public onTest = async (compartmentSelector: CompartmentSelector): Promise<void> => {
    this.setState({
      isPanelOpen: false,
      selectedCompartmentSelector: compartmentSelector,
      openDialog: 'test',
      test: undefined,
    });
  };

  public onDeleteConfirm = async () => {
    const { selectedCompartmentSelector } = this.state;
    const { api, searchContentStore } = this.stores;

    if (selectedCompartmentSelector === undefined) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteCompartmentSelector(selectedCompartmentSelector.coordinate, selectedCompartmentSelector.name),
      EslManagerPublicRouteV1.COMPARTMENT_SELECTOR,
      HttpMethod.DELETE,
      { 200: 'Compartment Selector deleted.' },
    );

    this.onDismiss();
    searchContentStore.emitRefresh();
  };

  public renderCompartments = (compartments: CompartmentView[]): React.JSX.Element => (
    <MUIDataTable
      title={''}
      data={compartments.map((c) => ({
        coordinate: c.coordinate,
        links: c.links.map((l) => l.labelId).join(', '),
      }))}
      columns={[
        { label: `Coordinate`, name: 'coordinate' },
        { label: 'Linked Label Ids', name: 'links' },
      ]}
      options={{ responsive: 'standard', selectableRows: 'none' }}
    />
  );

  public runTest = async (value: string): Promise<void> => {
    const { api } = this.stores;
    const { selectedCompartmentSelector } = this.state;

    if (selectedCompartmentSelector === undefined) {
      return;
    }

    this.setState({ test: { isLoading: true, isResultShown: false } });

    const compartments = await request<CompartmentView[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.runCompartmentSelector(selectedCompartmentSelector.coordinate, selectedCompartmentSelector.name, value),
      EslManagerPublicRouteV1.COMPARTMENT_SELECTOR,
      HttpMethod.POST,
    );

    this.setState({
      test: {
        compartments,
        isLoading: false,
        isResultShown: false,
      },
    });
  };

  public onEdit = async (compartmentSelector: CompartmentSelector): Promise<void> => {
    const { navigationStore, compartmentSelectorStore } = this.stores;

    compartmentSelectorStore.setEditableCompartmentSelector(compartmentSelector);

    this.setState(
      {
        isPanelOpen: true,
        selectedCompartmentSelector: compartmentSelector,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public onSave = async (compartmentSelector: CompartmentSelector): Promise<CompartmentSelector> => {
    const { api, navigationStore, searchContentStore, compartmentSelectorStore } = this.stores;
    const { editableCompartmentSelector } = compartmentSelectorStore;

    const doOverwrite = editableCompartmentSelector !== undefined;

    const responseSelector = await request<CompartmentSelector>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveCompartmentSelector(compartmentSelector, doOverwrite),
      EslManagerPublicRouteV1.COMPARTMENT_SELECTOR,
      HttpMethod.PUT,
      { 200: 'Compartment Selector saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return responseSelector;
  };

  public onDismiss = () => {
    this.setState({
      isPanelOpen: false,
      selectedCompartmentSelector: undefined,
      openDialog: undefined,
      test: undefined,
    });
  };

  public getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return <CompartmentSelectorPanel closeHandler={this.onDismiss} saveHandler={this.onSave} />;
  };

  public getOpenDialog = (): JSX.Element | undefined => {
    const { openDialog, selectedCompartmentSelector, test } = this.state;

    if (selectedCompartmentSelector?.name === undefined) {
      return;
    }

    switch (openDialog) {
      case 'delete':
        return (
          <GenericDialog
            open
            fullWidth
            centered
            type="confirmation"
            maxWidth={'sm'}
            title={'Delete Compartment Selector'}
            text={selectedCompartmentSelector.name}
            onClose={this.onDismiss}
            onConfirm={this.onDeleteConfirm}
          />
        );
      case 'test': {
        const content =
          test?.compartments !== undefined ? (
            <Stack direction={'column'} spacing={2}>
              <RunCompartmentSelectorResult
                compartments={test.compartments}
                showResultsHandler={() => this.setState({ test: { ...test, isResultShown: true } })}
              />
              {test.isResultShown && (
                <Fade in unmountOnExit>
                  <div>{this.renderCompartments(test.compartments)}</div>
                </Fade>
              )}
            </Stack>
          ) : (
            <Typography>{selectedCompartmentSelector.name}</Typography>
          );
        return (
          <GenericDialog
            open
            fullWidth
            centered
            type="input"
            maxWidth={'sm'}
            title={'Test Compartment Selector'}
            text={content}
            onClose={this.onDismiss}
            onSubmit={this.runTest}
            buttonText={{ cancel: 'Close', ok: 'Search' }}
            checkmarkSpinner={test?.isLoading}
          />
        );
      }
      default:
        return;
    }
  };

  public render() {
    const { isPanelOpen } = this.state;
    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as CompartmentSelectorManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
        run: this.onTest,
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={this.getOpenDialog()}
          buttons={[
            <ContentControlButton
              key={'add'}
              tooltip={'Add Compartment Selector'}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedCompartmentSelector: undefined })}
              content={<Add />}
              requiredPermission={Permissions.COMPARTMENT_SELECTORS_WRITE}
            />,
          ]}
        />
        <Paper>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchCompartmentSelectors}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'asc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<
  CompartmentSelectorManagementContentProps,
  typeof CompartmentSelectorManagementContentComponent
>(CompartmentSelectorManagementContentComponent);

const StyleWrapped = withStyles(styles)(RouterWrapped);

export const CompartmentSelectorManagementContent = StyleWrapped;
