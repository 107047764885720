import { EslManagerPublicRouteV1, HttpMethod, Pagination, PaginationResponse, SupplyProfile } from '@ekkogmbh/apisdk';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { SupplyProfileStore } from '../Stores/SupplyProfileStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject } from 'mobx-react';
import { Component } from 'react';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { request } from 'src/Common/Helper/FetchHandler';
import { enqueueSnackbar } from 'notistack';
import { WithStyles, withStyles } from '@mui/styles';
import { SupplyProfileManagementStyles } from '../Styles/SupplyProfileManagementStyles';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { materialDatatableColumnDefinitions } from './SupplyProfileDatatableColumnDefinitions';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { Paper } from '@mui/material';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import classNames from 'classnames';
import { SupplyProfilePanel } from './SupplyProfilePanel';
import { Add } from '@mui/icons-material';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

export interface SupplyProfileManagementContentActionHandlers {
  edit: (supplyProfile: SupplyProfile) => void;
  delete: (SupplyProfile: SupplyProfile) => void;
}

export interface SupplyProfileManagementContentState {
  selectedProfile?: SupplyProfile;
  isPanelOpen: boolean;
  isDialogOpen: boolean;
}

interface SupplyProfileManagementContentStores {
  api: ApiStore;
  supplyProfileStore: SupplyProfileStore;
  navigationStore: NavigationStore;
  searchContentStore: SearchContentStore;
}

interface SupplyProfileManagementContentProps
  extends WithStyles<typeof SupplyProfileManagementStyles>,
    RouteComponentProps {}

export type SupplyProfileManagementContentPropsWithStores = SupplyProfileManagementContentProps &
  SupplyProfileManagementContentStores;

@inject('api', 'supplyProfileStore', 'navigationStore', 'searchContentStore')
class SupplyProfileManagementContentComponent extends Component<
  SupplyProfileManagementContentProps,
  SupplyProfileManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<SupplyProfile> = ['name', 'coordinate'];
  private readonly sortFieldMaps: DataTableSortFieldMap<SupplyProfile> = { name: 'entry.name' };

  private fetchPromises: CancelableFetchPromises = {};

  public state: SupplyProfileManagementContentState = {
    isPanelOpen: false,
    isDialogOpen: false,
  };

  get stores(): SupplyProfileManagementContentStores {
    return this.props as SupplyProfileManagementContentProps & SupplyProfileManagementContentStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetch = async (pagination: Pagination): Promise<PaginationResponse<SupplyProfile>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<SupplyProfile>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getSupplyProfiles(pagination),
      EslManagerPublicRouteV1.SUPPLY_PROFILES,
      HttpMethod.GET,
    );
  };

  public onDismiss = () => {
    this.setState({
      isDialogOpen: false,
      isPanelOpen: false,
      selectedProfile: undefined,
    });
  };

  public onEdit = async (supplyProfile: SupplyProfile): Promise<void> => {
    const { navigationStore, supplyProfileStore } = this.stores;

    supplyProfileStore.resetStore(supplyProfile);

    this.setState(
      {
        selectedProfile: supplyProfile,
        isPanelOpen: true,
        isDialogOpen: false,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public onSave = async (): Promise<void> => {
    const { api, navigationStore, searchContentStore, supplyProfileStore } = this.stores;
    const { name, coordinate } = supplyProfileStore.state;

    await request<SupplyProfile>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveSupplyProfile(
        { name, coordinate },
        supplyProfileStore.getPayload(),
        supplyProfileStore.editableSupplyProfile !== undefined,
      ),
      EslManagerPublicRouteV1.SUPPLY_PROFILE,
      HttpMethod.PUT,
      { 200: 'Supply Profile saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();
  };

  public onDelete = async (supplyProfile: SupplyProfile): Promise<void> => {
    this.setState({ selectedProfile: supplyProfile, isDialogOpen: true, isPanelOpen: false });
  };

  public onDeleteConfirm = async () => {
    const { selectedProfile } = this.state;
    const { api, searchContentStore } = this.stores;

    if (selectedProfile === undefined) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteSupplyProfile(selectedProfile),
      EslManagerPublicRouteV1.SUPPLY_PROFILE,
      HttpMethod.DELETE,
      { 200: 'Supply Profile deleted.' },
    );

    this.onDismiss();
    searchContentStore.emitRefresh();
  };

  public getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return <SupplyProfilePanel saveHandler={this.onSave} closeHandler={this.onDismiss} />;
  };

  public render() {
    const { classes } = this.props;
    const { selectedProfile, isPanelOpen, isDialogOpen } = this.state;
    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as SupplyProfileManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={
            <GenericDialog
              title={'Delete Supply Profile'}
              text={selectedProfile?.name ?? ''}
              type={'confirmation'}
              maxWidth={'sm'}
              onClose={this.onDismiss}
              onConfirm={this.onDeleteConfirm}
              fullWidth
              centered
              open={isDialogOpen}
            />
          }
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              tooltip={'Add Supply Profile'}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedProfile: undefined })}
              requiredPermission={Permissions.EKANBAN_WRITE}
            />,
          ]}
        />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetch}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMaps}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<SupplyProfileManagementContentProps, typeof SupplyProfileManagementContentComponent>(
  SupplyProfileManagementContentComponent,
);
const StyleWrapped = withStyles(SupplyProfileManagementStyles)(RouterWrapped);

export const SupplyProfileManagementContent = StyleWrapped;
