import { Button, Stack, Tab, Tabs, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React, { ReactText } from 'react';
import { Component } from 'react';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { ContentPanel } from 'src/Common/Components/ContentControl/ContentPanel';
import { DynamicStepper, DynamicStepperStep } from 'src/Common/Components/Stepper/DynamicStepper';
import { CSVDropzone } from 'src/Common/Components/CSVDropzone';
import { UploadStep } from 'src/Common/Components/CSVImportStepper/UploadStep';
import { CsvDropzoneStore } from 'src/Common/Stores/CsvDropzoneStore';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { ConfigStore } from 'src/Common/Stores/ConfigStore';
import { inject, observer } from 'mobx-react';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { enqueueSnackbar } from 'notistack';
import { TaskCollectionProgressCallback, TaskCollectionStore } from 'src/Common/Stores/TaskCollectionStore';
import { EslManagerPrivateRoute, HttpMethod, ImportCompartmentsDiff } from '@ekkogmbh/apisdk';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';
import { GenericDialog } from 'src/Common/Components/GenericDialog';

const styles = FormStyles;

interface ImportPanelState {
  currentTab: number;
  currentStep: number;
  loading: boolean;
  disableDiffModalOverride: boolean;
  isDialogOpen: boolean;
  compartmentUpdateCount?: number;
}

interface ImportPanelTabDefinition {
  description: string;
  icon: React.ComponentType<SvgIconProps>;
  disableDiffModalOverride?: boolean;
}

interface ImportPanelProps extends WithStyles<typeof styles> {
  tabs: ImportPanelTabDefinition[];
  closeHandler: () => void;
  importFinishedHandler: () => void;
}

interface ImportPanelStores {
  api: ApiStore;
  csvDropzoneStore: CsvDropzoneStore;
  configStore: ConfigStore;
  taskCollectionStore: TaskCollectionStore;
}

@inject('api', 'csvDropzoneStore', 'configStore', 'taskCollectionStore')
@observer
class ImportPanelComponent extends Component<ImportPanelProps, ImportPanelState> {
  public state: ImportPanelState = {
    loading: false,
    currentTab: 0,
    currentStep: 0,
    disableDiffModalOverride: false,
    isDialogOpen: false,
  };

  get stores(): ImportPanelStores {
    return this.props as ImportPanelProps & ImportPanelStores;
  }

  private fetchPromises: CancelableFetchPromises = {};

  public async componentDidMount(): Promise<void> {
    this.setState({ loading: false });
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public handleTabChange = (_: React.SyntheticEvent, value: number): void => {
    const { tabs } = this.props;
    const disableDiffModalOverride = tabs[value].disableDiffModalOverride;
    this.setState({
      currentTab: value,
      disableDiffModalOverride: disableDiffModalOverride === undefined ? false : disableDiffModalOverride,
    });
  };

  public onShowDiffDialog = async () => {
    const { api, csvDropzoneStore } = this.stores;

    if (csvDropzoneStore.file === undefined) {
      return;
    }

    this.setState({ loading: true });

    try {
      const importCompartmentsDiff = await request<ImportCompartmentsDiff>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.importCompartmentsDiff(csvDropzoneStore.file),
        EslManagerPrivateRoute.IMPORTER_COMPARTMENTS_DIFF,
        HttpMethod.POST,
      );

      const compartmentUpdateCount = importCompartmentsDiff.count;

      this.setState({ compartmentUpdateCount, loading: false, isDialogOpen: true });
    } catch (e) {
      this.setState({
        isDialogOpen: false,
        compartmentUpdateCount: undefined,
        loading: false,
      });
    }
  };

  public onImport = async () => {
    const { disableDiffModalOverride } = this.state;
    const { configStore } = this.stores;
    const importDiffModal = configStore.config?.importDiffModal;

    if (!disableDiffModalOverride && importDiffModal !== false && importDiffModal !== 0) {
      await this.onShowDiffDialog();
    } else {
      await this.doImport();
    }
  };

  public doImport = async () => {
    const { importFinishedHandler } = this.props;
    const { currentTab } = this.state;
    const { api, csvDropzoneStore, taskCollectionStore } = this.stores;

    if (csvDropzoneStore.file === undefined) {
      return;
    }

    this.setState({
      loading: true,
      isDialogOpen: false,
    });

    const progressHandler = (uri: string) => {
      const callback: TaskCollectionProgressCallback = (id, __, progress) => {
        if (progress === 100 || id === '') {
          // this should be tasks created handler?
          importFinishedHandler();
        }
      };
      taskCollectionStore.processTaskCollection(uri, callback);
    };

    try {
      const importPromise =
        currentTab === 0
          ? request<void>( // compartments
              api,
              enqueueSnackbar,
              this.fetchPromises,
              api.importCompartments(csvDropzoneStore.file, (tasksUri) =>
                taskCollectionStore.processTaskCollection(tasksUri, progressHandler),
              ),
              EslManagerPrivateRoute.IMPORTER_COMPARTMENTS,
              HttpMethod.POST,
            )
          : request<void>( // links
              api,
              enqueueSnackbar,
              this.fetchPromises,
              api.importLinks(csvDropzoneStore.file, (tasksUri) =>
                taskCollectionStore.processTaskCollection(tasksUri, progressHandler),
              ),
              EslManagerPrivateRoute.IMPORTER_LINKS,
              HttpMethod.POST,
            );

      await importPromise;
      this.setState({ loading: false }, this.goToNextStep);
    } catch (e) {
      this.setState({ currentStep: 0 });
    }
  };

  public closeHandler = () => {
    this.setState({ currentTab: 0 });

    if (this.props.closeHandler !== undefined) {
      this.props.closeHandler();
    }
  };

  public makeTabs(): JSX.Element {
    const { tabs } = this.props;
    const { currentTab } = this.state;
    return (
      <Tabs value={currentTab} onChange={this.handleTabChange} indicatorColor="primary" textColor="secondary" centered>
        {tabs.map((tabDef: ImportPanelTabDefinition, index: number) => {
          const { description } = tabDef;

          return (
            <Tab
              key={index}
              label={
                <div
                  style={{
                    display: 'inline-flex',
                    justifyContent: 'center',
                    lineHeight: '42px',
                  }}
                >
                  <tabDef.icon style={{ margin: 8 }} />
                  {description}
                </div>
              }
            />
          );
        })}
      </Tabs>
    );
  }

  private goToNextStep = () => {
    const { currentStep } = this.state;
    this.setState({ currentStep: currentStep + 1 });
  };

  public makeSteps(): DynamicStepperStep[] {
    const { classes } = this.props;
    const { loading, currentTab } = this.state;
    return [
      {
        title: 'File-Selection',
        elementCallback: () => (
          <Stack direction={'column'} spacing={2} justifyContent={'flex-start'} alignItems={'center'}>
            {this.makeTabs()}
            <CSVDropzone onDrop={this.goToNextStep} />
          </Stack>
        ),
      },
      {
        title: 'Upload / Import',
        elementCallback: () => (
          <Stack spacing={2} direction={'column'}>
            <StyledFormHeader label={(currentTab === 0 ? 'compartment' : 'link') + ' import'} />
            <UploadStep loading={loading} />
            {currentTab === 0 && (
              <Button className={classes.button} onClick={this.onShowDiffDialog}>
                Calculate amount of changed compartments
              </Button>
            )}
          </Stack>
        ),
      },
      {
        title: 'Complete',
        elementCallback: () => (
          <Stack spacing={1} direction={'column'} justifyContent={'flex-start'}>
            <StyledFormHeader label={(currentTab === 0 ? 'compartment' : 'link') + ' import'} />
            <Typography textAlign={'center'}>Import successfully applied</Typography>
            <Typography textAlign={'center'}>
              The content of linked devices will be updated in the background
            </Typography>
          </Stack>
        ),
      },
    ];
  }

  public onReset = () => {
    const { csvDropzoneStore } = this.stores;
    this.setState(
      {
        loading: false,
        currentStep: 0,
        currentTab: 0,
      },
      csvDropzoneStore.reset,
    );
  };

  public getImportDiffDialog = (): JSX.Element | undefined => {
    const { configStore } = this.stores;
    const { isDialogOpen, compartmentUpdateCount } = this.state;

    if (!isDialogOpen || compartmentUpdateCount === undefined) {
      return undefined;
    }

    const compartmentsString = compartmentUpdateCount === 1 ? 'Compartment' : 'Compartments';
    const zeroDiff = compartmentUpdateCount === 0 ? 'not ' : undefined;
    const actualCount = compartmentUpdateCount === 0 ? 'any' : compartmentUpdateCount;

    const boldSpanFn = (text?: ReactText) => (
      <span
        style={{
          fontSize: 24,
          fontWeight: 700,
          verticalAlign: 'middle',
        }}
      >
        {text}
      </span>
    );

    const importDiffModal = configStore.config?.importDiffModal;
    const autoConfirmTime = !!importDiffModal ? Number(importDiffModal) : 0;

    const autoConfirmTextFn = (autoConfirmSecondsLeft: number) => (
      <React.Fragment>
        The import starts automatically in <span style={{ fontWeight: 700 }}>{autoConfirmSecondsLeft}</span> seconds.
      </React.Fragment>
    );

    return (
      <GenericDialog
        open
        fullWidth
        centered
        type="confirmation"
        maxWidth={'sm'}
        title={'Import Compartments'}
        text={
          <div>
            This import will {boldSpanFn(zeroDiff)}insert/update {boldSpanFn(actualCount)} {compartmentsString}.
          </div>
        }
        onClose={() => this.setState({ isDialogOpen: false, compartmentUpdateCount: undefined })}
        onConfirm={this.doImport}
        autoConfirm={autoConfirmTime > 0}
        autoConfirmTime={autoConfirmTime > 0 ? autoConfirmTime : undefined}
        autoConfirmTextFn={autoConfirmTime > 0 ? autoConfirmTextFn : undefined}
        buttonText={{ ok: 'import', cancel: 'close' }}
      />
    );
  };

  public render() {
    const { currentStep } = this.state;

    return (
      <>
        {this.getImportDiffDialog()}
        <ContentPanel
          content={<DynamicStepper steps={this.makeSteps()} activeStep={currentStep} />}
          panelButtonProps={{
            finished: currentStep === 2,
            cancelHandler: this.closeHandler,
            resetHandler: this.onReset,
            saveHandler: this.onImport,
            isSaveDisabled: currentStep !== 1,
            isDeleteHidden: true,
            saveButtonLabel: 'Import',
          }}
        />
      </>
    );
  }
}

const StyleWrapped = withStyles(styles)(ImportPanelComponent);

export const ImportPanel = StyleWrapped;
