import {
  EslManagerPrivateRoute,
  HttpMethod,
  Operation,
  OperationGroup,
  Pagination,
  PaginationResponse,
  Trigger,
} from '@ekkogmbh/apisdk';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { KeyboardArrowLeft } from '@mui/icons-material';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from '../../Common/Helper/PromiseHelper';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { ContentTitleStore } from '../../Common/Stores/ContentTitleStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { OperationsStyles } from '../Styles/OperationsStyles';
import { materialDatatableColumnDefinitions as OperationColumnDefinitions } from './OperationDatatableColumnDefinitions';
import { TriggerCard } from './TriggerCard';

const styles = OperationsStyles;

const stores = ['api', 'paginationStore', 'searchContentStore', 'navigationStore', 'contentTitleStore'];

export type OperationGroupDetailsContentPropsWithStores = OperationGroupDetailsContentProps &
  OperationGroupDetailsContentStores;

export interface OperationGroupDetailsContentStores {
  api: ApiStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  contentTitleStore: ContentTitleStore;
}

export interface OperationGroupDetailsContentState {
  deleteDialogOpen: boolean;
  loading: boolean;
  triggers: Trigger[];
}

interface OperationGroupDetailsContentParams {
  operationgroupid: string;
}

export interface OperationGroupDetailsContentProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<OperationGroupDetailsContentParams> {}

@inject(...stores)
class OperationGroupDetailsContentComponent extends Component<
  OperationGroupDetailsContentProps,
  OperationGroupDetailsContentState
> {
  public state: OperationGroupDetailsContentState = {
    deleteDialogOpen: false,
    loading: true,
    triggers: [],
  };
  private readonly filterFields: DataTableFilterFields<Operation> = ['identifier', 'type'];
  private readonly sortFieldMap: DataTableSortFieldMap<Operation> = {
    identifier: 'O.identifier',
    type: 'O.type',
  };
  private fetchPromises: CancelableFetchPromises = {};
  private operationGroupPromise?: Promise<OperationGroup>;

  get stores(): OperationGroupDetailsContentStores {
    return this.props as OperationGroupDetailsContentPropsWithStores;
  }

  public componentDidMount(): void {
    const operationGroupId = parseInt(this.props.match.params.operationgroupid, 10);

    this.operationGroupPromise = this.fetchOperationGroup(operationGroupId);

    this.operationGroupPromise.then(async () => {
      const { contentTitleStore } = this.stores;
      const operationGroup = await (this.operationGroupPromise as Promise<OperationGroup>);
      contentTitleStore.setContentTitle(this.renderOperationGroupNameContentTitle(operationGroup.name));

      await this.initTriggers();
    });
  }

  public componentWillUnmount(): void {
    const { contentTitleStore } = this.stores;

    cancelFetchPromises(this.fetchPromises);

    contentTitleStore.setContentTitle(undefined);
  }

  public renderOperationGroupNameContentTitle = (name: string) => (
    <span>
      :&nbsp;
      <span key={'title-name'} style={{ fontWeight: 'bold' }}>
        {name}
      </span>
    </span>
  );

  public fetchOperationGroup = async (id: number): Promise<OperationGroup> => {
    const { api } = this.stores;

    return await request<OperationGroup>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getOperationGroup(id),
      EslManagerPrivateRoute.OPERATION_GROUPS,
      HttpMethod.GET,
    );
  };

  public fetchOperations = async (pagination: Pagination): Promise<PaginationResponse<Operation>> => {
    const { api } = this.stores;

    const operationGroupId = parseInt(this.props.match.params.operationgroupid, 10);

    return await request<PaginationResponse<Operation>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getOperations({ id: operationGroupId } as OperationGroup, pagination),
      EslManagerPrivateRoute.OPERATIONS,
      HttpMethod.GET,
    );
  };

  public initTriggers = async (): Promise<void> => {
    const { operationGroupPromise } = this;

    const operationGroup = await (operationGroupPromise as Promise<OperationGroup>);
    const triggers = operationGroup.triggers;

    this.setState({
      loading: false,
      triggers,
    });
  };

  public goBack = () => {
    const { history } = this.props;

    history.replace('/operationgroups');
  };

  public render() {
    const { classes } = this.props;
    const { loading, triggers } = this.state;
    const { fetchOperations } = this;

    const operationColumnDefinition: MUIDataTableColumnDef[] = OperationColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as OperationGroupDetailsContentPropsWithStores, null),
    );

    return (
      <Stack direction={'column'} spacing={4}>
        <Paper
          className={classNames(classes.root)}
          style={{
            position: 'relative',
            minHeight: 120,
          }}
        >
          {loading && <LoadingMask width={75} height={75} />}
          <Stack direction={'column'} spacing={0} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Stack direction={'row'}>
              <Button
                size={'small'}
                style={{
                  borderRadius: 0,
                }}
                variant="text"
                color="secondary"
                onClick={this.goBack}
              >
                <KeyboardArrowLeft /> GO Back
              </Button>
              <Typography color={'primary'}>{'|'}</Typography>
              <Typography align={'center'} variant={'overline'} style={{ marginLeft: 8 }}>
                Triggers
              </Typography>
            </Stack>
            <Grid container spacing={2} alignItems={'stretch'} padding={2}>
              {triggers.map((trigger: Trigger, index: number) => (
                <Grid item lg={3} md={6} xs={12} key={index}>
                  <TriggerCard trigger={trigger} />
                </Grid>
              ))}
            </Grid>
          </Stack>
        </Paper>

        <Paper className={classNames(classes.root)}>
          <Typography align={'center'} variant={'overline'} style={{ marginLeft: 8 }}>
            Operations
          </Typography>
          <DataTable
            fetchItems={fetchOperations}
            columns={operationColumnDefinition}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: OperationColumnDefinitions[0].name, direction: 'asc' },
            }}
          />
        </Paper>
      </Stack>
    );
  }
}

const RouterWrapped = withRouter<OperationGroupDetailsContentProps, typeof OperationGroupDetailsContentComponent>(
  OperationGroupDetailsContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const OperationGroupDetailsContent = StyleWrapped;
