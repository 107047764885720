import {
  EslManagerPrivateRoute,
  HttpMethod,
  Pagination,
  PaginationResponse,
  PickingArea,
  PickingAreaCreatePayload,
  PickingAreaUpdatePayload,
} from '@ekkogmbh/apisdk';
import { Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from '../../Common/Helper/PromiseHelper';
import { SuccessHandlerStatusMessages } from '../../Common/Helper/ResponseHandler';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { PickingAreaStore } from '../Stores/PickingAreaStore';
import { PickingStyles } from '../Styles/PickingStyles';
import { materialDatatableColumnDefinitions } from './PickingAreaDatatableColumnDefinitions';
import { PickingAreaPanel } from './PickingAreaPanel';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

const styles = PickingStyles;

const stores = ['api', 'paginationStore', 'searchContentStore', 'navigationStore', 'pickingAreaStore'];

export interface PickingAreasContentActionHandlers {
  edit: (pickingArea: PickingArea) => void;
  delete: (pickingArea: PickingArea) => void;
}

export type PickingAreasContentPropsWithStores = PickingAreasContentProps & PickingAreasContentStores;

export interface PickingAreasContentStores {
  api: ApiStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  pickingAreaStore: PickingAreaStore;
}

export interface PickingAreasContentState {
  selectedPickingArea?: PickingArea;
  isDialogOpen: boolean;
  isPanelOpen: boolean;
}

export interface PickingAreasContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

@inject(...stores)
class PickingAreasContentComponent extends Component<PickingAreasContentProps, PickingAreasContentState> {
  public state: PickingAreasContentState = {
    isDialogOpen: false,
    isPanelOpen: false,
  };
  private readonly filterFields: DataTableFilterFields<PickingArea> = ['id', 'type', 'requiredSide', 'optionalSide'];
  private readonly sortFieldMap: DataTableSortFieldMap<PickingArea> = {
    id: 'PA.externalIdentifier',
    type: 'PA.type',
    requiredSide: 'RS.name',
    optionalSide: 'OS.name',
  };
  private fetchPromises: CancelableFetchPromises = {};
  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    200: 'Picking-Area updated.',
    201: 'Picking-Area created.',
    204: 'Picking-Area deleted.',
  };

  get stores(): PickingAreasContentStores {
    return this.props as PickingAreasContentPropsWithStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public addPickingArea = async (pickingArea: PickingAreaCreatePayload): Promise<PickingArea> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<PickingArea>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.addPickingArea(pickingArea),
      EslManagerPrivateRoute.PICKING_AREAS,
      HttpMethod.POST,
      this.successStatusCodes,
      successCallback,
    );
  };

  public updatePickingArea = async (pickingArea: PickingAreaUpdatePayload): Promise<PickingArea> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<PickingArea>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.updatePickingArea(pickingArea),
      EslManagerPrivateRoute.PICKING_AREA,
      HttpMethod.POST,
      this.successStatusCodes,
      successCallback,
    );
  };

  public deletePickingArea = async (pickingArea: PickingArea): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deletePickingArea(pickingArea),
      EslManagerPrivateRoute.PICKING_AREA,
      HttpMethod.DELETE,
      this.successStatusCodes,
      successCallback,
    );
  };

  public fetchAreas = async (pagination: Pagination): Promise<PaginationResponse<PickingArea>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<PickingArea>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getPickingAreas(pagination),
      EslManagerPrivateRoute.PICKING_AREAS,
      HttpMethod.GET,
    );
  };

  public onDismiss = () => {
    this.setState({
      isPanelOpen: false,
      isDialogOpen: false,
      selectedPickingArea: undefined,
    });
  };

  public getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen, selectedPickingArea } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return (
      <PickingAreaPanel
        saveHandler={this.addPickingArea}
        updateHandler={this.updatePickingArea}
        closeHandler={this.onDismiss}
        closeCallback={this.onDismiss}
        pickingArea={selectedPickingArea}
      />
    );
  };

  public openEditPanel = async (pickingArea: PickingArea): Promise<void> => {
    const { navigationStore } = this.stores;
    this.setState(
      {
        isPanelOpen: true,
        selectedPickingArea: pickingArea,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public openDeleteDialog = async (pickingArea: PickingArea): Promise<void> => {
    this.setState({
      isDialogOpen: true,
      isPanelOpen: false,
      selectedPickingArea: pickingArea,
    });
  };

  public onDeleteOk = async () => {
    const { selectedPickingArea } = this.state;

    if (selectedPickingArea?.id === undefined) {
      return;
    }

    await this.deletePickingArea(selectedPickingArea);
    this.onDismiss();
  };

  public render() {
    const { isPanelOpen, isDialogOpen, selectedPickingArea } = this.state;
    const { classes } = this.props;
    const { fetchAreas } = this;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as PickingAreasContentPropsWithStores, {
        delete: this.openDeleteDialog,
        edit: this.openEditPanel,
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={
            <GenericDialog
              type="confirmation"
              maxWidth={'sm'}
              fullWidth={true}
              centered={true}
              open={isDialogOpen}
              title={'Delete Picking-Area'}
              text={
                <>
                  <div>
                    Delete Picking-Area: <span className={classes.boldFont}>{selectedPickingArea?.id ?? ''}</span>
                  </div>
                  <div>
                    with Required Side:{' '}
                    <span className={classes.boldFont}>{selectedPickingArea?.requiredSide.name ?? ''}</span>?
                  </div>
                </>
              }
              onClose={this.onDismiss}
              onConfirm={this.onDeleteOk}
            />
          }
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedPickingArea: undefined })}
              tooltip={'Add Picking-Area'}
              requiredPermission={Permissions.PICKING_AREAS_WRITE}
            />,
          ]}
        />
        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={fetchAreas}
            columns={columnDefinition}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<PickingAreasContentProps, typeof PickingAreasContentComponent>(
  PickingAreasContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const PickingAreasContent = StyleWrapped;
