import {
  CompartmentSelectorIndex,
  EslManagerPublicRouteV1,
  Finder,
  HttpMethod,
  Pagination,
  PaginationResponse,
  SaveFinderPayload,
} from '@ekkogmbh/apisdk';
import { Paper } from '@mui/material';
import { Add } from '@mui/icons-material';
import { withStyles, WithStyles } from '@mui/styles';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { FinderStore } from '../Stores/FinderStore';
import { FinderManagementStyles } from '../Styles/FinderStyles';
import { materialDatatableColumnDefinitions } from './FinderDatatableColumnDefinitions';
import { FinderPanel } from './FinderPanel';
import { NotificationStrategyStore } from 'src/ZoneManagement/Stores/NotificationStrategyStore';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

const styles = FinderManagementStyles;

const stores = [
  'api',
  'finderStore',
  'notificationStrategyStore',
  'paginationStore',
  'searchContentStore',
  'navigationStore',
];

export interface FinderManagementContentActionHandlers {
  edit: (finder: Finder) => void;
  delete: (finder: Finder) => void;
}

export interface FinderManagementContentStores {
  api: ApiStore;
  finderStore: FinderStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  notificationStrategyStore: NotificationStrategyStore;
}

export interface FinderManagementContentState {
  selectedFinder?: Finder;
  isPanelOpen: boolean;
  isDialogOpen: boolean;
}

export interface FinderManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type FinderManagementContentPropsWithStores = FinderManagementContentProps & FinderManagementContentStores;

@inject(...stores)
class FinderManagementContentComponent extends Component<FinderManagementContentProps, FinderManagementContentState> {
  private readonly filterFields: DataTableFilterFields<Finder> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<Finder> = { name: 'F.name' };

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): FinderManagementContentStores {
    return this.props as FinderManagementContentPropsWithStores;
  }

  public state: FinderManagementContentState = {
    isPanelOpen: false,
    isDialogOpen: false,
  };

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchFinders = async (pagination: Pagination): Promise<PaginationResponse<Finder>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<Finder>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getFinders(pagination),
      EslManagerPublicRouteV1.FINDERS,
      HttpMethod.GET,
    );
  };

  public onDelete = async (finder: Finder): Promise<void> => {
    this.setState({ selectedFinder: finder, isDialogOpen: true });
  };

  public onDismiss = async () => {
    this.setState({
      selectedFinder: undefined,
      isPanelOpen: false,
      isDialogOpen: false,
    });
  };

  public onDeleteConfirm = async () => {
    const { selectedFinder } = this.state;
    const { api, searchContentStore } = this.stores;

    if (selectedFinder === undefined) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteFinder(selectedFinder.coordinate, selectedFinder.name),
      EslManagerPublicRouteV1.FINDER,
      HttpMethod.DELETE,
      { 200: 'Finder deleted.' },
    );

    this.onDismiss();
    searchContentStore.emitRefresh();
  };

  public onEdit = async (finder: Finder): Promise<void> => {
    const { navigationStore, finderStore } = this.stores;

    finderStore.setEditableFinder(finder);

    this.setState(
      {
        selectedFinder: finder,
        isPanelOpen: true,
        isDialogOpen: false,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public onSave = async (finder: Finder): Promise<Finder> => {
    const { api, navigationStore, searchContentStore, finderStore, notificationStrategyStore } = this.stores;
    const { editableFinder } = finderStore;

    const doOverwrite = editableFinder !== undefined;

    const payload: SaveFinderPayload = {
      notificationStrategy: notificationStrategyStore.getSavableNotificationStrategy(),
      templateNames: finder.templateNames ?? [],
      compartmentInputInstructions:
        finder.compartmentInputInstructions !== '' ? finder.compartmentInputInstructions : null,
      showResultsDuration: finder.showResultsDuration,
    };

    if (finder.compartmentSelector) {
      const { name, coordinate } = finder.compartmentSelector;
      payload.compartmentSelector = { name, coordinate } as CompartmentSelectorIndex;
    }

    const responseFinder = await request<Finder>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveFinder(finder.coordinate, finder.name, payload, doOverwrite),
      EslManagerPublicRouteV1.FINDER,
      HttpMethod.PUT,
      { 200: 'Finder saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return responseFinder;
  };

  public getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return <FinderPanel closeHandler={this.onDismiss} saveHandler={this.onSave} />;
  };

  public render() {
    const { selectedFinder, isPanelOpen, isDialogOpen } = this.state;
    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as FinderManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={
            <GenericDialog
              type="confirmation"
              maxWidth={'sm'}
              fullWidth={true}
              centered={true}
              open={isDialogOpen}
              title={'Delete Finder'}
              text={selectedFinder?.name ?? ''}
              onClose={this.onDismiss}
              onConfirm={this.onDeleteConfirm}
            />
          }
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              tooltip={'Add Finder'}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedFinder: undefined })}
              requiredPermission={Permissions.FINDERS_WRITE}
            />,
          ]}
        />
        <Paper>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchFinders}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<FinderManagementContentProps, typeof FinderManagementContentComponent>(
  FinderManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const FinderManagementContent = StyleWrapped;
