import {
  EslManagerPrivateRoute,
  HttpMethod,
  PaginationResponse,
  Role,
  User,
  UserRoleNodeMapping,
  UserRoleNodeMappingPayload,
} from '@ekkogmbh/apisdk';
import { Button, Grid, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add, KeyboardArrowLeft } from '@mui/icons-material';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import moment, { Moment } from 'moment';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { DataTable, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { request } from '../../Common/Helper/FetchHandler';
import { injectFakePagination } from '../../Common/Helper/Pagination';
import { CancelableFetchPromises, cancelFetchPromises } from '../../Common/Helper/PromiseHelper';
import { SuccessHandlerStatusMessages } from '../../Common/Helper/ResponseHandler';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { ContentTitleStore } from '../../Common/Stores/ContentTitleStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { UserManagementStyles } from '../Styles/UserManagementStyles';
import { materialDatatableColumnDefinitions } from './UserMappingDatatableColumnDefinitions';
import { UserMappingPanel } from './UserMappingPanel';
import { DatePicker } from '@mui/x-date-pickers';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

interface UserMappingsContentStores {
  api: ApiStore;
  contentTitleStore: ContentTitleStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

const stores = ['api', 'contentTitleStore', 'paginationStore', 'searchContentStore', 'navigationStore'];

export interface UserMappingsContentActionHandlers {
  delete: (mapping: UserRoleNodeMapping) => void;
}

export interface UserMappingsContentHelpers {
  onClickValidUntil: (mapping: UserRoleNodeMapping) => void;
}

interface UserMappingsContentParams {
  entityType: 'user' | 'role';
  entityId: string;
}

export interface UserMappingsContentState {
  selectedMapping?: UserRoleNodeMapping | Partial<UserRoleNodeMapping>;
  isPanelOpen: boolean;
  openDialog?: 'delete' | 'valid';
  entityType: 'user' | 'role';
  entityId: number;
  user?: User;
  role?: Role;
}

interface UserMappingsContentProps
  extends WithStyles<typeof UserManagementStyles>,
    RouteComponentProps<UserMappingsContentParams> {}

export type UserMappingsContentPropsWithStores = UserMappingsContentProps & UserMappingsContentStores;

@inject(...stores)
class UserMappingsContentComponent extends Component<UserMappingsContentProps, UserMappingsContentState> {
  public state: UserMappingsContentState = {
    isPanelOpen: false,
    entityId: 0,
    entityType: 'user',
  };
  private fetchPromises: CancelableFetchPromises = {};
  private readonly filterFields: string[] = ['value'];
  private readonly sortFieldMap: DataTableSortFieldMap<UserRoleNodeMapping> = { id: 'id' };
  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    200: 'UserRoleNodeMapping already exists.',
    201: 'UserRoleNodeMapping created.',
    204: 'UserRoleNodeMapping deleted.',
  };
  private readonly successUpdateStatusCodes: SuccessHandlerStatusMessages = {
    200: 'UserRoleNodeMapping updated.',
  };

  get stores(): UserMappingsContentStores {
    return this.props as UserMappingsContentProps & UserMappingsContentStores;
  }

  public static getDerivedStateFromProps(
    props: Readonly<UserMappingsContentProps>,
    state: UserMappingsContentState,
  ): Partial<UserMappingsContentState> | null {
    const entityId = parseInt(props.match.params.entityId, 10);
    const entityType = props.match.params.entityType;

    if (entityId !== state.entityId || entityType !== state.entityType) {
      return {
        entityId,
        entityType,
      };
    }

    return null;
  }

  public componentWillUnmount(): void {
    const { contentTitleStore } = this.stores;
    contentTitleStore.setContentTitle(undefined);
    cancelFetchPromises(this.fetchPromises);
  }

  public renderUsernameContentTitle = (username: string) => (
    <span>
      :&nbsp;
      <span key={'title-username'} style={{ fontWeight: 'bold' }}>
        {username}
      </span>
    </span>
  );

  public fetchUserMappings = async (): Promise<PaginationResponse<UserRoleNodeMapping>> => {
    const { api } = this.stores;
    const { user, role } = this.state;

    const data = await request<UserRoleNodeMapping[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getUserRoleNodeMappings(user, role, undefined, true, true),
      EslManagerPrivateRoute.USER_ROLE_NODE_MAPPINGS,
      HttpMethod.GET,
    );

    return injectFakePagination<UserRoleNodeMapping>(data);
  };

  public fetchEntity = async (): Promise<void> => {
    const { api } = this.stores;
    const { entityId, entityType } = this.state;

    if (entityType === 'user') {
      const user = await request<User>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getUser(entityId),
        EslManagerPrivateRoute.USER,
        HttpMethod.GET,
      );

      // maybe we have to handle this, but not sure
      // contentTitleStore.setContentTitle(this.renderUsernameContentTitle((entity as User).username));

      this.setState({ user, role: undefined });
    } else {
      const role = await request<Role>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getRole(entityId),
        EslManagerPrivateRoute.ROLE,
        HttpMethod.GET,
      );

      this.setState({ role, user: undefined });
    }
  };

  public fetchItems = async (): Promise<PaginationResponse<UserRoleNodeMapping>> => {
    cancelFetchPromises(this.fetchPromises);

    await this.fetchEntity();
    return await this.fetchUserMappings();
  };

  public onClickValidUntil = (userRoleNodeMapping: UserRoleNodeMapping) => {
    const { user } = this.state;

    this.setState({
      selectedMapping: {
        ...userRoleNodeMapping,
        user,
      },
      openDialog: 'valid',
    });
  };

  public actionHandlerDeleteDialog = (mapping: UserRoleNodeMapping) => {
    const { user } = this.state;
    this.setState({
      openDialog: 'delete',
      selectedMapping: { user, ...mapping },
    });
  };

  public onDismiss = () => {
    this.setState({
      isPanelOpen: false,
      openDialog: undefined,
      selectedMapping: undefined,
    });
  };

  public onAddItemClick = () => {
    const { isPanelOpen } = this.state;

    this.setState({ isPanelOpen: !isPanelOpen, selectedMapping: undefined });
  };

  public refreshTable = () => {
    const { searchContentStore } = this.stores;
    searchContentStore.emitRefresh();
  };

  public onSaveItem = async (mapping: UserRoleNodeMappingPayload): Promise<UserRoleNodeMapping> => {
    const { api } = this.stores;

    const userRoleNodeMapping = await request<UserRoleNodeMapping>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.addUserRoleNodeMapping(mapping),
      EslManagerPrivateRoute.USER_ROLE_NODE_MAPPING,
      HttpMethod.POST,
      this.successStatusCodes,
    );

    this.refreshTable();

    return userRoleNodeMapping;
  };

  public handleUpdateMapping = async (
    userRoleNodeMapping: Required<Pick<UserRoleNodeMapping, 'id' | 'validUntil'>>,
  ): Promise<boolean> => {
    const { api } = this.stores;

    await request<UserRoleNodeMapping>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.updateUserRoleNodeMapping(userRoleNodeMapping),
      EslManagerPrivateRoute.USER_ROLE_NODE_MAPPING,
      HttpMethod.POST,
      this.successUpdateStatusCodes,
    );

    this.refreshTable();

    return true;
  };

  public handleDeleteMapping = async (
    userRoleNodeMapping: Required<Pick<UserRoleNodeMapping, 'id'>>,
  ): Promise<void> => {
    const { api } = this.stores;

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteUserRoleNodeMapping(userRoleNodeMapping),
      EslManagerPrivateRoute.USER_ROLE_NODE_MAPPING,
      HttpMethod.DELETE,
      this.successStatusCodes,
    );

    this.refreshTable();
  };

  public handleChangeValidUntil = (date: Moment | null) => {
    const { selectedMapping } = this.state;

    if (selectedMapping === undefined || date == null) {
      return;
    }

    selectedMapping.validUntil = date.format('YYYY-MM-DD');

    this.setState({
      selectedMapping,
    });
  };

  public getOpenDialog = (): JSX.Element | undefined => {
    const { classes } = this.props;
    const { openDialog, selectedMapping } = this.state;

    if (selectedMapping === undefined) {
      return;
    }

    switch (openDialog) {
      case 'valid': {
        const validUntil = moment(selectedMapping.validUntil);
        const content = (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minWidth: 300 }}
          >
            <Grid item xs={12}>
              <div>
                <div>
                  User: <span className={classes.boldFont}>{selectedMapping.user!.username}</span>
                </div>
                <div>
                  Role: <span className={classes.boldFont}>{selectedMapping.role!.name}</span>
                </div>
                <div>
                  Node: <span className={classes.boldFont}>{selectedMapping.node!.path}</span>
                </div>
                <br />
                <DatePicker
                  className={classes.margin}
                  label="Valid-Until"
                  disablePast
                  value={validUntil}
                  onChange={this.handleChangeValidUntil}
                />
              </div>
            </Grid>
          </Grid>
        );
        return (
          <GenericDialog
            open
            type="confirmation"
            title={'Update Valid-Until'}
            text={content}
            onClose={this.onDismiss}
            onConfirm={this.onUpdateOk}
          />
        );
      }
      case 'delete':
        return (
          <GenericDialog
            open
            fullWidth
            centered
            type="confirmation"
            maxWidth={'sm'}
            title={'Delete Mapping'}
            text={
              <>
                <div>
                  Delete Mapping for User: <span className={classes.boldFont}>{selectedMapping.user!.username}</span>
                </div>
                <div>
                  with Role: <span className={classes.boldFont}>{selectedMapping.role!.name}</span>
                </div>
                <div>
                  on Node: <span className={classes.boldFont}>{selectedMapping.node!.path}</span>?
                </div>
              </>
            }
            onClose={this.onDismiss}
            onConfirm={this.onDeleteOk}
          />
        );
      default:
        return;
    }
  };

  public onDeleteOk = async () => {
    const { selectedMapping } = this.state;

    if (!selectedMapping || !selectedMapping.id || selectedMapping.user === undefined || !selectedMapping.user.id) {
      return;
    }

    const mapping = {
      id: selectedMapping.id,
      user: { ...selectedMapping.user },
    };

    await this.handleDeleteMapping(mapping);
    this.onDismiss();
  };

  public onUpdateOk = async () => {
    const { selectedMapping } = this.state;

    if (
      !selectedMapping ||
      !selectedMapping.id ||
      !selectedMapping.validUntil ||
      selectedMapping.validUntil === '' ||
      selectedMapping.user === undefined ||
      !selectedMapping.user.id
    ) {
      return;
    }

    const mapping = {
      id: selectedMapping.id,
      user: { ...selectedMapping.user },
      validUntil: selectedMapping.validUntil,
    };

    if (await this.handleUpdateMapping(mapping)) {
      this.onDismiss();
    }
  };

  public goBack = () => {
    const { entityType } = this.state;
    const { history } = this.props;

    history.replace('/' + entityType + 's');
  };

  public getPanel = () => {
    const { isPanelOpen, user, role } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return <UserMappingPanel user={user} role={role} saveHandler={this.onSaveItem} closeHandler={this.onDismiss} />;
  };

  public render() {
    const { api } = this.stores;
    const { classes } = this.props;

    const hasWritePermission =
      api.userHasPermissionOnAnyNode(Permissions.MAPPINGS_WRITE) ||
      api.userHasPermissionOnAnyNode(Permissions.MAPPINGS_WRITE_RESTRICTED);

    const columnDefinitions: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(
        this.state,
        this.props as UserMappingsContentPropsWithStores,
        {
          delete: this.actionHandlerDeleteDialog,
        },
        {
          onClickValidUntil: this.onClickValidUntil,
        },
      ),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={this.getOpenDialog()}
          buttons={
            hasWritePermission
              ? [
                  <ContentControlButton
                    key={'add'}
                    content={<Add />}
                    tooltip={'Add User-Role Mapping'}
                    onClick={this.onAddItemClick}
                  />,
                ]
              : []
          }
        />

        <Paper className={classNames(classes.root)}>
          <Button size={'small'} style={{ borderRadius: 0 }} variant="text" color="secondary" onClick={this.goBack}>
            <KeyboardArrowLeft /> GO Back
          </Button>
          <DataTable
            fetchItems={this.fetchItems}
            columns={columnDefinitions}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            disableFooter={true}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<UserMappingsContentProps, typeof UserMappingsContentComponent>(
  UserMappingsContentComponent,
);
const StyleWrapped = withStyles(UserManagementStyles)(RouterWrapped);

export const UserMappingsContent = StyleWrapped;
