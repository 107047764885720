import {
  EslManagerPublicRouteV2,
  HttpMethod,
  Pagination,
  PaginationResponse,
  PickerProfile,
  SavePickerProfilePayload,
  ZoneIdentifier,
} from '@ekkogmbh/apisdk';
import { Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { PickerProfileStore } from '../Stores/PickerProfileStore';
import { PickerProfileManagementStyles } from '../Styles/PickerProfileManagementStyles';
import { materialDatatableColumnDefinitions } from './PickerProfileDatatableColumnDefinitions';
import { PickerProfilePanel } from './PickerProfilePanel';
import { NotificationStrategyStore } from 'src/ZoneManagement/Stores/NotificationStrategyStore';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

const styles = PickerProfileManagementStyles;

const stores = [
  'api',
  'pickerProfileStore',
  'notificationStrategyStore',
  'paginationStore',
  'searchContentStore',
  'navigationStore',
];

export interface PickerProfileManagementContentActionHandlers {
  edit: (pickerProfile: PickerProfile) => void;
  delete: (pickerProfile: PickerProfile) => void;
}

export interface PickerProfileManagementContentStores {
  api: ApiStore;
  pickerProfileStore: PickerProfileStore;
  notificationStrategyStore: NotificationStrategyStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

export interface PickerProfileManagementContentState {
  selectedPickerProfile?: PickerProfile;
  isPanelOpen: boolean;
  isDialogOpen: boolean;
}

export interface PickerProfileManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type PickerProfileManagementContentPropsWithStores = PickerProfileManagementContentProps &
  PickerProfileManagementContentStores;

@inject(...stores)
class PickerProfileManagementContentComponent extends Component<
  PickerProfileManagementContentProps,
  PickerProfileManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<PickerProfile> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<PickerProfile> = {
    name: 'PP.name',
  };

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): PickerProfileManagementContentStores {
    return this.props as PickerProfileManagementContentPropsWithStores;
  }

  public state: PickerProfileManagementContentState = {
    isPanelOpen: false,
    isDialogOpen: false,
  };

  public componentWillUnmount() {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchPickerProfiles = async (pagination: Pagination): Promise<PaginationResponse<PickerProfile>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<PickerProfile>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getPickerProfiles(pagination),
      EslManagerPublicRouteV2.PICKER_PROFILES,
      HttpMethod.GET,
    );
  };

  public onDismiss = () => {
    this.setState({
      selectedPickerProfile: undefined,
      isPanelOpen: false,
      isDialogOpen: false,
    });
  };

  public onDelete = async (pickerProfile: PickerProfile): Promise<void> => {
    this.setState({
      selectedPickerProfile: pickerProfile,
      isDialogOpen: true,
    });
  };

  public onDeleteOk = async () => {
    const { selectedPickerProfile } = this.state;
    const { api, searchContentStore } = this.stores;

    if (selectedPickerProfile === undefined) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deletePickerProfile(selectedPickerProfile),
      EslManagerPublicRouteV2.PICKER_PROFILE,
      HttpMethod.DELETE,
      { 200: 'PickerProfile deleted.' },
    );

    this.onDismiss();
    searchContentStore.emitRefresh();
  };

  public onEdit = async (pickerProfile: PickerProfile): Promise<void> => {
    const { navigationStore, pickerProfileStore } = this.stores;

    pickerProfileStore.setEditablePickerProfile(pickerProfile);

    this.setState(
      {
        isPanelOpen: true,
        selectedPickerProfile: pickerProfile,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public onSave = async (
    zone: ZoneIdentifier,
    name: string,
    payload: SavePickerProfilePayload,
  ): Promise<PickerProfile> => {
    const { api, navigationStore, searchContentStore, pickerProfileStore } = this.stores;
    const { editablePickerProfile } = pickerProfileStore;

    const doOverwrite = editablePickerProfile !== undefined;
    const pickerProfile = await request<PickerProfile>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.savePickerProfile(zone, name, payload, doOverwrite),
      EslManagerPublicRouteV2.PICKER_PROFILE,
      HttpMethod.PUT,
      { 200: 'PickerProfile saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return pickerProfile;
  };

  public getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return <PickerProfilePanel saveHandler={this.onSave} closeHandler={this.onDismiss} />;
  };

  public render() {
    const { classes } = this.props;
    const { selectedPickerProfile, isPanelOpen, isDialogOpen } = this.state;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as PickerProfileManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={
            <GenericDialog
              type="confirmation"
              maxWidth="sm"
              fullWidth={true}
              centered={true}
              open={isDialogOpen}
              title="Delete Picker Profile"
              text={selectedPickerProfile?.name ?? ''}
              onClose={this.onDismiss}
              onConfirm={this.onDeleteOk}
            />
          }
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              tooltip={'Add Picker Profile'}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedPickerProfile: undefined })}
              requiredPermission={Permissions.PICKER_PROFILES_WRITE}
            />,
          ]}
        />

        <Paper className={classes.root}>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchPickerProfiles}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<PickerProfileManagementContentProps, typeof PickerProfileManagementContentComponent>(
  PickerProfileManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const PickerProfileManagementContent = StyleWrapped;
