import {
  EslManagerPrivateRoute,
  HttpMethod,
  Label,
  OperationCreate,
  OperationPayloadSwitchPage,
  OperationType,
  PaginationResponse,
} from '@ekkogmbh/apisdk';
import { Pagination } from '@ekkogmbh/apisdk/src/Sdk/ApiTypes';
import { Chip, Fade, Grid, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent } from 'react';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { CSVDropzone } from '../../Common/Components/CSVDropzone';
import { UploadStep } from '../../Common/Components/CSVImportStepper/UploadStep';
import { spacer } from '../../Common/Components/Forms/Spacer';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { CsvDropzoneStore } from '../../Common/Stores/CsvDropzoneStore';
import { FormStyles } from '../../Common/Styles/FormStyles';
import { OperationGroupStore } from '../Stores/OperationGroupStore';
import { CREATION_TYPE_COMPARTMENT_UPDATE } from './OperationGroupPanel';

const styles = FormStyles;
const fadeTimeout = 2000;

const stores = ['api', 'operationGroupStore', 'csvDropzoneStore'];

type LabelListElement = {
  label: Label;
  selected: boolean;
};

interface OperationFormStores {
  api: ApiStore;
  operationGroupStore: OperationGroupStore;
  csvDropzoneStore: CsvDropzoneStore;
}

interface OperationFormState {
  loading: boolean;
  fileDropped: boolean;
  labels: LabelListElement[];
  pageNumber: number;
  coordinate: string;
}

interface OperationFormProps extends WithStyles<typeof styles> {}

@inject(...stores)
@observer
class OperationFormComponent extends React.Component<OperationFormProps, OperationFormState> {
  public state: OperationFormState = {
    loading: false,
    fileDropped: false,
    labels: [],
    pageNumber: 0,
    coordinate: '',
  };

  get stores(): OperationFormStores {
    return this.props as OperationFormProps & OperationFormStores;
  }

  private fetchPromises: CancelableFetchPromises = {};

  public async componentDidMount() {
    const { operationGroupStore } = this.stores;
    const {
      state: { operations },
    } = operationGroupStore;

    let pageNumber = 0;

    const selectedLabels: string[] = operations.map((operation: OperationCreate) => {
      const { payload } = operation;
      const switchPagePayload = payload as OperationPayloadSwitchPage;

      pageNumber = switchPagePayload.pageNumber;

      return switchPagePayload.labelId + '::' + switchPagePayload.technology;
    });

    const rawLabels = await this.fetchLabels({ page: 1, limit: -1 } as Pagination);

    const labels = rawLabels.map((label: Label) => {
      const { id, technology } = label;
      const idAndTechnology = id + '::' + technology;

      return { label, selected: selectedLabels.includes(idAndTechnology), pageNumber } as LabelListElement;
    });

    this.setState({ labels, pageNumber });
  }

  public fetchLabels = async (pagination: Pagination): Promise<Label[]> => {
    const { api } = this.stores;

    const response = await request<PaginationResponse<Label>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getLabels(pagination),
      EslManagerPrivateRoute.LABELS,
      HttpMethod.GET,
    );

    return response.items ?? [];
  };

  public stepCompartmentImport = (): React.JSX.Element => {
    const { fileDropped, loading } = this.state;

    const onDrop = () => {
      this.setState({ fileDropped: true });
    };

    return (
      <Grid container item spacing={2} alignItems={'stretch'}>
        <Grid item xs={4}>
          <Fade in={true} timeout={fadeTimeout}>
            <StyledSelectField
              native
              disabled
              value="compartment-update-import"
              onChange={() => undefined}
              label="Type"
            >
              <option key={0} value="compartment-update-import">
                Compartment-Import
              </option>
            </StyledSelectField>
          </Fade>
        </Grid>

        {spacer(8)}

        {fileDropped && (
          <Grid item xs={12}>
            <Fade in={true} timeout={fadeTimeout}>
              <div>
                <UploadStep loading={loading} />
              </div>
            </Fade>
          </Grid>
        )}

        {!fileDropped && (
          <Grid item xs={12}>
            <Fade in={true} timeout={fadeTimeout}>
              <div>
                <CSVDropzone onDrop={onDrop} />
              </div>
            </Fade>
          </Grid>
        )}
      </Grid>
    );
  };

  public createOperationsFromState = (): void => {
    const { operationGroupStore } = this.stores;
    const { labels, pageNumber } = this.state;

    const selectedLabels = labels.filter((labelItem: LabelListElement) => labelItem.selected);
    const operations = selectedLabels.map(
      (labelItem: LabelListElement): OperationCreate => {
        const { label } = labelItem;
        const payload = {
          labelId: label.id,
          technology: label.technology,
          pageNumber,
        } as OperationPayloadSwitchPage;

        return { identifier: label.identifierValue, type: OperationType.SWITCH_PAGE, payload } as OperationCreate;
      },
    );

    operationGroupStore.setState({ operations });
  };

  public renderLabel = (labelElement: LabelListElement, index: number): React.JSX.Element => {
    const { labels } = this.state;
    const { label, selected } = labelElement;

    const toggleSelect = (): void => {
      labels[index].selected = !selected;
      this.setState({ labels }, () => this.createOperationsFromState());
    };

    return (
      <Grid item key={index}>
        <Chip
          onClick={toggleSelect}
          variant={selected ? 'outlined' : undefined}
          style={{
            position: 'relative',
            margin: 8,
            height: 48,
            overflow: 'hidden',
          }}
          label={
            <span style={{ position: 'relative' }}>
              <Typography
                variant={'overline'}
                align={'center'}
                color={'textPrimary'}
                display={'block'}
                style={{
                  lineHeight: 'normal',
                  fontWeight: 700,
                  userSelect: 'text',
                }}
              >
                {label.id}
              </Typography>
              <Typography
                variant={'overline'}
                align={'center'}
                color={'textSecondary'}
                display={'block'}
                style={{
                  lineHeight: 'normal',
                  fontWeight: 300,
                  fontSize: 9,
                  userSelect: 'text',
                }}
              >
                {label.technology}
              </Typography>
            </span>
          }
        />
      </Grid>
    );
  };

  public stepSwitchPage = (): React.JSX.Element => {
    const { labels, pageNumber, coordinate } = this.state;

    const setPageNumber = (event: ChangeEvent<HTMLInputElement>): void => {
      const pageNumber = parseInt(event.target.value);
      this.setState({ pageNumber }, () => this.createOperationsFromState());
    };

    const setCoordinate = (event: ChangeEvent<HTMLInputElement>): void => {
      const coordinate = event.target.value.trim();

      this.setState({ coordinate });
    };

    const labelChips: React.JSX.Element[] = [];
    labels.forEach((labelItem: LabelListElement, index: number) => {
      if (labelItem.label.identifierValue.startsWith(coordinate)) {
        labelChips.push(this.renderLabel(labelItem, index));
      }
    });

    return (
      <Grid container item spacing={2} alignItems={'stretch'}>
        <Grid item xs={4}>
          <Fade in={true} timeout={fadeTimeout}>
            <StyledSelectField native disabled value="label-select" onChange={() => undefined} label="Type">
              <option key={0} value="label-select">
                Label-Select
              </option>
            </StyledSelectField>
          </Fade>
        </Grid>

        {spacer(8)}

        <Fade in={true} timeout={fadeTimeout}>
          <Grid container item spacing={2}>
            <Grid item xs={3}>
              <StyledTextField
                type={'text'}
                value={coordinate}
                label={'filter-by-coordinate'}
                onChange={setCoordinate}
              />
            </Grid>

            <Grid item xs={1}>
              <StyledTextField type={'number'} value={pageNumber} label={'page-number'} onChange={setPageNumber} />
            </Grid>
          </Grid>
        </Fade>

        {labels.length > 0 && (
          <Grid container item xs={12} style={{ maxHeight: 500, overflowY: 'scroll' }}>
            <Fade in={true} timeout={fadeTimeout}>
              <Grid container item>
                {labelChips}
              </Grid>
            </Fade>
          </Grid>
        )}
      </Grid>
    );
  };

  public render() {
    const { operationGroupStore } = this.stores;
    const { loading } = this.state;
    const { creationType } = operationGroupStore.state;

    const operationForm =
      creationType === CREATION_TYPE_COMPARTMENT_UPDATE ? this.stepCompartmentImport() : this.stepSwitchPage();

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        {loading && <LoadingMask />}

        {operationForm}
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(OperationFormComponent);

export const OperationForm = StyleWrapped;
