import { Node } from '@ekkogmbh/apisdk';
import { ArrowBack, Delete, Refresh, Remove, SubdirectoryArrowRight, TurnedIn } from '@mui/icons-material';
import Assignment from '@mui/icons-material/Assignment';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import React, { CSSProperties, Fragment } from 'react';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import { DatatableColumnDefinitionFn } from '../../Common/Components/DataTable';
import { ActionButton, DataTableActionsComponent } from '../../Common/Components/DataTable/DataTableActions';
import { Permissions } from '../../Common/Stores/ApiStore';
import {
  AreaManagementContentActionHandlers,
  AreaManagementContentPropsWithStores,
  AreaManagementContentState,
  nodeSeparator,
} from './AreaManagementContent';

class AreaDataTableActions extends DataTableActionsComponent<Node> {}

const AreaActions = (
  state: AreaManagementContentState,
  propsWithStores: AreaManagementContentPropsWithStores,
  actions: AreaManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: ' ',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: (area: Node): React.ReactNode => {
      const { api } = propsWithStores;

      const writeable: boolean = state.id !== undefined && parseInt(state.id, 10) !== area.id;

      const actionButtons: ActionButton<Node>[] = [
        {
          title: 'Annotations',
          onClick: actions.annotations,
          icon: TurnedIn,
        },
        {
          title: 'Users',
          onClick: actions.users,
          icon: Assignment,
          disabled:
            !api.userHasPermissionOnAnyNode(Permissions.USERS_READ) ||
            !api.userHasPermissionOnAnyNode(Permissions.MAPPINGS_READ),
        },
        {
          title: 'Synchronize Devices',
          onClick: actions.sync,
          icon: Refresh,
        },
        {
          title: 'Delete',
          onClick: actions.delete,
          icon: Delete,
          disabled: !writeable || !api.userHasPermissionOnAnyNode(Permissions.AREAS_WRITE),
        },
      ];

      return <AreaDataTableActions dataset={area} isProcessing={false} actionButtons={actionButtons} />;
    },
  },
});

const AreaBodyRenderIdentifier = (
  propsWithStores: AreaManagementContentPropsWithStores,
  // eslint-disable-next-line react/display-name
) => (path: Node['path']) => {
  const { classes } = propsWithStores;

  const identifier = path.split(nodeSeparator);

  const mapper = (val: string, idx: number) => (
    <Fragment key={`${val}${idx}`}>
      <span className={classes.verticalAlignMiddle}>{val}</span>
      {idx < identifier.length - 1 && <span className={classes.verticalAlignMiddle}>{nodeSeparator}</span>}
    </Fragment>
  );

  return <div>{identifier.map(mapper)}</div>;
};

const AreaIdentifier = (
  _: AreaManagementContentState,
  propsWithStores: AreaManagementContentPropsWithStores,
): MUIDataTableColumnDef => ({
  label: 'Path',
  name: 'entry.path',
  options: {
    sort: true,
    filter: false,
    customBodyRender: AreaBodyRenderIdentifier(propsWithStores),
  },
});

const AreaBodyRenderValue = (
  state: AreaManagementContentState,
  propsWithStores: AreaManagementContentPropsWithStores,
  _: AreaManagementContentActionHandlers,
  // eslint-disable-next-line react/display-name
) => (value: Node) => {
  const { id, rootNodes } = state;
  const { classes } = propsWithStores;
  const { searchText } = propsWithStores.searchContentStore;
  const actualValue = value.value;
  const unhighlightStyle: CSSProperties = {
    fontWeight: 700,
    fontSize: 14,
  };
  const highlightStyle: CSSProperties = {
    ...unhighlightStyle,
    backgroundColor: '#BBB',
  };

  const rootNodeIds = rootNodes.map((node: Node) => node.id);
  const { hasChildren } = value;

  const isCurrentNode = String(value.id) === id;

  const getIcon = () => {
    if (isCurrentNode) {
      return ArrowBack;
    } else if (hasChildren) {
      return SubdirectoryArrowRight;
    } else {
      return Remove;
    }
  };

  const Icon = getIcon();

  const iconClassNames = classNames(classes.verticalAlignMiddle, classes.icon, !isCurrentNode && classes.iconSpacer);
  const highlighterClassNames = classNames(classes.verticalAlignMiddle);

  const content = (
    <div>
      <Icon className={iconClassNames} />

      <Highlighter
        className={highlighterClassNames}
        searchWords={[searchText.replace(/\\/g, '\\\\')]}
        textToHighlight={actualValue}
        highlightStyle={highlightStyle}
        unhighlightStyle={unhighlightStyle}
      />
    </div>
  );

  const linkTo = isCurrentNode
    ? value.parentId !== null && rootNodeIds.indexOf(value.id) === -1
      ? '/areas/' + value.parentId
      : '/areas'
    : '/areas/' + value.id;

  return hasChildren || isCurrentNode ? (
    <Link className={classes.link} to={linkTo}>
      {content}
    </Link>
  ) : (
    content
  );
};

const AreaValue = (
  state: AreaManagementContentState,
  propsWithStores: AreaManagementContentPropsWithStores,
  actions: AreaManagementContentActionHandlers,
): MUIDataTableColumnDef => ({
  label: 'Name',
  name: 'entry',
  options: {
    sort: false,
    filter: false,
    customBodyRender: AreaBodyRenderValue(state, propsWithStores, actions),
  },
});

const AreaType = (): MUIDataTableColumnDef => ({
  label: 'Type',
  name: 'entry.type',
  options: {
    sort: false,
    filter: false,
  },
});

export const materialDatatableColumnDefinitions: Array<DatatableColumnDefinitionFn<
  AreaManagementContentPropsWithStores,
  AreaManagementContentState,
  AreaManagementContentActionHandlers,
  null
>> = [AreaValue, AreaIdentifier, AreaType, AreaActions];

export { AreaValue, AreaIdentifier, AreaType, AreaActions };
