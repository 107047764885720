import { Component, ReactNode } from 'react';
import {
  Accordion,
  AccordionDetails,
  Box,
  CircularProgress,
  Grow,
  Paper,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { ContentControlStyles } from '../Styles/ContentControlStyles';
import { inject, observer } from 'mobx-react';
import { TaskCollectionStore } from '../Stores/TaskCollectionStore';
import { ContentControlButton } from './ContentControl/ContentControlButton';
import { TaskCollection } from '@ekkogmbh/apisdk';
import { Check, Warning } from '@mui/icons-material';
import { ContentPanel } from './ContentControl/ContentPanel';
import { TaskCollectionProgressBar } from 'src/TaskManagement/Components/TaskCollectionProgressBar';
import { StyledFormHeader } from './Forms/StyledFormHeader';

const styles = ContentControlStyles;

/**
 * @FIXME More precise typing would be nice.
 */
interface ContentControlProps extends WithStyles<typeof styles> {
  panel?: JSX.Element;
  dialog?: JSX.Element;
  buttons?: JSX.Element[];
}

interface ContentControlStores {
  taskCollectionStore: TaskCollectionStore;
}

interface ContentControlState {
  isTasksOpen: boolean;
}
@inject('taskCollectionStore')
@observer
class ContentControlComponent extends Component<ContentControlProps, ContentControlState> {
  public state: ContentControlState = {
    isTasksOpen: false,
  };

  get stores(): ContentControlStores {
    return this.props as ContentControlProps & ContentControlStores;
  }

  public tasksPanel = () => {
    const { taskCollectionStore } = this.stores;
    const { taskCollections } = taskCollectionStore;

    let content: JSX.Element | JSX.Element[] = (
      <Typography textAlign={'center'}>{'your tasks have been finished'}</Typography>
    );

    if (taskCollections.length > 0) {
      const progressBars = taskCollections.map((tc, i) => <TaskCollectionProgressBar key={i} taskCollection={tc} />);

      content = (
        <>
          <Stack direction={'row'} spacing={3} alignItems={'flex-end'} justifyContent={'flex-start'}>
            <Typography variant={'overline'}>{'ID'}</Typography>
            <Typography variant={'overline'}>{'Progress'}</Typography>
          </Stack>
          {progressBars}
          <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'center'}>
            <Typography variant={'subtitle1'}>{'reloading the page will stop all task progress tracking'}</Typography>
            <Warning color={'secondary'} />
          </Stack>
        </>
      );
    }
    return (
      <ContentPanel
        content={
          <Stack direction={'column'} alignItems={'stretch'} justifyContent={'flex-start'} spacing={1}>
            <StyledFormHeader label={'Task Collections'} />
            {content}
          </Stack>
        }
      />
    );
  };

  public tasksProgressCircle = (progress: number) => {
    const { isTasksOpen } = this.state;
    return (
      <>
        <Box sx={{ position: 'absolute', alignItems: 'center', justifyContent: 'center' }}>
          <Typography color={'secondary'}>{progress}</Typography>
        </Box>
        <CircularProgress
          size={50}
          variant={progress > 0 ? 'determinate' : 'indeterminate'}
          color={'secondary'}
          value={progress}
          onClick={() => this.setState({ isTasksOpen: !isTasksOpen })}
        />
      </>
    );
  };

  public tasksButton = () => {
    const { isTasksOpen } = this.state;
    const { taskCollectionStore } = this.stores;
    const { taskCollections } = taskCollectionStore;

    const totalProgress =
      taskCollections.length > 0
        ? Math.floor(
            taskCollections.reduce((carry: number, tc: TaskCollection) => carry + (tc.progress ?? 0), 0) /
              taskCollections.length,
          )
        : 100;
    return (
      <Grow in={isTasksOpen || taskCollections.length > 0} appear timeout={1000} unmountOnExit>
        <div style={{ display: 'flex' }}>
          <ContentControlButton
            content={totalProgress === 100 ? <Check /> : this.tasksProgressCircle(totalProgress)}
            tooltip={'Task Progress'}
            onClick={() => this.setState({ isTasksOpen: !isTasksOpen })}
          />
        </div>
      </Grow>
    );
  };

  public slideButtons = () => {
    const { buttons } = this.props;

    return (buttons ?? []).map((btn, i) => (
      <Slide key={i} in direction={'left'} unmountOnExit>
        <div style={{ display: 'flex' }}>{btn}</div>
      </Slide>
    ));
  };

  public render(): ReactNode {
    const { isTasksOpen } = this.state;
    const { dialog, panel, classes } = this.props;

    const shownPanel = panel !== undefined ? panel : isTasksOpen ? this.tasksPanel() : undefined;
    const isExpaneded = shownPanel !== undefined;

    return (
      <>
        {dialog !== undefined && dialog}
        <>
          <Stack direction={'row'} spacing={0.5} justifyContent={'flex-end'} className={classNames(classes.buttonRow)}>
            {this.tasksButton()}
            {this.slideButtons()}
          </Stack>
          <Paper
            className={classNames(classes.root, isExpaneded ? classes.rootWhenExpanded : classes.rootWhenNotExpanded)}
          >
            <Accordion
              expanded={isExpaneded}
              className={classNames(classes.expansion, isExpaneded && classes.expansionExpanded)}
            >
              <AccordionDetails style={{ padding: 0 }}>{shownPanel ?? <></>}</AccordionDetails>
            </Accordion>
          </Paper>
        </>
      </>
    );
  }
}

const StyleWrapped = withStyles(styles)(ContentControlComponent);

export const ContentControl = StyleWrapped;
