import {
  EslManagerPrivateRoute,
  HttpMethod,
  Operation,
  Pagination,
  PaginationResponse,
  Trigger,
} from '@ekkogmbh/apisdk';
import { Button, Paper, Stack, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { KeyboardArrowLeft } from '@mui/icons-material';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises } from '../../Common/Helper/PromiseHelper';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { ContentTitleStore } from '../../Common/Stores/ContentTitleStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { CompartmentStyles } from '../Styles/CompartmentStyles';
import { CompartmentOperationTriggerDialog } from './CompartmentOperationTriggerDialog';
import { materialDatatableColumnDefinitions } from './OperationDatatableColumnDefinitions';

const styles = CompartmentStyles;

interface CompartmentDetailsContentStores {
  api: ApiStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  contentTitleStore: ContentTitleStore;
}

const stores = ['api', 'paginationStore', 'searchContentStore', 'navigationStore', 'contentTitleStore'];

export type CompartmentDetailsContentPropsWithStores = CompartmentDetailsContentProps & CompartmentDetailsContentStores;

export interface CompartmentDetailsContentState {
  loading: boolean;
  triggerDialogOpen: boolean;
  triggers: Trigger[];
}

export interface CompartmentDetailsContentActionHandlers {
  details: (operation: Operation) => void;
}

interface CompartmentDetailsContentParams {
  compartmentidentifier: string;
}

interface CompartmentDetailsContentProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<CompartmentDetailsContentParams> {}

@inject(...stores)
class CompartmentDetailsContentComponent extends Component<
  CompartmentDetailsContentProps,
  CompartmentDetailsContentState
> {
  public state: CompartmentDetailsContentState = {
    loading: false,
    triggerDialogOpen: false,
    triggers: [],
  };

  private readonly filterFields: DataTableFilterFields<Operation> = ['identifier', 'type'];
  private readonly sortFieldMap: DataTableSortFieldMap<Operation> = {
    identifier: 'O.identifier',
    type: 'O.type',
  };

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): CompartmentDetailsContentStores {
    return this.props as CompartmentDetailsContentProps & CompartmentDetailsContentStores;
  }

  public componentDidMount(): void {
    const compartmentIdentifier = this.props.match.params.compartmentidentifier;
    const { contentTitleStore } = this.stores;
    contentTitleStore.setContentTitle(this.renderCompartmentNameContentTitle(compartmentIdentifier));
  }

  public componentWillUnmount(): void {
    const { contentTitleStore } = this.stores;
    contentTitleStore.setContentTitle(undefined);
  }

  public fetchCompartmentOperations = async (pagination: Pagination): Promise<PaginationResponse<Operation>> => {
    const { api } = this.stores;
    const compartmentIdentifier = this.props.match.params.compartmentidentifier;
    return await request<PaginationResponse<Operation>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getCompartmentOperations(compartmentIdentifier, pagination),
      EslManagerPrivateRoute.COMPARTMENT_OPERATIONS,
      HttpMethod.GET,
    );
  };

  public renderCompartmentNameContentTitle = (name: string) => (
    <span>
      :&nbsp;
      <span key={'title-name'} style={{ fontWeight: 'bold' }}>
        {name}
      </span>
    </span>
  );

  public goBack = () => {
    const { history } = this.props;
    history.replace('/compartments');
  };

  public openTriggerDialog = async (operation: Operation): Promise<void> => {
    const { triggers } = operation;
    this.setState({
      triggerDialogOpen: true,
      triggers: triggers ?? [],
    });
  };

  public onDialogDismiss = () => {
    this.setState({
      triggerDialogOpen: false,
      triggers: [],
    });
  };

  public render() {
    const { classes } = this.props;
    const { loading, triggerDialogOpen, triggers } = this.state;
    const { fetchCompartmentOperations } = this;

    const operationColumnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as CompartmentDetailsContentPropsWithStores, {
        details: this.openTriggerDialog,
      }),
    );

    return (
      <>
        {triggerDialogOpen && (
          <CompartmentOperationTriggerDialog
            open={triggerDialogOpen}
            onClose={this.onDialogDismiss}
            triggers={triggers}
          />
        )}
        <Paper
          className={classNames(classes.root)}
          style={{
            position: 'relative',
            minHeight: 120,
          }}
        >
          {loading && <LoadingMask width={75} height={75} />}
          <Stack>
            <Button
              size={'small'}
              style={{
                borderRadius: 0,
                position: 'absolute',
                left: 0,
                top: 0,
              }}
              variant="text"
              color="secondary"
              onClick={this.goBack}
            >
              <KeyboardArrowLeft /> GO Back
            </Button>
            <Typography align={'center'} variant={'overline'} style={{ marginLeft: 8 }}>
              Operations
            </Typography>
          </Stack>
          <DataTable
            fetchItems={fetchCompartmentOperations}
            columns={operationColumnDefinition}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'asc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<CompartmentDetailsContentProps, typeof CompartmentDetailsContentComponent>(
  CompartmentDetailsContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const CompartmentDetailsContent = StyleWrapped;
