import { EslManagerPublicRouteV1, HttpMethod, Pagination, PaginationResponse, ReceivingArea } from '@ekkogmbh/apisdk';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject } from 'mobx-react';
import { Component } from 'react';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { request } from 'src/Common/Helper/FetchHandler';
import { enqueueSnackbar } from 'notistack';
import { WithStyles, withStyles } from '@mui/styles';
import { ReceivingAreaManagementStyles } from '../Styles/ReceivingAreaManagementStyles';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { materialDatatableColumnDefinitions } from './ReceivingAreaDatatableColumnDefinitions';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { Paper } from '@mui/material';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import classNames from 'classnames';
import { ReceivingAreaPanel } from './ReceivingAreaPanel';
import { Add } from '@mui/icons-material';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { ReceivingAreaStore } from '../Stores/ReceivingAreaStore';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

export interface ReceivingAreaManagementContentActionHandlers {
  edit: (receivingArea: ReceivingArea) => void;
  delete: (receivingArea: ReceivingArea) => void;
}

export interface ReceivingAreaManagementContentState {
  selectedArea?: ReceivingArea;
  isPanelOpen: boolean;
  isDialogOpen: boolean;
}

interface ReceivingAreaManagementContentStores {
  api: ApiStore;
  receivingAreaStore: ReceivingAreaStore;
  navigationStore: NavigationStore;
  searchContentStore: SearchContentStore;
}

interface ReceivingAreaManagementContentProps
  extends WithStyles<typeof ReceivingAreaManagementStyles>,
    RouteComponentProps {}

export type ReceivingAreaManagementContentPropsWithStores = ReceivingAreaManagementContentProps &
  ReceivingAreaManagementContentStores;

@inject('api', 'receivingAreaStore', 'navigationStore', 'searchContentStore')
class ReceivingAreaManagementContentComponent extends Component<
  ReceivingAreaManagementContentProps,
  ReceivingAreaManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<ReceivingArea> = ['name', 'coordinate'];
  private readonly sortFieldMaps: DataTableSortFieldMap<ReceivingArea> = { name: 'entry.name' };

  private fetchPromises: CancelableFetchPromises = {};

  public state: ReceivingAreaManagementContentState = {
    isPanelOpen: false,
    isDialogOpen: false,
  };

  get stores(): ReceivingAreaManagementContentStores {
    return this.props as ReceivingAreaManagementContentProps & ReceivingAreaManagementContentStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetch = async (pagination: Pagination): Promise<PaginationResponse<ReceivingArea>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<ReceivingArea>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getReceivingAreas(pagination),
      EslManagerPublicRouteV1.RECEIVING_AREAS,
      HttpMethod.GET,
    );
  };

  public onDismiss = () => {
    this.setState({
      selectedArea: undefined,
      isPanelOpen: false,
      isDialogOpen: false,
    });
  };

  public onEdit = (receivingArea: ReceivingArea) => {
    const { navigationStore, receivingAreaStore } = this.stores;

    receivingAreaStore.resetStore(receivingArea);

    this.setState({ selectedArea: receivingArea, isPanelOpen: true }, () => navigationStore.scrollTop());
  };

  public onSave = async (): Promise<void> => {
    const { api, navigationStore, searchContentStore, receivingAreaStore } = this.stores;
    const { name, coordinate, allFilled } = receivingAreaStore.state;

    if (!allFilled) {
      return;
    }

    await request<ReceivingArea>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveReceivingArea(
        { name: name!, coordinate: coordinate! },
        receivingAreaStore.getPayload(),
        receivingAreaStore.editableReceivingArea !== undefined,
      ),
      EslManagerPublicRouteV1.RECEIVING_AREA,
      HttpMethod.PUT,
      { 200: 'Receiving Area saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();
  };

  public onDelete = async (receivingArea: ReceivingArea): Promise<void> => {
    this.setState({
      selectedArea: receivingArea,
      isDialogOpen: true,
      isPanelOpen: false,
    });
  };

  public onDeleteConfirm = async () => {
    const { selectedArea } = this.state;
    const { api, searchContentStore } = this.stores;

    if (selectedArea === undefined) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteReceivingArea(selectedArea),
      EslManagerPublicRouteV1.RECEIVING_AREA,
      HttpMethod.DELETE,
      { 200: 'Receiving Area deleted.' },
      undefined,
    );

    this.onDismiss();
    searchContentStore.emitRefresh();
  };

  public getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return <ReceivingAreaPanel saveHandler={this.onSave} closeHandler={this.onDismiss} />;
  };

  public render() {
    const { classes } = this.props;
    const { selectedArea, isPanelOpen, isDialogOpen } = this.state;
    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as ReceivingAreaManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={
            <GenericDialog
              title={'Delete Receiving Area'}
              text={selectedArea?.name ?? ''}
              type={'confirmation'}
              maxWidth={'sm'}
              onClose={this.onDismiss}
              onConfirm={this.onDeleteConfirm}
              fullWidth
              centered
              open={isDialogOpen}
            />
          }
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              tooltip={'Add Receiving Area'}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedArea: undefined })}
              requiredPermission={Permissions.EKANBAN_WRITE}
            />,
          ]}
        />
        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetch}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMaps}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<ReceivingAreaManagementContentProps, typeof ReceivingAreaManagementContentComponent>(
  ReceivingAreaManagementContentComponent,
);
const StyleWrapped = withStyles(ReceivingAreaManagementStyles)(RouterWrapped);

export const ReceivingAreaManagementContent = StyleWrapped;
