import { Component, ReactNode } from 'react';
import { FormPanelButtons, FormPanelButtonsProps } from '../FormPanelButtons';
import { Stack } from '@mui/material';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { withStyles, WithStyles } from '@mui/styles';

const styles = FormStyles;

interface ContentPanelProps extends WithStyles<typeof styles> {
  onOpen?: () => void;
  onClose?: () => void;
  content: JSX.Element;
  panelButtonProps?: Omit<FormPanelButtonsProps, 'classes'>;
}
interface ContentPanelState {}

export class ContentPanelComponent extends Component<ContentPanelProps, ContentPanelState> {
  public componentDidMount(): void {
    const { onOpen } = this.props;

    if (onOpen) {
      onOpen();
    }
  }

  public componentWillUnmount(): void {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  }

  public render(): ReactNode {
    const { panelButtonProps, content } = this.props;

    return (
      <Stack direction={'column'} spacing={2}>
        {content}
        {panelButtonProps && <FormPanelButtons {...panelButtonProps} />}
      </Stack>
    );
  }
}

const StyleWrapped = withStyles(styles)(ContentPanelComponent);

export const ContentPanel = StyleWrapped;
