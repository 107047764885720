import { Radio, SelectChangeEvent, Stack, Tab, Tabs } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { Component } from 'react';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { ContentPanel } from 'src/Common/Components/ContentControl/ContentPanel';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { ConfigStore } from 'src/Common/Stores/ConfigStore';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { AllInbox, Link } from '@mui/icons-material';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { EslManagerPrivateRoute, Filtering, HttpMethod } from '@ekkogmbh/apisdk';
import { createBlobDownload } from 'src/Common/Helper/BlobDownload';

const styles = FormStyles;
interface ExportPanelState {
  loading: boolean;
  exportEntity: 'compartment' | 'link';
  exportFormatIndex: number;
  exportFormatTypes: string[];
}

interface ExportPanelStores {
  api: ApiStore;
  configStore: ConfigStore;
  paginationStore: PaginationStore;
}

export interface ExportPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
}

const FormatTypeMap: Record<string, Record<string, string>> = {
  csv: {
    text: 'CSV',
    contentType: 'text/csv',
  },
  json: {
    text: 'JSON',
    contentType: 'application/json',
  },
};

const FileExtMap: Record<string, string> = {
  'application/json': 'json',
  'text/plain': 'csv',
  'text/csv': 'csv',
};

@inject('api', 'configStore', 'paginationStore')
class ExportPanelComponent extends Component<ExportPanelProps, ExportPanelState> {
  public state: ExportPanelState = {
    exportEntity: 'compartment',
    exportFormatIndex: -1,
    exportFormatTypes: [],
    loading: true,
  };

  get stores(): ExportPanelStores {
    return this.props as ExportPanelProps & ExportPanelStores;
  }

  public fetchPromises: CancelableFetchPromises = {};

  public async componentDidMount(): Promise<void> {
    const { closeHandler } = this.props;
    const { configStore } = this.stores;
    const { exportFormatTypes } = configStore.config;

    const validTypes = Object.keys(FormatTypeMap);
    const prunedTypes = exportFormatTypes.filter((t) => {
      if (!validTypes.includes(t)) {
        enqueueSnackbar(`Unknown export type "${t}" found in config`);
        return false;
      }
      return true;
    });

    if (prunedTypes.length === 0) {
      closeHandler();
    }

    this.setState({
      loading: false,
      exportFormatTypes: prunedTypes,
      exportFormatIndex: 0,
    });
  }

  public handleTabChange = (_: React.SyntheticEvent, value: 'compartment' | 'link'): void => {
    this.setState({ exportEntity: value });
  };

  public handleExportTypeChange = ({ target: { value } }: SelectChangeEvent<unknown>): void => {
    this.setState({ exportFormatIndex: value as number });
  };

  public getFormatTypeOptions = (): JSX.Element[] => {
    const { exportFormatTypes } = this.state;
    return exportFormatTypes.map((formatType: string, key: number) => (
      <option key={key} value={key}>
        {FormatTypeMap[formatType].text}
      </option>
    ));
  };

  public doExport = async () => {
    const { api, paginationStore } = this.stores;
    const { pagination } = paginationStore;
    const { exportEntity, exportFormatIndex, exportFormatTypes } = this.state;

    const exportFormat = exportFormatTypes[exportFormatIndex];
    const { contentType } = FormatTypeMap[exportFormat];
    const filtering = pagination as Filtering;

    this.setState({ loading: true });

    try {
      const exportCall =
        exportEntity === 'link'
          ? api.exportLinks(filtering, contentType)
          : api.exportCompartments(filtering, contentType);

      const exportRoute =
        exportEntity === 'link' ? EslManagerPrivateRoute.EXPORTER_LINKS : EslManagerPrivateRoute.EXPORTER_COMPARTMENTS;

      const blob = await request<Blob>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        exportCall,
        exportRoute,
        HttpMethod.GET,
      );

      const timestamp = Date.now();
      const filename = `${exportEntity}s_${timestamp}.${FileExtMap[contentType]}`;
      createBlobDownload(blob, filename);
    } catch (e) {
      enqueueSnackbar((e as Error).toString(), { variant: 'error' });
    }

    this.setState({ loading: false });
  };

  public render() {
    const { classes, closeHandler } = this.props;
    const { exportEntity, exportFormatIndex } = this.state;

    const formatOptions = this.getFormatTypeOptions();

    return (
      <ContentPanel
        content={
          <Stack direction={'column'} alignItems={'center'} justifyContent={'flex-start'} spacing={4}>
            <Tabs
              value={exportEntity}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="secondary"
              classes={{
                flexContainer: classes.exportFlexContainer,
                indicator: classes.exportIndicator,
              }}
            >
              {['compartment', 'link'].map((entity: string) => {
                const { exportEntity } = this.state;

                const description = entity[0].toUpperCase() + entity.slice(1) + 's';
                const icon = entity === 'link' ? <Link style={{ margin: 11 }} /> : <AllInbox style={{ margin: 11 }} />;

                return (
                  <Tab
                    key={entity}
                    value={entity}
                    style={{
                      display: 'block',
                      maxWidth: 400,
                    }}
                    label={
                      <div
                        style={{
                          display: 'inline-flex',
                          justifyContent: 'center',
                          lineHeight: '48px',
                        }}
                      >
                        <Radio checked={exportEntity === entity} />
                        {icon}
                        {description}
                      </div>
                    }
                  />
                );
              })}
            </Tabs>
            <div style={{ width: '25%' }}>
              <StyledSelectField native value={exportFormatIndex} onChange={this.handleExportTypeChange} label="Type">
                {formatOptions}
              </StyledSelectField>
            </div>
          </Stack>
        }
        onClose={closeHandler}
        panelButtonProps={{
          saveButtonLabel: 'Export',
          cancelHandler: closeHandler,
          saveHandler: this.doExport,
          isDeleteHidden: true,
        }}
      />
    );
  }
}

const StyleWrapped = withStyles(styles)(ExportPanelComponent);

export const ExportPanel = StyleWrapped;
