import {
  EslManagerPrivateRoute,
  HttpMethod,
  Template,
  TemplateSavePayload,
  Pagination,
  PaginationResponse,
  RendererResult,
  RendererPayload,
  TemplateWithData,
  ApplicableRenderersPayload,
  ApplicableRenderersResult,
} from '@ekkogmbh/apisdk';
import { Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add } from '@mui/icons-material';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject, observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { createBlobDownload } from 'src/Common/Helper/BlobDownload';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { SuccessHandlerStatusMessages } from 'src/Common/Helper/ResponseHandler';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { PaginationStore } from 'src/Common/Stores/PaginationStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { TemplateStore } from '../Stores/TemplateStore';
import { TemplateManagementStyles } from '../Styles/TemplateManagementStyles';
import { materialDatatableColumnDefinitions } from './TemplateManagementDatatableColumnDefinitions';
import { TemplatePanel } from './TemplatePanel';
import { TemplatePreviewDialog } from './TemplatePreviewDialog';
import { ConfigStore } from 'src/Common/Stores/ConfigStore';
import React from 'react';
import { TaskCollectionStore } from 'src/Common/Stores/TaskCollectionStore';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';
import classNames from 'classnames';

const styles = TemplateManagementStyles;

const stores = [
  'api',
  'templateStore',
  'paginationStore',
  'searchContentStore',
  'navigationStore',
  'configStore',
  'taskCollectionStore',
];

export interface TemplateManagementContentActionHandlers {
  preview: (template: TemplateWithData) => void;
  download: (template: TemplateWithData) => void;
  edit: (template: TemplateWithData) => void;
  delete: (template: Template) => void;
}

export interface TemplateManagementContentState {
  isPanelOpen: boolean;
  openDialog?: 'delete' | 'preview';
  selectedTemplate?: Template | TemplateWithData;
}

export interface TemplateManagementContentStores {
  api: ApiStore;
  templateStore: TemplateStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  configStore: ConfigStore;
  taskCollectionStore: TaskCollectionStore;
}

export interface TemplateManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type TemplateManagementContentPropsWithStores = TemplateManagementContentProps & TemplateManagementContentStores;

@inject(...stores)
@observer
class TemplateManagementContentComponent extends Component<
  TemplateManagementContentProps,
  TemplateManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<Template> = ['name', 'type'];

  private readonly sortFieldMap: DataTableSortFieldMap<Template> = {
    name: 'T.name',
    type: 'T.type',
  };

  private fetchPromises: CancelableFetchPromises = {};

  private readonly successStatusCodes: SuccessHandlerStatusMessages = {
    201: 'Template created.',
    204: 'Template deleted.',
  };

  get stores(): TemplateManagementContentStores {
    return this.props as TemplateManagementContentPropsWithStores;
  }

  public state: TemplateManagementContentState = {
    isPanelOpen: false,
  };

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetchTemplates = async (pagination: Pagination): Promise<PaginationResponse<TemplateWithData>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<TemplateWithData>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getTemplates(pagination),
      EslManagerPrivateRoute.TEMPLATE,
      HttpMethod.GET,
    );
  };

  public fetchRendererResult = async (payload: RendererPayload): Promise<RendererResult> => {
    const { api, templateStore } = this.stores;

    templateStore.loading = true;

    return await request<RendererResult>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.renderTemplate(payload),
      EslManagerPrivateRoute.RENDERER,
      HttpMethod.POST,
      undefined,
      () => {
        templateStore.loading = false;
      },
      () => {
        templateStore.loading = false;
      },
    );
  };

  public getApplicableRenderers = async (payload: ApplicableRenderersPayload): Promise<ApplicableRenderersResult> => {
    const { api, templateStore } = this.stores;

    templateStore.loading = true;

    return await request<ApplicableRenderersResult>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getApplicableRenderers(payload),
      EslManagerPrivateRoute.APPLICABLE_RENDERERS,
      HttpMethod.POST,
      undefined,
      () => {
        templateStore.loading = false;
      },
      () => {
        templateStore.loading = false;
      },
    );
  };

  public saveTemplate = async (
    coordinate: string,
    name: string,
    template: TemplateSavePayload,
  ): Promise<TemplateWithData> => {
    const { api, searchContentStore, taskCollectionStore } = this.stores;

    const taskCollectionHandlerFn = (taskCollectionUri: string | null) => {
      taskCollectionStore.processTaskCollection(
        taskCollectionUri,
        (taskCollectionId: string, __: string, progress?: number) => {
          if (progress === 100) {
            console.log('Template Change. Tasks Finished. Collection ID: ' + taskCollectionId);
          }
        },
      );
    };

    return await request<TemplateWithData>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveTemplate(coordinate, name, template, taskCollectionHandlerFn),
      EslManagerPrivateRoute.SAVE_TEMPLATE,
      HttpMethod.PUT,
      this.successStatusCodes,
      () => searchContentStore.emitRefresh(),
    );
  };

  public onEdit = async (template: TemplateWithData): Promise<void> => {
    const { navigationStore, templateStore, configStore } = this.stores;
    const { coordinateFieldName, labelIdFieldName } = configStore.config;

    // FIXME: this should happen in the panel itself I think
    const rendererResult = await this.fetchRendererResult({
      data: template.data,
      type: template.type,
      fields: {},
      autoFill: true,
      render: true,
    });

    const keys = Object.keys(rendererResult.fields);

    if (keys.includes(coordinateFieldName)) {
      const newFields = rendererResult.fields;
      delete newFields[coordinateFieldName];
      rendererResult.fields = newFields;
      templateStore.reservedFieldCoordinate = true;
    }

    if (keys.includes(labelIdFieldName)) {
      const newFields = rendererResult.fields;
      delete newFields[labelIdFieldName];
      rendererResult.fields = newFields;
      templateStore.reservedFieldLabelId = true;
    }

    templateStore.setEditableTemplate(template, rendererResult);

    this.setState(
      {
        selectedTemplate: template,
        isPanelOpen: true,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public onDownload = async (template: TemplateWithData): Promise<void> => {
    try {
      const decodedData = Buffer.from(template.data, 'base64').toString('utf8');
      const blob = new Blob([decodedData], { type: 'text/plain' });
      const filename = `${template.name}.xsl`;
      createBlobDownload(blob, filename);
    } catch (e) {
      enqueueSnackbar((e as Error).toString(), { variant: 'error' });
    }
  };

  public onPreview = async (template: TemplateWithData): Promise<void> => {
    this.setState({
      openDialog: 'preview',
      selectedTemplate: template,
    });
  };

  public dismissDialog = () => {
    this.setState({ openDialog: undefined, selectedTemplate: undefined });
  };

  public openDeleteDialog = async (template: Template | TemplateWithData): Promise<void> => {
    this.setState({
      openDialog: 'delete',
      selectedTemplate: template,
    });
  };

  public onDeleteOk = async () => {
    const { selectedTemplate } = this.state;

    if (selectedTemplate === undefined) {
      return;
    }

    this.deleteTemplate(selectedTemplate);
    this.dismissDialog();
  };

  public deleteTemplate = async (template: Template): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const successCallback = () => {
      searchContentStore.emitRefresh();
    };

    return await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteTemplate(template),
      EslManagerPrivateRoute.TEMPLATE,
      HttpMethod.DELETE,
      this.successStatusCodes,
      successCallback,
    );
  };

  public getOpenDialog = (): JSX.Element | undefined => {
    const { classes } = this.props;
    const { openDialog, selectedTemplate } = this.state;

    if (selectedTemplate === undefined) {
      return;
    }

    switch (openDialog) {
      case 'delete': {
        const deleteDialogText = (
          <React.Fragment>
            <div>
              name: <span className={classes.boldFont}>{selectedTemplate.name}</span>
            </div>
            <div>
              type: <span className={classes.boldFont}>{selectedTemplate.type}</span>
            </div>
          </React.Fragment>
        );
        return (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={true}
            title={'Delete Template'}
            text={deleteDialogText}
            onClose={this.dismissDialog}
            onConfirm={this.onDeleteOk}
          />
        );
      }
      case 'preview':
        return (
          <TemplatePreviewDialog
            template={selectedTemplate as TemplateWithData}
            fetchRendererResult={this.fetchRendererResult}
            onClose={this.dismissDialog}
            dialogProps={{
              open: true,
              fullWidth: true,
              maxWidth: 'lg',
            }}
          />
        );
      default:
        return;
    }
  };

  public render() {
    const { templateStore } = this.stores;
    const { isPanelOpen } = this.state;
    const { classes } = this.props;

    const panel = isPanelOpen ? (
      <TemplatePanel
        saveHandler={this.saveTemplate}
        closeCallback={templateStore.resetStore}
        fetchRendererResult={this.fetchRendererResult}
        getApplicableRenderers={this.getApplicableRenderers}
        closeHandler={() => this.setState({ isPanelOpen: false })}
      />
    ) : (
      undefined
    );

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as TemplateManagementContentPropsWithStores, {
        preview: this.onPreview,
        delete: this.openDeleteDialog,
        download: this.onDownload,
        edit: this.onEdit,
      }),
    );

    return (
      <>
        <ContentControl
          panel={panel}
          dialog={this.getOpenDialog()}
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen })}
              tooltip={'Add Template'}
              requiredPermission={Permissions.TEMPLATES_WRITE}
            />,
          ]}
        />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            fetchItems={this.fetchTemplates}
            columns={columnDefinition}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<TemplateManagementContentProps, typeof TemplateManagementContentComponent>(
  TemplateManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const TemplateManagementContent = StyleWrapped;
