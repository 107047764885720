import { EslManagerPublicRouteV1, HttpMethod, Pagination, PaginationResponse, EventRule } from '@ekkogmbh/apisdk';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { EventRuleStore } from '../Stores/EventRuleStore';
import { RouteComponentProps, withRouter } from 'react-router';
import { inject } from 'mobx-react';
import { Component } from 'react';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { request } from 'src/Common/Helper/FetchHandler';
import { enqueueSnackbar } from 'notistack';
import { WithStyles, withStyles } from '@mui/styles';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { materialDatatableColumnDefinitions } from './EventRuleDatatableColumnDefinitions';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { Paper } from '@mui/material';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import classNames from 'classnames';
import { Add } from '@mui/icons-material';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { EventRuleManagementStyles } from '../Styles/EventRuleManagementStyles';
import { EventRulePanel } from './EventRulePanel';
import { EventTriggerStore } from '../Stores/EventTriggerStore';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

export interface EventRuleManagementContentActionHandlers {
  edit: (eventRule: EventRule) => void;
  delete: (eventRule: EventRule) => void;
}

export interface EventRuleManagementContentState {
  selectedEventRule?: EventRule;
  isDialogOpen: boolean;
  isPanelOpen: boolean;
}

interface EventRuleManagementContentStores {
  api: ApiStore;
  eventRuleStore: EventRuleStore;
  eventTriggerStore: EventTriggerStore;
  navigationStore: NavigationStore;
  searchContentStore: SearchContentStore;
}

interface EventRuleManagementContentProps extends WithStyles<typeof EventRuleManagementStyles>, RouteComponentProps {}

export type EventRuleManagementContentPropsWithStores = EventRuleManagementContentProps &
  EventRuleManagementContentStores;

@inject('api', 'eventRuleStore', 'eventTriggerStore', 'navigationStore', 'searchContentStore')
class EventRuleManagementContentComponent extends Component<
  EventRuleManagementContentProps,
  EventRuleManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<EventRule> = ['name', 'coordinate'];
  private readonly sortFieldMaps: DataTableSortFieldMap<EventRule> = { name: 'entry.name' };

  private fetchPromises: CancelableFetchPromises = {};

  public state: EventRuleManagementContentState = {
    isPanelOpen: false,
    isDialogOpen: false,
  };

  get stores(): EventRuleManagementContentStores {
    return this.props as EventRuleManagementContentProps & EventRuleManagementContentStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetch = async (pagination: Pagination): Promise<PaginationResponse<EventRule>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<EventRule>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getEventRules(pagination),
      EslManagerPublicRouteV1.EVENT_RULES,
      HttpMethod.GET,
    );
  };

  public onEdit = async (eventRule: EventRule): Promise<void> => {
    const { navigationStore, eventRuleStore } = this.stores;
    eventRuleStore.resetStore(eventRule);
    this.setState(
      {
        isPanelOpen: true,
        selectedEventRule: eventRule,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public onSave = async (eventRule: EventRule, overwrite: boolean): Promise<void> => {
    const { api, navigationStore, searchContentStore } = this.stores;
    const { name, coordinate, trigger, action } = eventRule;

    await request<EventRule>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveEventRule({ name: name, coordinate: coordinate }, { trigger: trigger, action: action }, overwrite),
      EslManagerPublicRouteV1.EVENT_RULE,
      HttpMethod.PUT,
      { 200: 'Event Rule saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();
  };

  public onDelete = async (eventRule: EventRule): Promise<void> => {
    this.setState({
      isDialogOpen: true,
      isPanelOpen: false,
      selectedEventRule: eventRule,
    });
  };

  public onDismiss = () => {
    this.setState({
      isPanelOpen: false,
      isDialogOpen: false,
      selectedEventRule: undefined,
    });
  };

  public onDeleteConfirm = async () => {
    const { selectedEventRule } = this.state;
    const { api, searchContentStore } = this.stores;

    if (selectedEventRule === undefined) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteEventRule(selectedEventRule),
      EslManagerPublicRouteV1.EVENT_RULE,
      HttpMethod.DELETE,
      { 204: 'Event Rule deleted.' },
    );
    this.onDismiss();
    searchContentStore.emitRefresh();
  };

  public createPanel = (): JSX.Element => <EventRulePanel closeHandler={this.onDismiss} saveHandler={this.onSave} />;

  public render() {
    const { classes } = this.props;
    const { selectedEventRule, isDialogOpen, isPanelOpen } = this.state;
    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as EventRuleManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <>
        <ContentControl
          panel={isPanelOpen ? this.createPanel() : undefined}
          dialog={
            <GenericDialog
              title={'Delete Supply Profile'}
              text={selectedEventRule?.name ?? ''}
              type={'confirmation'}
              maxWidth={'sm'}
              onClose={this.onDismiss}
              onConfirm={this.onDeleteConfirm}
              fullWidth
              centered
              open={isDialogOpen}
            />
          }
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedEventRule: undefined })}
              tooltip={'Add Event Rule'}
              requiredPermission={Permissions.EVENT_RULES_WRITE}
            />,
          ]}
        />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetch}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMaps}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<EventRuleManagementContentProps, typeof EventRuleManagementContentComponent>(
  EventRuleManagementContentComponent,
);
const StyleWrapped = withStyles(EventRuleManagementStyles)(RouterWrapped);

export const EventRuleManagementContent = StyleWrapped;
