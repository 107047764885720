import { Fab, Tooltip } from '@mui/material';
import { withStyles, WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { inject } from 'mobx-react';

import { Component, ReactNode } from 'react';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { ContentControlStyles } from 'src/Common/Styles/ContentControlStyles';

const styles = ContentControlStyles;

interface ContentControlButtonStores {
  api: ApiStore;
}

interface ContentControlButtonProps extends WithStyles<typeof styles> {
  onClick: () => void;
  content: JSX.Element;
  tooltip: string;
  flipText?: string;
  requiredPermission?: Permissions;
}

interface ContentControlButtonState {}

@inject('api')
class ContentControlButtonComponent extends Component<ContentControlButtonProps, ContentControlButtonState> {
  get stores(): ContentControlButtonStores {
    return this.props as ContentControlButtonProps & ContentControlButtonStores;
  }

  public makeButton(): JSX.Element {
    const { flipText, content, onClick, classes } = this.props;

    const isFlipping = !!flipText;

    if (isFlipping) {
      return (
        <Fab color={'secondary'} className={classNames(classes.fab, classes.fabConditional)} onClick={onClick}>
          <div className={classes.flip}>
            <div className={classes.flipInner}>
              <div className={classes.flipMain}>{content}</div>
              <div className={classNames(classes.flipMain, classes.flipBack)}>{flipText ?? ''}</div>
            </div>
          </div>
        </Fab>
      );
    }

    return (
      <Fab color={'primary'} className={classes.fab} onClick={onClick}>
        {content}
      </Fab>
    );
  }

  public hasPermission(): boolean {
    const { api } = this.stores;
    const { requiredPermission } = this.props;

    if (requiredPermission === undefined) {
      return true;
    }

    return api.userHasPermissionOnAnyNode(requiredPermission);
  }

  public render(): ReactNode {
    const { tooltip } = this.props;

    if (!this.hasPermission()) {
      return <></>;
    }

    return (
      <Tooltip title={tooltip} aria-label={tooltip} placement={'bottom'} enterDelay={500} leaveDelay={200}>
        {this.makeButton()}
      </Tooltip>
    );
  }
}

const StyleWrapped = withStyles(styles)(ContentControlButtonComponent);

/**
 * Built upon "Fab" - Floating Action Button
 */
export const ContentControlButton = StyleWrapped;
