import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, InputAdornment, Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography/Typography';
import { injectFakePagination } from '../../Common/Helper/Pagination';
import { BlueprintManagementStyles } from '../Styles/BlueprintManagementStyles';
import { RouteComponentProps, withRouter } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { inject } from 'mobx-react';
import { Add, KeyboardArrowLeft } from '@mui/icons-material';
import { BlueprintPanel } from './BlueprintPanel';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import {
  Blueprint,
  BlueprintValue,
  EslManagerPublicRouteV2,
  HttpMethod,
  Pagination,
  PaginationResponse,
  Zone,
} from '@ekkogmbh/apisdk';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { materialDatatableColumnDefinitions } from './BlueprintColumnDefinitions';
import { request } from 'src/Common/Helper/FetchHandler';
import { BlueprintStore } from '../Stores/BlueprintStore';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

const styles = BlueprintManagementStyles;

const stores = ['api', 'searchContentStore', 'navigationStore', 'blueprintStore'];

export interface BlueprintManagementContentActionHandlers {
  delete: (blueprint: Blueprint) => void;
  showSteps: (blueprint: Blueprint) => void;
  edit: (blueprint: Blueprint) => void;
}

export interface BlueprintManagementContentStores {
  api: ApiStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  blueprintStore: BlueprintStore;
}

export interface BlueprintManagementContentState {
  currentZoneIdentifier: Pick<Zone, 'name' | 'coordinate'>;
  isPanelOpen: boolean;
  openDialog?: 'steps' | 'delete';
  selectedBlueprint?: Blueprint;
}

export interface BlueprintManagementContentParams {
  zoneName: string;
  coordinate: string;
}

export interface BlueprintManagementContentProps
  extends WithStyles<typeof styles>,
    RouteComponentProps<BlueprintManagementContentParams> {}

export type BlueprintManagementContentPropsWithStores = BlueprintManagementContentProps &
  BlueprintManagementContentStores;

@inject(...stores)
class BlueprintManagementContentComponent extends Component<
  BlueprintManagementContentProps,
  BlueprintManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<Blueprint> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<Blueprint> = { name: 'B.name' };

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): BlueprintManagementContentStores {
    return this.props as BlueprintManagementContentPropsWithStores;
  }

  public state: BlueprintManagementContentState = {
    currentZoneIdentifier: {
      name: '',
      coordinate: '',
    },
    isPanelOpen: false,
  };

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public static getDerivedStateFromProps(
    props: Readonly<BlueprintManagementContentProps>,
    state: BlueprintManagementContentState,
  ): Partial<BlueprintManagementContentState> | null {
    const currentZoneIdentifier = {
      name: props.match.params.zoneName,
      coordinate: props.match.params.coordinate,
    };

    if (currentZoneIdentifier !== state.currentZoneIdentifier) {
      return {
        currentZoneIdentifier,
      };
    }

    return null;
  }

  private fetchBlueprints = async (_: Pagination): Promise<PaginationResponse<Blueprint>> => {
    const { api } = this.stores;
    const {
      currentZoneIdentifier: { name, coordinate },
    } = this.state;

    if (!name || !coordinate) {
      return { currentPage: 0, items: [], pageCount: 0, itemsPerPage: 0, totalItemCount: 0 };
    }

    const blueprints = await request<Blueprint[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getBlueprints(coordinate, name),
      EslManagerPublicRouteV2.BLUEPRINTS,
      HttpMethod.GET,
    );

    return injectFakePagination<Blueprint>(blueprints);
  };

  private onSave = async (): Promise<void> => {
    const { api, navigationStore, searchContentStore, blueprintStore } = this.stores;
    const { editableBlueprint } = blueprintStore;

    await request<Blueprint>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveBlueprint(blueprintStore.getSavableBlueprint(), editableBlueprint !== undefined),
      EslManagerPublicRouteV2.BLUEPRINT,
      HttpMethod.PUT,
      { 200: 'Blueprint saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();
  };

  public onDismiss = () => {
    this.setState({
      isPanelOpen: false,
      selectedBlueprint: undefined,
      openDialog: undefined,
    });
  };

  private onEdit = (blueprint: Blueprint) => {
    const { navigationStore, blueprintStore } = this.stores;

    blueprintStore.setEditableBlueprint(blueprint);

    this.setState(
      {
        isPanelOpen: true,
        selectedBlueprint: blueprint,
      },
      () => navigationStore.scrollTop(),
    );
  };

  private onDeleteConfirm = async () => {
    const { selectedBlueprint } = this.state;
    const { api, searchContentStore } = this.stores;

    if (selectedBlueprint === undefined) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteBlueprint(selectedBlueprint),
      EslManagerPublicRouteV2.BLUEPRINT,
      HttpMethod.DELETE,
      { 200: 'Blueprint deleted.' },
    );

    this.onDismiss();
    searchContentStore.emitRefresh();
  };

  public getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen, currentZoneIdentifier } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return (
      <BlueprintPanel saveHandler={this.onSave} closeHandler={this.onDismiss} zoneIdentifier={currentZoneIdentifier} />
    );
  };

  private showSteps = (blueprint: Blueprint) => {
    this.setState({
      selectedBlueprint: blueprint,
      openDialog: 'steps',
      isPanelOpen: false,
    });
  };

  public getOpenDialog = (): JSX.Element | undefined => {
    const { openDialog, selectedBlueprint } = this.state;

    if (selectedBlueprint === undefined) {
      return;
    }

    switch (openDialog) {
      case 'delete':
        return (
          <GenericDialog
            open
            fullWidth
            centered
            type={'confirmation'}
            maxWidth={'sm'}
            title={'Delete Blueprint'}
            text={selectedBlueprint.name}
            onClose={this.onDismiss}
            onConfirm={this.onDeleteConfirm}
          />
        );
      case 'steps': {
        const stepsTextFieldStyle = {
          color: '#555',
          borderColor: '#555',
        };
        return (
          <Dialog open onClose={this.onDismiss}>
            <DialogTitle>Blueprint Steps - {selectedBlueprint.name}</DialogTitle>
            <DialogContent>
              <Grid container spacing={0}>
                {selectedBlueprint.steps.map((step, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    style={{ borderColor: '#eee', borderStyle: 'solid', borderRadius: 8, margin: 12, padding: 12 }}
                  >
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <Typography variant={'h6'}>Source</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {step.source.map((source: BlueprintValue, sourceIndex) => (
                            <Grid item xs={4} key={sourceIndex}>
                              <TextField
                                inputProps={{ style: stepsTextFieldStyle }}
                                label={''}
                                value={source.value}
                                name={''}
                                disabled={true}
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position={'start'}>
                                      <TextField
                                        variant="standard"
                                        style={{ maxWidth: '70px' }}
                                        type={'text'}
                                        label={''}
                                        value={source.amount}
                                        disabled={true}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant={'h6'}>Destination</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {step.destination.map((destination: BlueprintValue, destinationIndex) => (
                            <Grid item xs={4} key={destinationIndex}>
                              <TextField
                                inputProps={{ style: stepsTextFieldStyle }}
                                label={''}
                                value={destination.value}
                                name={''}
                                disabled={true}
                                variant="outlined"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position={'start'}>
                                      <TextField
                                        variant="standard"
                                        style={{ maxWidth: '70px' }}
                                        type={'text'}
                                        label={''}
                                        value={destination.amount}
                                        disabled={true}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onDismiss} color="primary" variant={'contained'}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        );
      }
      default:
        return;
    }
  };

  public goBack = () => {
    const { history } = this.props;

    history.replace('/picking/zones');
  };

  public render() {
    const { isPanelOpen } = this.state;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as BlueprintManagementContentPropsWithStores, {
        showSteps: this.showSteps,
        delete: (blueprint) => this.setState({ selectedBlueprint: blueprint, openDialog: 'delete' }),
        edit: (blueprint) => this.onEdit(blueprint),
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={this.getOpenDialog()}
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              tooltip={'Add Blueprint'}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedBlueprint: undefined })}
              requiredPermission={Permissions.BLUEPRINTS_WRITE}
            />,
          ]}
        />

        <Paper>
          <Button size={'small'} style={{ borderRadius: 0 }} variant="text" color="secondary" onClick={this.goBack}>
            <KeyboardArrowLeft /> GO Back
          </Button>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchBlueprints}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<BlueprintManagementContentProps, typeof BlueprintManagementContentComponent>(
  BlueprintManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const BlueprintManagementContent = StyleWrapped;
