import {
  ApplicableRenderersPayload,
  ApplicableRenderersResult,
  RendererPayload,
  RendererResult,
  Template,
  TemplateSavePayload,
} from '@ekkogmbh/apisdk';
import { Grid } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { ConfigStore } from 'src/Common/Stores/ConfigStore';
import { CheckmarkSpinner } from '../../Common/Components/CheckmarkSpinner';
import { FormPanelButtons } from '../../Common/Components/FormPanelButtons';
import { DynamicStepper } from '../../Common/Components/Stepper/DynamicStepper';
import { ApiStore } from '../../Common/Stores/ApiStore';
import { TemplateStore } from '../Stores/TemplateStore';
import { TemplateCreateOverview } from './TemplateCreateOverview';
import { TemplateDropzone } from './TemplateDropzone';
import { TemplateFileStep } from './TemplateFileStep';
import { TemplateForm } from './TemplateForm';
import { TemplatePreview } from './TemplatePreview';

const stores = ['api', 'templateStore', 'configStore'];

interface TemplatePanelStores {
  api: ApiStore;
  templateStore: TemplateStore;
  configStore: ConfigStore;
}

export interface TemplatePanelState {
  activeStep: number;
  finished: boolean;
}

export interface TemplatePanelProps {
  closeHandler: () => void;
  saveHandler: (coordinate: string, name: string, template: TemplateSavePayload) => Promise<Template>;
  closeCallback?: () => void;
  fetchRendererResult: (payload: RendererPayload) => Promise<RendererResult>;
  getApplicableRenderers: (payload: ApplicableRenderersPayload) => Promise<ApplicableRenderersResult>;
}

@inject(...stores)
@observer
class TemplatePanelComponent extends React.Component<TemplatePanelProps, TemplatePanelState> {
  public state: TemplatePanelState = {
    activeStep: 0,
    finished: false,
  };
  private closeTimeout?: number;

  get stores(): TemplatePanelStores {
    return this.props as TemplatePanelProps & TemplatePanelStores;
  }

  public componentWillUnmount(): void {
    const { templateStore } = this.stores;
    templateStore.resetState();
  }

  public handleReset = async (): Promise<void> => {
    const { templateStore } = this.stores;
    templateStore.resetStore(true);
    this.setState({ activeStep: 0 });
  };

  public handleSave = async (): Promise<void> => {
    const { templateStore } = this.stores;
    const { allFilled, name, data, type, coordinate } = templateStore.state;

    if (!allFilled) {
      return;
    }

    const steps = this.getSteps();

    this.setState(
      {
        activeStep: steps.length,
      },
      () => {
        const { saveHandler } = this.props;

        const payload = {
          type,
          data,
        } as TemplateSavePayload;

        try {
          saveHandler(coordinate, name, payload);
        } catch (e) {
          this.setState({ activeStep: steps.length - 1 });
          return;
        }
      },
    );

    this.setState({ finished: true });

    this.closeTimeout = window.setTimeout(this.closeMountAware, 1250);
  };

  public handleNext = async (): Promise<void> => {
    const { activeStep } = this.state;

    const steps = this.getSteps();
    const nextStep = activeStep + 1 > steps.length ? steps.length : activeStep + 1;

    this.setState({
      activeStep: nextStep,
    });
  };

  public handleBack = async (): Promise<void> => {
    const { activeStep } = this.state;

    const lastStep = activeStep - 1 < 0 ? 0 : activeStep - 1;

    this.setState({
      activeStep: lastStep,
    });
  };

  public closeMountAware = (): void => {
    const { closeHandler, closeCallback } = this.props;

    const close =
      closeCallback !== undefined
        ? () => {
            closeHandler();
            closeCallback();
          }
        : closeHandler;

    window.clearTimeout(this.closeTimeout);
    close();
  };

  public getSteps = (): { title: string; elementCallback: () => React.JSX.Element }[] => [
    {
      title: 'File',
      elementCallback: (): React.JSX.Element => {
        const { fetchRendererResult, getApplicableRenderers } = this.props;
        const { templateStore } = this.stores;
        const { templateFile } = templateStore;

        return (
          <Grid container spacing={2}>
            {!templateFile && (
              <Grid item xs={12}>
                <TemplateDropzone
                  fetchRendererResult={fetchRendererResult}
                  getApplicableRenderers={getApplicableRenderers}
                />
              </Grid>
            )}
            {templateFile && (
              <>
                <Grid item xs={12}>
                  <TemplateFileStep />
                </Grid>
                {templateStore.rendererResult && (
                  <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent={'center'}>
                      <Grid item xs={'auto'}>
                        <TemplatePreview
                          rendererResult={templateStore.rendererResult!}
                          extendOnMount={templateStore.rendererResult!.errors.length > 0}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        );
      },
    },
    {
      title: 'Parameters',
      elementCallback: (): React.JSX.Element => <TemplateForm />,
    },
    {
      title: 'Overview',
      elementCallback: (): React.JSX.Element => <TemplateCreateOverview />,
    },
  ];

  public render(): React.JSX.Element {
    const { templateStore } = this.stores;
    const { changed } = templateStore;
    const { allFilled, data } = templateStore.state;
    const { activeStep, finished } = this.state;

    const steps = this.getSteps();

    const close = this.closeMountAware;

    const backHandler = this.handleBack;
    const nextHandler = activeStep === steps.length - 1 ? undefined : this.handleNext;
    const saveHandler = activeStep !== steps.length - 1 ? undefined : this.handleSave;
    const isBackDisabled = activeStep === 0 || activeStep === steps.length;
    const isNextDisabled =
      (activeStep === 1 && !allFilled) ||
      (activeStep === 0 && data === '') ||
      activeStep === steps.length ||
      templateStore.rendererResult === undefined ||
      templateStore.rendererResult.errors.length > 0;

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <Grid item xs={12}>
          <div style={{ display: activeStep === steps.length ? 'block' : 'none' }}>
            <Grid container spacing={2} alignItems={'stretch'}>
              <Grid
                item
                xs={12}
                style={{
                  height: 496,
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    top: '50%',
                    marginTop: -48,
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  <CheckmarkSpinner complete={finished} failure={false} />
                </div>
              </Grid>
            </Grid>
          </div>

          <DynamicStepper activeStep={activeStep} steps={steps} />
        </Grid>

        <FormPanelButtons
          cancelHandler={close}
          finished={finished}
          resetHandler={this.handleReset}
          backHandler={backHandler}
          nextHandler={nextHandler}
          saveHandler={saveHandler}
          deleteHandler={undefined}
          isBackDisabled={isBackDisabled}
          isNextDisabled={isNextDisabled}
          isResetDisabled={!changed || activeStep === steps.length}
          isSaveDisabled={!changed || !allFilled}
          isDeleteDisabled={true}
          isDeleteHidden={true}
        />
      </Grid>
    );
  }
}

export const TemplatePanel = TemplatePanelComponent;
