import { ApiSdkEvents, HttpApiSdk, TaskCollection, TaskState } from '@ekkogmbh/apisdk';
import EventEmitter from 'eventemitter3';
import { action, observable } from 'mobx';

export type TaskCollectionProgressCallback = (taskCollectionId: string, state: string, progress?: number) => void;

interface TaskCollectionBundle {
  taskCollection: TaskCollection;
  pollPromise?: Promise<TaskCollection>;
  pollInterval?: NodeJS.Timeout;
  progressCallback?: TaskCollectionProgressCallback;
}

export class TaskCollectionStore {
  @observable
  public taskCollections: TaskCollection[] = [];

  private taskCollectionBundles: Record<string, TaskCollectionBundle> = {};
  private readonly removeItemDelay: number = 5;
  private readonly pollRate: number = 2;

  constructor(private readonly api: HttpApiSdk & EventEmitter) {}

  public getApiEvent(taskState: TaskState): ApiSdkEvents {
    switch (taskState) {
      case TaskState.CREATED:
        return ApiSdkEvents.TASK_COLLECTION_CREATED;
      case TaskState.FINISHED:
        return ApiSdkEvents.TASK_COLLECTION_FINISHED;
      case TaskState.FAILED:
        return ApiSdkEvents.TASK_COLLECTION_FAILED;
      case TaskState.RUNNING:
        return ApiSdkEvents.TASK_COLLECTION_PROGRESS;
      default:
        return ApiSdkEvents.TASK_COLLECTION_ERROR;
    }
  }

  /**
   * Entry point for task collections coming in via http header.
   */
  public async processTaskCollection(
    taskCollectionHeaderValue: string | null,
    progressCallback?: TaskCollectionProgressCallback,
  ): Promise<void> {
    if (taskCollectionHeaderValue === null) {
      if (progressCallback) {
        progressCallback('', 'no task-collection created', 100);
      }

      return;
    }

    const taskCollectionUri: string = taskCollectionHeaderValue + '';
    const parts = taskCollectionUri.split('/');
    const taskCollectionId = parseInt(parts[parts.length - 1], 10);

    if (!taskCollectionId) {
      if (progressCallback) {
        progressCallback('', 'not found', 100);
      }

      return;
    }

    const taskCollection: TaskCollection = await this.api.getTaskCollectionProgress(taskCollectionId);

    if (!taskCollection) {
      if (progressCallback) {
        progressCallback('', 'not found', 100);
      }

      return;
    }

    this.handleTaskCollection(taskCollection, progressCallback);
  }

  /**
   * Handle task collection via task collection object
   */
  public handleTaskCollection(taskCollection: TaskCollection, progressCallback?: TaskCollectionProgressCallback): void {
    let bundle: TaskCollectionBundle | null = this.taskCollectionBundles[taskCollection.id] ?? null;
    if (bundle === null) {
      // register new task collection for polling
      bundle = {
        taskCollection,
        progressCallback,
        pollInterval: setInterval(() => this.pollTaskCollection(taskCollection.id), this.pollRate * 1000),
      };
      this.setTaskCollectionBundle(bundle);
    }

    // trigger side-effects
    if (progressCallback) {
      progressCallback(taskCollection.id, taskCollection.state, taskCollection.progress);
    }

    // remove finished collections from polling
    if ([TaskState.FINISHED, TaskState.FAILED].includes(taskCollection.state)) {
      this.removeTaskCollectionBundle(bundle);
    }

    // refresh the public list
    this.updateTaskCollectionList();

    // emit event for apisdk listeners
    this.api.emit(this.getApiEvent(taskCollection.state), taskCollection);
  }

  private setTaskCollectionBundle(bundle: TaskCollectionBundle): void {
    this.taskCollectionBundles[bundle.taskCollection.id] = bundle;
  }

  private removeTaskCollectionBundle(bundle: TaskCollectionBundle, immediate: boolean = false): void {
    // stop polling
    if (bundle.pollInterval !== undefined) {
      clearInterval(bundle.pollInterval);
    }

    // remove item with a delay to allow anims etc.
    setTimeout(() => {
      delete this.taskCollectionBundles[bundle.taskCollection.id];
      this.updateTaskCollectionList();
    }, this.removeItemDelay * (immediate ? 0 : 1000));
  }

  @action
  private updateTaskCollectionList(): void {
    this.taskCollections = Object.values(this.taskCollectionBundles).map((tcb) => tcb.taskCollection);
  }

  /**
   * fetches the task collection progress and updates the task collection
   * within the bundle.
   */
  private async pollTaskCollection(taskCollectionId: string) {
    const bundle = this.taskCollectionBundles[taskCollectionId] ?? null;
    if (bundle === null || bundle.pollPromise !== undefined) {
      return;
    }

    bundle.pollPromise = this.api.getTaskCollectionProgress(parseInt(taskCollectionId, 10));
    this.setTaskCollectionBundle(bundle);

    try {
      bundle.taskCollection = await bundle.pollPromise;
      bundle.pollPromise = undefined;
      this.setTaskCollectionBundle(bundle);
      this.handleTaskCollection(bundle.taskCollection, bundle.progressCallback);
    } catch (e) {
      this.api.emit(ApiSdkEvents.TASK_COLLECTION_ERROR, bundle.taskCollection);
    }
  }

  /**
   * External entry point for immediately removing a collection from polling.
   */
  public removeTaskCollectionPolling(taskCollectionId: string): void {
    const bundle = this.taskCollectionBundles[taskCollectionId] ?? null;

    if (bundle === null) {
      return;
    }

    this.removeTaskCollectionBundle(bundle, true);
  }
}
