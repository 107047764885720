import {
  CompartmentSavePayload,
  CompartmentView,
  EslManagerPrivateRoute,
  EslManagerPublicRouteV1,
  HttpMethod,
  LinkSavePayload,
  LinkView,
  Pagination,
  PaginationResponse,
  UserPermission,
} from '@ekkogmbh/apisdk';
import { Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add, AllInbox, CloudUpload, Delete, Replay, SaveAlt } from '@mui/icons-material';
import LinkIcon from '@mui/icons-material/Link';
import classNames from 'classnames';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { inject, observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { DataTable } from '../../Common/Components/DataTable';
import { LoadingMask } from '../../Common/Components/LoadingMask';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises } from '../../Common/Helper/PromiseHelper';
import { SuccessHandlerStatusMessages } from '../../Common/Helper/ResponseHandler';
import { ConfigStore } from '../../Common/Stores/ConfigStore';
import { TaskCollectionProgressCallback, TaskCollectionStore } from '../../Common/Stores/TaskCollectionStore';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { DataTableStore } from '../../Common/Stores/DataTableStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { PaginationStore } from '../../Common/Stores/PaginationStore';
import { RowSelectStore } from '../../Common/Stores/RowSelectStore';
import { SearchContentStore, ST_PLACEHOLDER } from '../../Common/Stores/SearchContentStore';
import { LinkPanel } from '../../LabelManagement/Components/LinkPanel';
import { CompartmentStyles } from '../Styles/CompartmentStyles';
import { materialDatatableColumnDefinitions } from './CompartmentDatatableColumnDefinitions';
import { CompartmentPanel } from './CompartmentPanel';
import { ExportPanel } from './ExportPanel';
import { ImportPanel } from './ImportPanel';
import { LinkStore } from '../Stores/LinkStore';
import React from 'react';
import { SelectedCompartmentsDialog } from './SelectedCompartmentsDialog';
import { CompartmentStore } from '../Stores/CompartmentStore';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

const styles = CompartmentStyles;

export enum ProcessingType {
  LINK = 'link',
  COMPARTMENT = 'compartment',
}

interface ProcessingEntity {
  id?: number | string;
  type: ProcessingType;
}

const stores = [
  'api',
  'navigationStore',
  'paginationStore',
  'searchContentStore',
  'rowSelectStore',
  'dataTableStore',
  'taskCollectionStore',
  'configStore',
  'linkStore',
  'compartmentStore',
];
export interface CompartmentManagementContentHelpers {
  updateLink: (compartment: CompartmentView, linkIndex?: number) => void;
  isProcessing: (entity: ProcessingEntity) => boolean;
  deleteLinkDialog: (link: LinkView) => Promise<void>;
}

export interface CompartmentManagementContentActionHandlers {
  edit: (compartment: CompartmentView) => void;
  blink: (compartment: CompartmentView) => void;
  link: (compartment: CompartmentView) => void;
  delete: (compartment: CompartmentView) => void;
  details: (compartment: CompartmentView) => void;
}

export interface CompartmentManagementContentState {
  processing: ProcessingEntity[];
  loading: boolean;
  openDialog?: 'delete' | 'bulkDelete' | 'deleteLink' | 'sync' | 'blink';
  openPanel?: 'link' | 'compartment' | 'import' | 'export';
}

interface CompartmentManagementContentStores {
  api: ApiStore;
  navigationStore: NavigationStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  rowSelectStore: RowSelectStore;
  dataTableStore: DataTableStore;
  taskCollectionStore: TaskCollectionStore;
  configStore: ConfigStore;
  linkStore: LinkStore;
  compartmentStore: CompartmentStore;
}

interface CompartmentManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type CompartmentManagementContentPropsWithStores = CompartmentManagementContentProps &
  CompartmentManagementContentStores;

@inject(...stores)
@observer
class CompartmentManagementContentComponent extends Component<
  CompartmentManagementContentProps,
  CompartmentManagementContentState
> {
  public state: CompartmentManagementContentState = {
    processing: [],
    loading: false,
  };

  private fetchPromises: CancelableFetchPromises = {};
  private readonly successTaskCollectionStatusCodes: SuccessHandlerStatusMessages = {
    200: 'TaskCollection created.',
  };
  private readonly successBlinkStatusCodes: SuccessHandlerStatusMessages = {
    200: 'Blink by Coordinate successful.',
  };

  private taskCollectionHandlerFn = (progressCallback?: TaskCollectionProgressCallback) => (
    taskCollectionUri: string | null,
  ) => {
    const { taskCollectionStore } = this.stores;
    taskCollectionStore.processTaskCollection(taskCollectionUri, progressCallback);
  };

  get stores(): CompartmentManagementContentStores {
    return this.props as CompartmentManagementContentPropsWithStores;
  }

  public async componentDidMount(): Promise<void> {
    const { searchContentStore } = this.stores;

    searchContentStore.setSearchBarDisabled();

    searchContentStore.setSearchTextFieldOverride({
      field: 'fields',
      pattern: ST_PLACEHOLDER,
    });

    searchContentStore.setSearchBarDisabled(false);
  }

  public isProcessing = (entity: ProcessingEntity) => {
    const { processing } = this.state;

    if (processing.length < 1) {
      return false;
    }

    const filtered = processing.filter(
      (processingEntity: ProcessingEntity) =>
        processingEntity.id === entity.id && processingEntity.type === entity.type,
    );

    return filtered.length > 0;
  };

  public saveLinkHandler = async (link: LinkSavePayload): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const progressCallback = (taskCollectionId: string, __: string, progress?: number) => {
      if (progress === 100) {
        console.log('Link Change. Tasks Finished. Collection ID: ' + taskCollectionId);
      }
    };

    await request<LinkView>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveLink(link, this.taskCollectionHandlerFn(progressCallback)),
      EslManagerPublicRouteV1.LINK,
      HttpMethod.PUT,
      { 200: 'Link saved.' },
      () => searchContentStore.emitRefresh(),
    );
  };

  public saveCompartmentHandler = async (compartment: CompartmentSavePayload): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const progressCallback = (taskCollectionId: string, __: string, progress?: number) => {
      if (progress === 100) {
        console.log('Compartment Change. Tasks Finished. Collection ID: ' + taskCollectionId);
      }
    };

    await request<CompartmentView>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveCompartment(compartment, this.taskCollectionHandlerFn(progressCallback)),
      EslManagerPublicRouteV1.COMPARTMENT,
      HttpMethod.PUT,
      { 200: 'Compartment saved.' },
      () => searchContentStore.emitRefresh(),
    );
  };

  public deleteCompartmentHandler = async (
    compartment: CompartmentView,
    _?: TaskCollectionProgressCallback,
  ): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const progressCallback = (taskCollectionId: string, __: string, progress?: number) => {
      if (progress === 100) {
        console.log('Delete Compartment. Tasks Finished. Collection ID: ' + taskCollectionId);
      }
    };

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteCompartment(compartment, this.taskCollectionHandlerFn(progressCallback)),
      EslManagerPublicRouteV1.COMPARTMENT,
      HttpMethod.DELETE,
      this.successTaskCollectionStatusCodes,
      () => searchContentStore.emitRefresh(),
    );
  };

  public deleteLinkHandler = async (link: LinkView, _?: TaskCollectionProgressCallback): Promise<void> => {
    const { api, searchContentStore } = this.stores;

    const progressCallback = (taskCollectionId: string, __: string, progress?: number) => {
      if (progress === 100) {
        console.log('Delete Link. Tasks Finished. Collection ID: ' + taskCollectionId);
      }
    };

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteLink(link, this.taskCollectionHandlerFn(progressCallback)),
      EslManagerPrivateRoute.LINKS,
      HttpMethod.DELETE,
      this.successTaskCollectionStatusCodes,
    );
    searchContentStore.emitRefresh();
  };

  public onBlinkOk = () => {
    const { compartmentStore } = this.stores;
    const { editableCompartment } = compartmentStore;

    this.blinkByCoordinateHandler(editableCompartment!.coordinate);
  };

  public onDeleteOk = async () => {
    const { compartmentStore } = this.stores;
    const { editableCompartment } = compartmentStore;

    await this.deleteCompartmentHandler(editableCompartment!);
  };

  public onDeleteLinkOk = async () => {
    const { linkStore } = this.stores;

    await this.deleteLinkHandler(linkStore.editableLink!);
    linkStore.resetStore();
  };

  public blinkByCoordinateHandler = async (coordinate: CompartmentView['coordinate']): Promise<void> => {
    const { api } = this.stores;

    await request<LinkView[]>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.blinkByCoordinate(coordinate),
      EslManagerPublicRouteV1.BLINK_BY_COORDINATE,
      HttpMethod.POST,
      this.successBlinkStatusCodes,
    );
  };

  public importFinishedHandler = () => {
    const { searchContentStore } = this.stores;

    this.setState({ openPanel: undefined });
    searchContentStore.emitRefresh();
  };

  public fetchItems = (pagination: Pagination): Promise<PaginationResponse<CompartmentView>> => {
    const { api } = this.stores;

    return api.getCompartments(pagination);
  };

  public fetchPermissions = (): string[] => {
    const { api } = this.stores;

    const permissions = api.getUserPermissions();

    return permissions.map((permission: UserPermission): string => permission.permissionName);
  };

  public actionHandlerDetails = async (compartment: CompartmentView) => {
    const { history } = this.props;
    history.push('/compartments/' + compartment.coordinate);
  };

  public actionHandlerLink = async (compartment: CompartmentView, linkIndex?: number) => {
    const { linkStore } = this.stores;

    const editableLink = linkIndex !== undefined ? compartment.links[linkIndex] : undefined;
    if (editableLink) {
      linkStore.setEditableLink(editableLink);
    } else {
      linkStore.setTargetCompartment(compartment);
    }

    this.switchPanel('link');
  };

  public actionHandlerEdit = async (compartment: CompartmentView) => {
    const { compartmentStore } = this.stores;

    compartmentStore.resetWithCompartment(compartment);
    this.switchPanel('compartment');
  };

  public actionHandlerDelete = async (compartment: CompartmentView) => {
    const { compartmentStore } = this.stores;

    compartmentStore.resetWithCompartment(compartment);
    this.openDialog('delete');
  };

  public actionHandlerBlink = async (compartment: CompartmentView) => {
    const { compartmentStore } = this.stores;

    compartmentStore.resetWithCompartment(compartment);
    this.openDialog('blink');
  };

  public deleteLinkDialog = async (link: LinkView) => {
    const { linkStore } = this.stores;

    linkStore.setEditableLink(link);
    this.openDialog('deleteLink');
  };

  public getOpenDialog(): JSX.Element | undefined {
    const { compartmentStore } = this.stores;
    const { editableCompartment } = compartmentStore;
    const { openDialog } = this.state;

    const dismissDialog = () => {
      compartmentStore.resetWithCompartment();
      this.openDialog(undefined);
    };

    switch (openDialog) {
      case 'delete':
        const deleteDialogText = editableCompartment
          ? `Delete Compartment with Coordinate ${editableCompartment!.coordinate}?`
          : '';
        return (
          <GenericDialog
            open
            type="confirmation"
            title={'Delete Compartment'}
            text={deleteDialogText}
            onClose={dismissDialog}
            onConfirm={async () => {
              await this.onDeleteOk();
              dismissDialog();
            }}
            timedOkButton={true}
          />
        );
      case 'bulkDelete':
      case 'sync':
        const { rowSelectStore, searchContentStore } = this.stores;
        const variant = openDialog == 'sync' ? openDialog : 'delete';
        return (
          <SelectedCompartmentsDialog
            variant={variant}
            onDismiss={dismissDialog}
            onTasksFinished={() => {
              setTimeout(() => {
                rowSelectStore.reset();
                rowSelectStore.clear();
                if (variant === 'delete') {
                  searchContentStore.emitRefresh();
                }
                dismissDialog();
              }, 1500);
            }}
          />
        );
      case 'blink':
        const blinkDialogText = editableCompartment
          ? `Blink all Label linked to Coordinate ${editableCompartment!.coordinate}?`
          : '';
        return (
          <GenericDialog
            open
            type={'confirmation'}
            title={'Blink by Coordinate'}
            text={blinkDialogText}
            onClose={dismissDialog}
            onConfirm={() => {
              this.onBlinkOk();
              dismissDialog();
            }}
            timedOkButton={false}
          />
        );
      case 'deleteLink':
        const { linkStore } = this.stores;
        const { editableLink } = linkStore;

        if (editableLink === undefined) {
          return <></>;
        }

        const deleteLinkDialogText = (
          <React.Fragment>
            Coordinate: <span style={{ fontWeight: 'bold' }}>{editableLink.coordinate}</span>
            <br />
            LabelId: <span style={{ fontWeight: 'bold' }}>{editableLink.labelId}</span>
            <br />
            Template: <span style={{ fontWeight: 'bold' }}>{editableLink.template.name}</span>
            <br />
            Page: <span style={{ fontWeight: 'bold' }}>{editableLink.pageNumber}</span>
          </React.Fragment>
        );

        return (
          <GenericDialog
            open
            type="confirmation"
            title={'Delete Link'}
            text={deleteLinkDialogText}
            onClose={dismissDialog}
            onConfirm={async () => {
              await this.onDeleteLinkOk();
              linkStore.resetStore();
              dismissDialog();
            }}
            timedOkButton={true}
          />
        );
      default:
        return undefined;
    }
  }

  public getOpenPanel(): JSX.Element | undefined {
    const { openPanel } = this.state;

    const genericCloseHandler = () => {
      const { navigationStore } = this.stores;
      this.setState({ openPanel: undefined });
      navigationStore!.scrollTop();
    };

    switch (openPanel) {
      case 'compartment':
        return <CompartmentPanel closeHandler={genericCloseHandler} saveHandler={this.saveCompartmentHandler} />;
      case 'link':
        return (
          <LinkPanel
            closeHandler={genericCloseHandler}
            saveHandler={this.saveLinkHandler}
            deleteHandler={this.deleteLinkDialog}
          />
        );
      case 'export':
        return <ExportPanel closeHandler={genericCloseHandler} />;
      case 'import':
        return (
          <ImportPanel
            closeHandler={genericCloseHandler}
            tabs={[
              {
                description: 'Compartments',
                icon: AllInbox,
              },
              {
                description: 'Links',
                icon: LinkIcon,
                disableDiffModalOverride: true,
              },
            ]}
            importFinishedHandler={this.importFinishedHandler}
          />
        );
      default:
        return undefined;
    }
  }

  public switchPanel(panelName: typeof this.state['openPanel']): void {
    const { navigationStore } = this.stores;
    const { openPanel } = this.state;

    const isOther = panelName !== openPanel;

    if (isOther) {
      this.setState({ openPanel: panelName });
      if (panelName !== undefined) {
        navigationStore.scrollTop();
      }
    } else {
      this.setState({
        openPanel: undefined,
      });
    }
  }

  public openDialog(dialogName: typeof this.state['openDialog']): void {
    if (dialogName !== undefined) {
      this.switchPanel(undefined);
    }
    this.setState({ openDialog: dialogName });
  }

  public render() {
    const { rowSelectStore } = this.stores;
    const { classes } = this.props;
    const { loading } = this.state;

    const columnDefinitions: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(
        this.state,
        this.props as CompartmentManagementContentPropsWithStores,
        {
          details: this.actionHandlerDetails,
          link: this.actionHandlerLink,
          edit: this.actionHandlerEdit,
          blink: this.actionHandlerBlink,
          delete: this.actionHandlerDelete,
        },
        {
          updateLink: this.actionHandlerLink,
          isProcessing: this.isProcessing,
          deleteLinkDialog: this.deleteLinkDialog,
        },
      ),
    );

    const contentButtons = [
      <ContentControlButton
        key={'add'}
        content={<Add />}
        onClick={() => this.switchPanel('compartment')}
        tooltip={'Add Compartment'}
        requiredPermission={Permissions.SHELFLABELING_WRITE}
      />,
      <ContentControlButton
        key={'import'}
        content={<CloudUpload />}
        onClick={() => this.switchPanel('import')}
        tooltip={'Import'}
      />,
      <ContentControlButton
        key={'export'}
        content={<SaveAlt />}
        onClick={() => this.switchPanel('export')}
        tooltip={'Export'}
      />,
    ];

    if (rowSelectStore.selectedCount > 0) {
      contentButtons.push(
        <div>
          <ContentControlButton
            key={'sync'}
            content={<Replay />}
            onClick={() => this.openDialog('sync')}
            tooltip={'Synchronize Devices'}
            flipText={rowSelectStore.selectedCount.toString()}
          />
          <ContentControlButton
            key={'bulkDelete'}
            content={<Delete />}
            onClick={() => this.openDialog('bulkDelete')}
            tooltip={'Delete Compartments'}
            flipText={rowSelectStore.selectedCount.toString()}
          />
        </div>,
      );
    }

    return (
      <>
        <ContentControl dialog={this.getOpenDialog()} panel={this.getOpenPanel()} buttons={contentButtons} />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          {loading && <LoadingMask width={75} height={75} topPercent={10} />}
          <DataTable
            fetchItems={this.fetchItems}
            columns={columnDefinitions}
            filterFields={['identifier', 'label', 'template', 'fields']}
            sortFieldMap={{ coordinate: 'Node.materializedPath' }}
            options={{ sortOrder: { name: materialDatatableColumnDefinitions[1].name, direction: 'asc' } }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<CompartmentManagementContentProps, typeof CompartmentManagementContentComponent>(
  CompartmentManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const CompartmentManagementContent = StyleWrapped;
