import { withStyles, WithStyles } from '@mui/styles';
import { CompartmentStyles } from '../Styles/CompartmentStyles';
import { RowSelectStore } from 'src/Common/Stores/RowSelectStore';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { request } from 'src/Common/Helper/FetchHandler';
import { enqueueSnackbar } from 'notistack';
import { CancelableFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { TaskCollectionStore } from 'src/Common/Stores/TaskCollectionStore';
import { EslManagerPrivateRoute, HttpMethod, TaskCollectionHandler, TaskCollectionView } from '@ekkogmbh/apisdk';
import { Typography } from '@mui/material';

const styles = CompartmentStyles;

interface SelectedCompartmentsDialogStores {
  api: ApiStore;
  taskCollectionStore: TaskCollectionStore;
  rowSelectStore: RowSelectStore;
}
interface SelectedCompartmentsDialogProps extends WithStyles<typeof styles> {
  variant: 'delete' | 'sync';
  onDismiss: () => void;
  onTasksFinished: () => void;
}
interface SelectedCompartmentsDialogState {
  coordinates: string[];
  isSpinning: boolean;
  isSpinningDone: boolean;
}

@inject('api', 'taskCollectionStore', 'rowSelectStore')
@observer
class SelectedCompartmentsDialogComponent extends Component<
  SelectedCompartmentsDialogProps,
  SelectedCompartmentsDialogState
> {
  public state: SelectedCompartmentsDialogState = {
    coordinates: [],
    isSpinning: false,
    isSpinningDone: false,
  };
  get stores(): SelectedCompartmentsDialogStores {
    return this.props as SelectedCompartmentsDialogProps & SelectedCompartmentsDialogStores;
  }
  private fetchPromises: CancelableFetchPromises = {};

  public componentDidMount(): void {
    const { rowSelectStore } = this.stores;
    const coordinates = Object.keys(rowSelectStore.selectedRows).filter((item, coord) => item[coord]);
    this.setState({ coordinates });
  }

  public title(): string {
    const { variant } = this.props;

    switch (variant) {
      case 'sync':
        return 'Synchronize linked devices with Compartments';
      case 'delete':
      default:
        return 'Delete selected Compartments';
    }
  }

  public text(): JSX.Element {
    const { variant } = this.props;
    const { coordinates } = this.state;

    const identMax = 10;
    const identCount = coordinates.length;
    const single = coordinates.length === 1;

    let betterVariant: string;
    switch (variant) {
      case 'sync':
        betterVariant = 'Synchronize';
        break;
      default:
      case 'delete':
        betterVariant = 'Delete';
        break;
    }

    let identifiersString = coordinates.slice(0, identMax).join(', ');

    if (identCount > identMax) {
      identifiersString += '... (' + (identCount - identMax) + ' more)';
    }

    if (single) {
      return <Typography>{`${betterVariant} compartment with Coordinate ${identifiersString}?`}</Typography>;
    }

    return (
      <div>
        <div>
          {betterVariant} <span style={{ fontWeight: 700 }}>{identCount}</span> Compartments?
        </div>
        <div
          style={{
            fontSize: 12,
            marginTop: 18,
          }}
        >
          <span style={{ fontWeight: 700 }}>Coordinates:</span> {identifiersString}
        </div>
      </div>
    );
  }

  public apply = (): void => {
    const { api, taskCollectionStore } = this.stores;
    const { coordinates } = this.state;
    const { variant, onTasksFinished, onDismiss } = this.props;

    const progressCallback = (_: string, __: string, progress?: number) => {
      if (progress === 100) {
        onTasksFinished();
      }
    };

    const taskCollectionHandlerFn: TaskCollectionHandler = (taskCollectionUri) =>
      taskCollectionStore.processTaskCollection(taskCollectionUri, progressCallback);

    this.setState({ isSpinning: true }, async () => {
      switch (variant) {
        case 'sync':
          await request<TaskCollectionView>(
            api,
            enqueueSnackbar,
            this.fetchPromises,
            api.syncCompartmentLinks(coordinates, [], taskCollectionHandlerFn),
            EslManagerPrivateRoute.SYNC_COMPARTMENT_LINKS,
            HttpMethod.POST,
          );
          break;
        case 'delete':
        default:
          await request<void>(
            api,
            enqueueSnackbar,
            this.fetchPromises,
            api.bulkDeleteCompartments(coordinates, taskCollectionHandlerFn),
            EslManagerPrivateRoute.BULK_COMPARTMENT_DELETE,
            HttpMethod.POST,
          );
      }
      this.setState({ isSpinning: false }, onDismiss);
    });
  };

  public render() {
    const { onDismiss } = this.props;
    const { isSpinning, isSpinningDone } = this.state;

    return (
      <GenericDialog
        type={'confirmation'}
        open={true}
        title={this.title()}
        text={this.text()}
        onClose={onDismiss}
        onConfirm={this.apply}
        checkmarkSpinner={isSpinning}
        checkmarkSpinnerDone={isSpinningDone}
        timedOkButton={true}
        minHeight={180}
        minWidth={564}
      />
    );
  }
}

const StyleWrapped = withStyles(styles)(SelectedCompartmentsDialogComponent);

export const SelectedCompartmentsDialog = StyleWrapped;
