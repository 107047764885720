import {
  EslManagerPublicRouteV1,
  HttpMethod,
  Pagination,
  PaginationResponse,
  ReplenishmentPlan,
} from '@ekkogmbh/apisdk';
import { SaveReplenishmentPlanPayload } from '@ekkogmbh/apisdk/src/Sdk/Ekanban/EkanbanTypes';
import { Add } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import { inject } from 'mobx-react';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from '../../Common/Components/DataTable';
import { GenericDialog } from '../../Common/Components/GenericDialog';
import { request } from '../../Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from '../../Common/Helper/PromiseHelper';
import { ApiStore, Permissions } from '../../Common/Stores/ApiStore';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { PaginationStore } from '../../Common/Stores/PaginationStore';
import { SearchContentStore } from '../../Common/Stores/SearchContentStore';
import { ReplenishmentPlanStore } from '../Stores/ReplenishmentPlanStore';
import { ReplenishmentPlanManagementStyles } from '../Styles/ReplenishmentPlanManagementStyles';
import { materialDatatableColumnDefinitions } from './ReplenishmentPlanDatatableColumnDefinitions';
import { ReplenishmentPlanPanel } from './ReplenishmentPlanPanel';
import { EventTriggerStore } from 'src/EventRuleManagement/Stores/EventTriggerStore';
import { ContentControl } from 'src/Common/Components/ContentControl';
import { ContentControlButton } from 'src/Common/Components/ContentControl/ContentControlButton';

export interface ReplenishmentPlanManagementContentActionHandlers {
  edit: (replenishmentPlan: ReplenishmentPlan) => void;
  delete: (replenishmentPlan: ReplenishmentPlan) => void;
}

export interface ReplenishmentPlanManagementContentState {
  selectedPlan?: ReplenishmentPlan;
  isPanelOpen: boolean;
  isDialogOpen: boolean;
}

interface ReplenishmentPlanManagementContentStores {
  api: ApiStore;
  replenishmentPlanStore: ReplenishmentPlanStore;
  eventTriggerStore: EventTriggerStore;
  paginationStore: PaginationStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
}

const stores = [
  'api',
  'replenishmentPlanStore',
  'eventTriggerStore',
  'paginationStore',
  'searchContentStore',
  'navigationStore',
];

interface ReplenishmentPlanManagementContentProps
  extends WithStyles<typeof ReplenishmentPlanManagementStyles>,
    RouteComponentProps {}

export type ReplenishmentPlanManagementContentPropsWithStores = ReplenishmentPlanManagementContentProps &
  ReplenishmentPlanManagementContentStores;

@inject(...stores)
class ReplenishmentPlanManagementContentComponent extends Component<
  ReplenishmentPlanManagementContentProps,
  ReplenishmentPlanManagementContentState
> {
  private readonly filterFields: DataTableFilterFields<ReplenishmentPlan> = ['name', 'coordinate'];
  private readonly sortFieldMap: DataTableSortFieldMap<ReplenishmentPlan> = { name: 'entry.name' };

  private fetchPromises: CancelableFetchPromises = {};

  public state: ReplenishmentPlanManagementContentState = {
    isPanelOpen: false,
    isDialogOpen: false,
  };

  get stores(): ReplenishmentPlanManagementContentStores {
    return this.props as ReplenishmentPlanManagementContentProps & ReplenishmentPlanManagementContentStores;
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public fetch = async (pagination: Pagination): Promise<PaginationResponse<ReplenishmentPlan>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<ReplenishmentPlan>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getReplenishmentPlans(pagination),
      EslManagerPublicRouteV1.REPLENISHMENT_PLANS,
      HttpMethod.GET,
    );
  };

  public onDismiss = () => {
    this.setState({ selectedPlan: undefined, isDialogOpen: false, isPanelOpen: false });
  };

  public onDelete = (replenishmentPlan: ReplenishmentPlan) => {
    this.setState({ isDialogOpen: true, selectedPlan: replenishmentPlan });
  };

  public onDeleteConfirm = async () => {
    const { selectedPlan } = this.state;
    const { api, searchContentStore } = this.stores;

    if (selectedPlan === undefined) {
      return;
    }

    const { coordinate, name } = selectedPlan;

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteReplenishmentPlan({ coordinate, name }),
      EslManagerPublicRouteV1.REPLENISHMENT_PLAN,
      HttpMethod.DELETE,
      { 200: 'Replenishment Plan deleted.' },
    );

    this.onDismiss();
    searchContentStore.emitRefresh();
  };

  public onEdit = async (replenishmentPlan: ReplenishmentPlan): Promise<void> => {
    const { navigationStore, replenishmentPlanStore } = this.stores;

    replenishmentPlanStore.setEditableReplenishmentPlan(replenishmentPlan);
    this.setState(
      {
        isPanelOpen: true,
        selectedPlan: replenishmentPlan,
      },
      () => navigationStore.scrollTop(),
    );
  };

  public onSave = async (replenishmentPlan: ReplenishmentPlan): Promise<ReplenishmentPlan> => {
    const { api, navigationStore, searchContentStore, replenishmentPlanStore } = this.stores;

    const payload: SaveReplenishmentPlanPayload = {
      linearStateMachineDefinition: replenishmentPlan.linearStateMachineDefinition,
    };

    const responseReplenishmentPlan = await request<ReplenishmentPlan>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveReplenishmentPlan(
        {
          name: replenishmentPlan.name,
          coordinate: replenishmentPlan.coordinate,
          ...payload,
        },
        replenishmentPlanStore.editableReplenishmentPlan !== undefined,
      ),
      EslManagerPublicRouteV1.REPLENISHMENT_PLAN,
      HttpMethod.PUT,
      { 200: 'Replenishment Plan saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();

    return responseReplenishmentPlan;
  };

  public getPanel = (): JSX.Element | undefined => {
    const { isPanelOpen } = this.state;

    if (!isPanelOpen) {
      return;
    }

    return <ReplenishmentPlanPanel saveHandler={this.onSave} closeHandler={this.onDismiss} />;
  };

  public render() {
    const { classes } = this.props;
    const { selectedPlan, isPanelOpen, isDialogOpen } = this.state;
    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as ReplenishmentPlanManagementContentPropsWithStores, {
        edit: this.onEdit,
        delete: this.onDelete,
      }),
    );

    return (
      <>
        <ContentControl
          panel={this.getPanel()}
          dialog={
            <GenericDialog
              type="confirmation"
              maxWidth={'sm'}
              fullWidth={true}
              centered={true}
              open={isDialogOpen}
              title={'Delete Replenishment Plan'}
              text={selectedPlan?.name ?? ''}
              onClose={this.onDismiss}
              onConfirm={this.onDeleteConfirm}
            />
          }
          buttons={[
            <ContentControlButton
              key={'add'}
              content={<Add />}
              tooltip={'Add Replenishment Plan'}
              onClick={() => this.setState({ isPanelOpen: !isPanelOpen, selectedPlan: undefined })}
              requiredPermission={Permissions.EKANBAN_WRITE}
            />,
          ]}
        />

        <Paper className={classNames(classes.root, classes.dataTablePaper)}>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetch}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </>
    );
  }
}

const RouterWrapped = withRouter<
  ReplenishmentPlanManagementContentProps,
  typeof ReplenishmentPlanManagementContentComponent
>(ReplenishmentPlanManagementContentComponent);
const StyleWrapped = withStyles(ReplenishmentPlanManagementStyles)(RouterWrapped);

export const ReplenishmentPlanManagementContent = StyleWrapped;
